import React, { useEffect, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  Pagination,
  ArrayField,
  Labeled,
  useDataProvider,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { ShowToolbar } from "../components/ShowToolbar";
import { TotalField } from "../components/producer_summaries/total_field";
import { RegionField } from "../components/producer_summaries/region_field";
import { ProducerSummaryTitle } from "../components/producer_summaries/producer_summary_title";
import { DetailsByProducerTab } from "../components/producer_summaries/details_by_producer_tab";
import { Region, FetchedListData } from "../infrastructure/types";
import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";

const useStyles = makeStyles({
  header: {
    display: "none",
  },
  tbody: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
});

export const ProducerSummariesList = (props: any) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      perPage={50}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" sortable={false} />
        <TotalField source="total" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const ProducerSummaryShow = (props: any) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [regions, setRegions] = useState<Region[]>([]);

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      const fetchedBrands: FetchedListData<Region> = await dataProvider.getList(
        "regions",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );

      if (!isCleanedUp) {
        if (fetchedBrands) {
          setRegions(fetchedBrands.data);
        }
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, setRegions]);

  return (
    <Show
      {...props}
      actions={<ShowToolbar listPath="/producer_summaries" />}
      title={<ProducerSummaryTitle {...props} />}
    >
      <TabbedShowLayout>
        <Tab label="ui.producer_summaries.tabs.summary">
          <Labeled label="ui.producer_summaries.total_producers">
            <TotalField source="total" />
          </Labeled>
          <RegionField {...props} source="details_by_region" regions={regions} />
          <ArrayField source="details_by_area">
            <Datagrid classes={{ tbody: classes.tbody, headerCell: classes.header }}>
              <TextField source="label" sortable={false} />
              <TotalField source="total" sortable={false} />
            </Datagrid>
          </ArrayField>
          <ArrayField source="details_by_storage_period">
            <Datagrid classes={{ tbody: classes.tbody, headerCell: classes.header }}>
              <TextField source="label" sortable={false} />
              <TotalField source="total" sortable={false} />
            </Datagrid>
          </ArrayField>
          <ArrayField source="details_by_farming_certification">
            <Datagrid classes={{ tbody: classes.tbody, headerCell: classes.header }}>
              <TextField source="label" sortable={false} />
              <TotalField source="total" sortable={false} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="ui.producer_summaries.tabs.producer">
          <DetailsByProducerTab {...props} source="details_by_producer" regions={regions} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
