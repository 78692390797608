import React from "react";
import { useTranslate } from "react-admin";
import { useField } from "react-final-form";

import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

import { HandoverAddress, PackagingForm } from "../api/amend";
import { usePrefectures } from "../hooks/usePrefectures";
import { useWholesalerAddresses } from "../hooks/useWholesalerAddresses";
import { formatStringAsCurrency } from "../utils/locale";
import { calcAgencyFee, calcPricePerTawara, calcTotalPrice, findBasePrice } from "../utils/hacobell";

export const HacobellPriceField = (props: { packagingForm: PackagingForm; handoverAddress: HandoverAddress }) => {
  const { handoverAddress, packagingForm } = props;
  const [loadingPrefectures, prefectures] = usePrefectures();
  const [loadingWholesalerAddresses, wholesalerAddresses] = useWholesalerAddresses();
  const translate = useTranslate();

  const {
    input: { value: deliveryContents },
  } = useField("delivery_contents");
  const {
    input: { value: deliveryAddressId },
  } = useField("delivery_address_id");
  const {
    input: { value: preferredVehicle },
  } = useField("hacobell.preferred_vehicle");

  const loading = loadingPrefectures || loadingWholesalerAddresses;
  if (loading) {
    return null;
  }

  const deliveryAddress = wholesalerAddresses.find((wa) => wa.id === deliveryAddressId);
  if (deliveryAddress === undefined) {
    return (
      <Box>
        <InputLabel id="label" shrink={true}>
          {translate("ui.individual_deliveries.shipping_charges")}
        </InputLabel>
        <Typography variant="caption">{translate("ui.individual_deliveries.select_address")}</Typography>
      </Box>
    );
  }

  const fromPrefectureId = handoverAddress.prefecture_id;
  const toPrefectureId = deliveryAddress.prefecture_id;
  const fromPrefecture = prefectures.find((pref) => pref.id === fromPrefectureId);
  const toPrefecture = prefectures.find((pref) => pref.id === toPrefectureId);
  if (!fromPrefecture || !toPrefecture) {
    return null;
  }

  const basePrice = findBasePrice(fromPrefecture, toPrefecture, preferredVehicle);
  if (basePrice === null) {
    return (
      <Box>
        <InputLabel id="label" shrink={true}>
          {translate("ui.individual_deliveries.shipping_charges")}
        </InputLabel>
        <Typography variant="caption">{translate("ui.individual_deliveries.tariff_not_found")}</Typography>
      </Box>
    );
  }

  const _deliveryContents = deliveryContents || [];
  const packageCount = _deliveryContents
    .filter((dc: any) => dc !== undefined)
    .reduce((total: number, dc: any) => {
      return total + dc.package_count;
    }, 0);

  const totalPrice = calcTotalPrice(basePrice);
  const agencyFee = calcAgencyFee();
  const pricePerPackage = calcPricePerTawara(totalPrice, packageCount, packagingForm);
  return (
    <Box>
      <InputLabel id="label" shrink={true}>
        {translate("ui.individual_deliveries.shipping_charges")}
      </InputLabel>
      <Typography variant="body1">{formatStringAsCurrency(totalPrice.toFixed(0))}円</Typography>
      <Box>
        <Typography variant="caption">
          {translate("ui.individual_deliveries.agency_fee")}：{formatStringAsCurrency(agencyFee.toFixed(0))}円
        </Typography>
      </Box>
      {packageCount > 0 && (
        <Box>
          <Typography variant="caption">
            {translate("ui.individual_deliveries.charge_per_tawara")}：
            {formatStringAsCurrency(pricePerPackage.toFixed(0))}
            {translate("ui.individual_deliveries.charge_unit")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
HacobellPriceField.defaultProps = {
  addLabel: true,
};
