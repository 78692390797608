import { stringify } from "query-string";
// eslint-disable-next-line no-unused-vars
import { fetchUtils, DataProvider } from "ra-core";
import { parse as parseCookie } from "cookie";

import { HttpError } from "react-admin";
import * as Sentry from "@sentry/react";
import { redirect503 } from "../utils/route";

function buildFilter(filter: any) {
  if (filter === undefined || filter === null) {
    return {};
  }
  return Object.keys(filter).reduce((container: any, key) => {
    container[`_filters[${key}]`] = filter[key];
    return container;
  }, {});
}

// HACK! allSettled Polyfill
// TypeScriptのバージョンをあげたらPromise.allSettledに置き換える
// https://github.com/amrayn/allsettled-polyfill/blob/master/index.js
async function allSettled(promises: Promise<any>[]) {
  return Promise.all(
    promises.map((p) => {
      return p
        .then((value) => ({
          status: "fulfilled",
          value,
        }))
        .catch((reason) => ({
          status: "rejected",
          reason,
        }));
    })
  );
}

/**
 * react-admin data provider for dummy server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 */
export default (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filters = buildFilter(params.filter);
    const query: any = {
      _sortField: field,
      _sortDir: order,
      _perPage: perPage,
      _page: page,
      ...filters,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then((response) => {
      const { headers, json } = response;
      const count = parseInt(headers.get("x-total-count") + "", 10);
      const total = Number.isNaN(count) ? 0 : count;
      return {
        data: json,
        total,
      };
    });
  },

  getOne: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({ data: json })),

  getOneVersion: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}/${params.version_number}`).then(({ json }) => ({ data: json })),

  getMany: async (resource, params) => {
    return allSettled(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`))).then((responses: any) => ({
      data: responses
        .map((res: any) => {
          if (res.status === "fulfilled") {
            return res.value.json;
          }
          return null;
        })
        .filter((data: any) => data !== null),
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filters = buildFilter({
      ...params.filter,
      [params.target]: params.id,
    });
    const query: any = {
      _sortField: field,
      _sortDir: order,
      _perPage: perPage,
      _page: page,
      ...filters,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then((response) => {
      const { headers, json } = response;
      const count = parseInt(headers.get("x-total-count") + "", 10);
      const total = Number.isNaN(count) ? 0 : count;
      return {
        data: json,
        total,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: () => {
    throw new Error("updateMany is not implemented");
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) => {
    const { version_number } = params.previousData!;
    const options = {
      method: "DELETE",
      body: JSON.stringify({
        version_number,
      }),
    };
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: () => {
    throw new Error("deleteMany is not implemented");
  },
});

export const getHttpClient = () => {
  return async (url: string, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
    }

    const CsrfTokenKey = "__Host-an-ki-b-csrf-token";
    const myCookie = parseCookie(document.cookie);
    const CsrfToken = myCookie[CsrfTokenKey];
    options.headers.set("X-An-Ki-Csrf-Token", CsrfToken);

    const response = await fetch(url, options);

    if (response.status === 204) {
      return {
        status: response.status,
        headers: response.headers,
        body: null,
        json: null,
      };
    }

    const json = await response.json();

    if (response.status < 200 || 300 <= response.status) {
      const error = new HttpError(json.message || response.statusText, response.status, json);

      // HACK:
      // API周りのエラーは一番外のコンポーネントのSentryWrapperまで伝播しない
      // 一方authProviderのcheckErrorでハンドリングすると二度呼ばれてしまう
      // そのため、ここでcaptureExceptionする
      if (response.status !== 401 && response.status !== 503) {
        Sentry.captureException(error);
      }

      if (response.status === 503) {
        redirect503();
      }

      throw error;
    }

    return {
      status: response.status,
      headers: response.headers,
      body: json,
      json,
    };
  };
};
