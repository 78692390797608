import React from "react";
import { Datagrid, TextField, ArrayField, Labeled } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { Region } from "../../infrastructure/types";

import { TotalField } from "./total_field";

const useStyles = makeStyles({
  header: {
    display: "none",
  },
  tbody: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
});

export const RegionField = (props: any) => {
  const { record, regions } = props;
  const classes = useStyles();
  const filterRegions = regions.filter((region: Region) => {
    return region.is_active && region.prefecture_id === record["id"];
  });

  if (record === undefined) {
    return null;
  }

  if (filterRegions.length === 0) {
    return null;
  }

  return (
    <Labeled {...props}>
      <ArrayField {...props}>
        <Datagrid classes={{ tbody: classes.tbody, headerCell: classes.header }}>
          <TextField source="label" sortable={false} />
          <TotalField source="total" sortable={false} />
        </Datagrid>
      </ArrayField>
    </Labeled>
  );
};
