import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";

const useStyles = makeStyles({
  hidden: {
    display: "none",
  },
});

export const HiddenInput = (props: any) => {
  const classes = useStyles();
  const { source, value } = props;
  const initialValue = value !== undefined ? value : props.record[source];
  return (
    <div className={classes.hidden}>
      <Field component="input" disabled initialValue={initialValue} name={source} />
    </div>
  );
};
