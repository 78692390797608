import React from "react";

type UnitPriceFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
};

export const UnitPriceField = (props: UnitPriceFieldProps) => {
  const { record, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const price: number = record[props.source];

  return <span {...rest}>{`${price.toLocaleString()}円以上`}</span>;
};
