import React, { useState } from "react";
import { useInput, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  form: {
    marginBottom: 20,
    width: 256,
  },
});

export const GradePenaltyInput = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  const {
    input: { value, onChange },
    meta: { error },
  } = useInput(props);

  const initialCanReceiveItem = value !== "";
  const [canReceiveItem, setCanReceiveItem] = useState(initialCanReceiveItem);

  const initialReducedUnitPrice = value;
  const [reducedUnitPrice, setReducedUnitPrice] = useState(initialReducedUnitPrice);

  const onChangeSwitch = (ev: React.ChangeEvent<any>) => {
    if (ev.target.value === "receive") {
      setCanReceiveItem(true);
      onChange(reducedUnitPrice);
    } else {
      setCanReceiveItem(false);
      // 受け取らない場合は金額としてnullを設定する
      onChange(null);
    }
  };

  const onChangeText = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setReducedUnitPrice(ev.target.value);
    onChange(ev.target.value);
  };

  // エラーメッセージの型がまちまちなので適宜変換する
  let priceError = null;
  if (error) {
    if (typeof error === "string") {
      priceError = error;
    } else if (typeof error === "object") {
      priceError = translate(error.message, error.args);
    }
  }

  return (
    <>
      <div>
        <FormControl variant="filled" className={classes.form}>
          <InputLabel id="label">受け取り可否</InputLabel>
          <Select
            labelId="label"
            id="select"
            value={canReceiveItem ? "receive" : "not_receive"}
            onChange={onChangeSwitch}
          >
            <MenuItem value={"receive"}>減額して受け取る</MenuItem>
            <MenuItem value={"not_receive"}>受け取らない</MenuItem>
          </Select>
        </FormControl>
      </div>
      {canReceiveItem && (
        <div>
          <FormControl variant="filled" className={classes.form}>
            <TextField
              type="number"
              variant="filled"
              label="減額する金額"
              value={reducedUnitPrice}
              error={!!error}
              helperText={priceError}
              onChange={onChangeText}
            />
          </FormControl>
        </div>
      )}
    </>
  );
};
