import React, { useCallback, useReducer, useState } from "react";
import { Confirm, useDataProvider, useNotify, useRefresh, useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { OfferIcon } from "../svg/OfferIcon";
import { ActionProps, ShowToolbar } from "./ShowToolbar";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    textAlign: "right",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  linkButton: {
    marginLeft: theme.spacing(8),
  },
  accept: {
    color: "rgb(67, 160, 71)",
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: "rgba(67, 160, 71, 0.12)",
    },
  },
  reject: {
    color: "rgb(229, 57, 53)",
    "&:hover": {
      backgroundColor: "rgb(229, 57, 53, 0.12)",
    },
  },
  offerButton: {
    color: "rgb(30, 136, 229)",
    "&:hover": {
      backgroundColor: "rgb(30, 136, 229, 0.12)",
    },
  },
}));

type State = boolean;
type Action = {
  type: string;
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "fetching":
      return true;
    case "fetched":
      return false;
    default:
      throw new Error();
  }
}

const canAnswer = (permissions: any, data: any) => {
  if (!["admin", "staff"].includes(permissions)) {
    return false;
  }

  if (data.status !== "provisional") {
    return false;
  }

  return data.wholesaler_agreement_status === "waiting";
};

export const SpotContractDealShowActions = (
  props: ActionProps & {
    listPath: string;
    permissions: string;
  }
) => {
  const [isLoading, dispatch] = useReducer(reducer, false);
  const classes = useStyles();

  const { listPath, permissions, ...actionProps } = props;
  const { data } = actionProps;
  if (!permissions || !data) {
    return null;
  }

  return (
    <ShowToolbar {...actionProps} listPath={listPath}>
      <Box className={classes.toolbar}>
        {canAnswer(permissions, data) && (
          <>
            <span className={classes.button}>
              <AcceptButton {...actionProps} isLoading={isLoading} dispatch={dispatch} />
            </span>
            <span className={classes.button}>
              <RejectButton {...actionProps} isLoading={isLoading} dispatch={dispatch} />
            </span>
          </>
        )}
        <span className={classes.linkButton}>
          <OfferButton offerId={data.offer_id} />
        </span>
      </Box>
    </ShowToolbar>
  );
};

const AcceptButton = (props: any) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleClickOpen = () => {
    setConfirmModalOpen(true);
  };
  const handleClickClose = () => {
    setConfirmModalOpen(false);
  };

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const { data, dispatch, isLoading } = props;
  const { id, version_number } = data;

  const handleClickAgreement = useCallback(async () => {
    dispatch({ type: "fetching" });
    const data = {
      wholesaler_agreement_status: "agreement",
      version_number,
    };
    const params = { id, data };
    try {
      const res = await dataProvider.update("spot_contract_deals", params);

      dispatch({ type: "fetched" });
      if (res.data.id === id) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      if (e instanceof Error) {
        notify(e.message, "error");
      }
    } finally {
      setConfirmModalOpen(false);
    }

    dispatch({ type: "fetched" });
  }, [dataProvider, dispatch, id, notify, refresh, version_number]);

  return (
    <>
      <Button
        className={classes.accept}
        size="small"
        startIcon={<CheckIcon />}
        disabled={isLoading}
        onClick={handleClickOpen}
      >
        {translate("ui.deals.agreement.agreement")}
      </Button>
      <Confirm
        isOpen={confirmModalOpen}
        title="取引案件の承諾"
        content="取引案件に承諾しますがよろしいですか？"
        onConfirm={handleClickAgreement}
        onClose={handleClickClose}
      />
    </>
  );
};

const RejectButton = (props: any) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleClickOpen = () => {
    setConfirmModalOpen(true);
  };
  const handleClickClose = () => {
    setConfirmModalOpen(false);
  };

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();

  const { data, dispatch, isLoading } = props;
  const { id, version_number } = data;

  const handleClickDisagreement = useCallback(async () => {
    dispatch({ type: "fetching" });
    const data = {
      wholesaler_agreement_status: "disagreement",
      version_number,
    };
    const params = { id, data };

    try {
      const res = await dataProvider.update("spot_contract_deals", params);

      if (res.data.id === id) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      if (e instanceof Error) {
        notify(e.message, "error");
      }
    } finally {
      setConfirmModalOpen(false);
    }

    dispatch({ type: "fetched" });
  }, [dataProvider, dispatch, id, notify, refresh, version_number]);

  return (
    <>
      <Button
        className={classes.reject}
        size="small"
        startIcon={<CloseIcon />}
        disabled={isLoading}
        onClick={handleClickOpen}
      >
        {translate("ui.deals.agreement.disagreement")}
      </Button>
      <Confirm
        isOpen={confirmModalOpen}
        title="取引案件を不承諾"
        content="取引案件を不承諾にしますがよろしいですか？"
        onConfirm={handleClickDisagreement}
        onClose={handleClickClose}
      />
    </>
  );
};

const OfferButton = (props: { offerId: number }) => {
  const classes = useStyles();
  const to = `/#/spot_contract_offers/${props.offerId}/show`;
  return (
    <Button
      className={classes.offerButton}
      size="small"
      startIcon={<OfferIcon width={18} height={18} color="#1e88e5" />}
      href={to}
    >
      オファー
    </Button>
  );
};
