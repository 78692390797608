import { useEffect, useRef, useState } from "react";
import { getDataProvider } from "../utils/api";
import { Prefecture } from "../api/prefectures";

export function usePrefectures(): [boolean, Prefecture[]] {
  const [loading, setLoading] = useState(false);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const isCleanedUp = useRef(false);
  const dataProvider = getDataProvider();

  useEffect(() => {
    (async () => {
      setLoading(true);
      // TODO 100件だけでなく再帰的に全件取得する
      const response: { data: any[]; total: number } = await dataProvider.getList("prefectures", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          order: "DESC",
          field: "id",
        },
        filter: {
          is_active: true,
        },
      });
      if (!isCleanedUp.current) {
        const { data }: { data: Prefecture[] } = response;
        setPrefectures(data);
        setLoading(false);
      }
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [dataProvider]);
  return [loading, prefectures];
}
