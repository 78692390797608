export const KG_PER_TAWARA = 60;

/**
 * 取引案件1口当たりの梱包単位の数量を求める
 *
 * @param amount_in_kg 単位重量
 * @param deal_amount 1口当たりの俵数
 */
export function calcPackageCountPerDeal(amount_in_kg: number, deal_amount: number) {
  return (deal_amount * KG_PER_TAWARA) / amount_in_kg;
}
