import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

export const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: "#67ad11",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#74b622",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        minWidth: "6em",
      },
    },
    RaListToolbar: {
      toolbar: {
        justifyContent: "left",
      },
    },
    MuiTablePagination: {
      root: {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  },
});
