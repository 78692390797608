import React from "react";
import { Button, useTranslate } from "react-admin";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Payment } from "../api/payments";
import { useUpdateSpotContractPaymentStatus } from "../hooks/useUpdateSpotContractPaymentStatus";

const ReportWholesalerPaymentButton = (props: { record?: Payment }) => {
  const { record } = props;
  const translate = useTranslate();
  const [loading, update] = useUpdateSpotContractPaymentStatus();
  if (!record) {
    return null;
  }
  const onClick = async () => {
    if (!window.confirm(translate("ui.payment.confirm_report_wholesaler_payment"))) {
      return;
    }
    await update("waiting_for_payment_confirmation", record);
  };
  return (
    <Button
      {...props}
      size="medium"
      variant="contained"
      color="primary"
      label="ui.payment.report_wholesaler_payment"
      disabled={loading}
      onClick={onClick}
    >
      <CheckCircleIcon />
    </Button>
  );
};

export const UpdatePaymentButton = (props: { record?: Payment }) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  const { status } = record;
  switch (status) {
    case "waiting_for_payment_report": {
      return <ReportWholesalerPaymentButton {...props} />;
    }
    default: {
      return null;
    }
  }
};
