import { SettingMailNotification } from "../api/setting_mail_notification";
import { useEffect, useRef, useState } from "react";
import { getApiBase, getHttpClient } from "../utils/api";

const initialSetting = {
  version_number: undefined,
  when_added_pending_task: undefined,
  when_processed_deal: undefined,
  when_changed_profile: undefined,
};

export function useSettingMailNotification(): [boolean, SettingMailNotification] {
  const [loading, setLoading] = useState(true);
  const [settingState, setSettingState] = useState<SettingMailNotification>(initialSetting);
  const isCleanedUp = useRef(false);
  const httpClient = getHttpClient();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await httpClient(getApiBase() + "/wholesalers/me/setting_mail_notification");
      if (!isCleanedUp.current) {
        const data: SettingMailNotification = response.json;
        setSettingState(data);
        setLoading(false);
      }
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [httpClient]);

  return [loading, settingState];
}
