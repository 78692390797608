import { getHttpClient } from "../infrastructure/dataProvider";
import { getApiBase } from "./api";
import { isImage, removeExif } from "./image";
import { UUID } from "../api/common";
import { FileNameExtensionError, FileSizeError } from "./error";

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const ALLOW_EXTENSIONS = [".jpeg", ".jpg", ".png", ".pdf", ".zip"];

export const ACCEPTS = ALLOW_EXTENSIONS.join(",");

type FileSizeUnit = "B" | "KB" | "MB";
export const formatFileSize = (fileSize: number): [number, FileSizeUnit] => {
  let size = fileSize;
  let unit: FileSizeUnit = "B";
  if (Math.pow(1024, 2) <= fileSize) {
    size = fileSize / Math.pow(1024, 2);
    unit = "MB";
  } else if (1024 <= size) {
    size = fileSize / 1024;
    unit = "KB";
  }
  return [Math.round(size), unit];
};

const getExtension = (fileName: string) => {
  const splitted = fileName.split(".");
  return "." + splitted[splitted.length - 1].toLowerCase();
};

type Fields = {
  [key: string]: string;
};
type preUploadBody = {
  file_name: string;
  file_size_in_bytes: number; // 100 * 1024 * 1024 以下
};
type AttachmentUploadParams = {
  uuid: UUID;
  aws_s3_presigned_post: {
    url: string;
    fields: Fields;
  };
};

async function preUpload(file: Blob, fileName: string): Promise<AttachmentUploadParams> {
  const httpClient = getHttpClient();
  const body: preUploadBody = {
    file_name: fileName,
    file_size_in_bytes: file.size,
  };
  const preUploadOption = {
    method: "POST",
    body: JSON.stringify(body),
  };
  const preUploadResponse: any = await httpClient(getApiBase() + "/attachments", preUploadOption);
  return preUploadResponse.json;
}

async function doUpload(file: Blob, fileName: string, url: string, fields: Fields): Promise<void> {
  const formData = new FormData();

  const kvs = Object.entries(fields) as [string, string][];
  kvs.forEach((field: [string, string]) => formData.append(field[0], field[1]));
  formData.append("file", file, fileName);

  const uploadOption: RequestInit = {
    method: "POST",
    mode: "cors",
    body: formData,
  };
  // AWSのURLを叩くのでhttpClientではなくfetchを使う
  await fetch(url, uploadOption);
}

async function postUpload(uuid: string): Promise<void> {
  const httpClient = getHttpClient();
  const url = getApiBase() + `/attachments/upload_done?uuid=${uuid}`;
  await httpClient(url);
}

export async function upload(file: File): Promise<UUID> {
  // blobに変換するとファイル名がなくなるので退避させる
  const fileName = file.name;

  if (MAX_FILE_SIZE < file.size) {
    throw new FileSizeError("file size is too big");
  }

  const extension = getExtension(fileName);
  if (!ALLOW_EXTENSIONS.includes(extension)) {
    throw new FileNameExtensionError("filename extension is not allowed");
  }

  let blobFile = new Blob([file.slice(0)], { type: file.type });
  if (isImage(file)) {
    blobFile = await removeExif(blobFile);
  }

  const response = await preUpload(blobFile, fileName);
  const { aws_s3_presigned_post, uuid } = response;
  const { fields, url } = aws_s3_presigned_post;

  await doUpload(blobFile, fileName, url, fields);

  await postUpload(uuid);
  return uuid;
}
