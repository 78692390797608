export const resourceJa = {
  Configuration: "設定",
  "Invalid Credential": "IDかパスワードが誤っている、またはアカウントが無効な可能性があります",
  "Under Maintenance": "ただいまメンテナンス中です",
  "Unknown Error": "不明なエラーです",
  "login_id already exists": "ログインIDが既に使用されています",
  "cannot delete self": "自分自身は削除できません",
  "cannot change own role": "自分自身の権限を変更することはできません",
  "cannot change own is_active": "自分自身の状態を変更することはできません",
  "target record was modified by someone else":
    "対象のデータは他のユーザーによって変更されました。ブラウザを再読み込みして再実行してください",
  "only future offer publication period allowed": "過去の公開期間は指定できません",
  "failed to cancel charter": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'shipment_delivered'":
    "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'shipment_completed'":
    "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'canceled'": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'rejected'": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'closed'": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'accepted'": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  "cannot cancel hacobell with status 'unknown'": "配車のキャンセルに失敗しました。運営者にお問い合わせください",
  commons: {
    states: {
      active: "有効",
      inactive: "無効",
    },
    is_deleted: {
      is_deleted: "削除済のみ",
      is_not_deleted: "削除済以外",
    },
    publish_statuses: {
      waiting: "公開前",
      published: "公開中",
      done: "公開終了",
    },
    agreement_statuses: {
      waiting: "要承諾",
      agreement: "承諾済",
      disagreement: "不承諾",
    },
    can_adjust_unit_price: {
      can: "可",
      cannot: "不可",
    },
    inspection_responsibilities: {
      not_needed: "検査不要",
      self: "自身で検査する／検査手配する",
      request: "外部検査機関の手配を依頼する",
    },
    delivery_types: {
      self_delivery: "自己手配（自社運送）",
      hacobell: "ハコベルに依頼",
    },
    delivery_statuses: {
      draft: "下書き",
      waiting_for_arrangement: "手配待ち",
      waiting_for_inspection: "検品待ち",
      done: "完了",
      cancelled: "キャンセル",
      cancelled_by_deal: "キャンセル",
    },
    hacobell_preferred_vehicles: {
      light: "軽貨物",
      one_ton: "1t車両",
      two_ton: "2t車両",
      four_ton: "4t車両",
      ten_ton: "10t車両",
      trailer: "トレイラー",
    },
    hacobell_options: {
      refrigerator: "冷蔵",
      freezer: "冷凍",
      tail_gate_lift: "パワーゲート",
      air_conditioner: "空調",
      air_suspension: "エアサス",
      stanchion: "スタンション",
      two_men: "2名配送",
      three_men: "3名配送",
      additional: "付帯作業",
      sundays_and_holidays: "日祝",
      stack: "積み置き",
      waypoint: "経由地",
      night_time: "夜間対応",
      insurance_without_tax: "保険",
      campaign_discount: "キャンペーン値引",
      other: "その他",
    },
    task_keys: {
      // 事前
      TASK_BUYER_AGREE_BID_DEAL: "取引案件（単価増額）の承諾または再提示",
      TASK_BUYER_AGREE_DEAL: "取引案件の承諾",
      TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "運送手配の作成",
      TASK_BUYER_PAYS_AND_REPORTS: "売買代金の支払",
      TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY: "ドライバー情報の入力",
      TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "受取報告",

      // 現物
      SPOT_CONTRACT_TASK_BUYER_AGREE_BID_DEAL: "【現物】取引案件（単価増額）の承諾",
      SPOT_CONTRACT_TASK_BUYER_AGREE_DEAL: "【現物】取引案件の承諾",
      SPOT_CONTRACT_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "【現物】運送手配の作成",
      SPOT_CONTRACT_TASK_BUYER_PAYS_AND_REPORTS: "【現物】売買代金の支払",
      SPOT_CONTRACT_TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY: "【現物】ドライバー情報の入力",
      SPOT_CONTRACT_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "【現物】受取報告",
    },
    inspection_result: {
      ok: "OK",
      ng: "問題あり",
    },
    amend_statuses: {
      waiting_for_delivery_creation: "運送手配待ち",
      waiting_for_after_delivery_inspection: "受取報告待ち",
      waiting_for_payment: "支払い報告待ち",
    },
    has_producer_reviewed: {
      done: "承諾済",
      not_yet: "未承諾",
    },
    payment_statuses: {
      provisional: "仮請求",
      waiting_for_billing: "請求待ち",
      waiting_for_payment_report: "支払報告待ち",
      waiting_for_payment_confirmation: "入金確認待ち",
      waiting_for_transfer: "完了",
      done: "完了",
    },
    delivery_categories: {
      hacobell: "ハコベル",
    },
    delivery_subcategories: {
      shipping: "運送料",
      options: "オプション料金",
      agency: "取次手数料",
      cancellation_fee: "取消手数料",
      parking_freight_handling_waiting: "駐車場・待機料・搬出料金",
      other: "その他",
    },
    is_product_adjustment: {
      yes: "する",
      no: "しない",
    },
    hacobell_statuses: {
      circulated: "依頼中 (circulated)",
      seeking: "依頼中 (seeking)",
      opened: "依頼中 (opened)",
      requesting: "依頼中 (requesting)",
      unchartered: "依頼中 (unchartered)",
      charter_not_fixed: "運行準備中 (charter_not_fixed)",
      chartered: "運行準備中 (chartered)",
      in_transit: "運行中 (in_transit)",
      shipment_delivered: "運行完了 (shipment_delivered)",
      shipment_completed: "運行完了 (shipment_completed)",
      canceled: "キャンセル (canceled)",
      rejected: "却下 (rejected)",
      closed: "却下 (closed)",
      accepted: "運行準備中 (accepted)",
      unknown: "例外処理中 (unknown)",
    },
    deal_types: {
      without_offer: "オファー外ディール",
      with_offer: "通常ディールのみ",
    },
  },
  validation: {
    invalid_katakana_string: "全角カタカナで入力してください",
    invalid_login_id_string: "ログインIDに使用できる文字は半角英数字とアンダースコア ( _ )のみです",
    invalid_password_string: "パスワードに使用できない文字が含まれています",
    offers: {
      must_set_period: "公開期間を指定してください",
      can_not_set_period_that_includes_today: "本日を公開期間に含むオファーは作れません",
      can_not_set_past_period: "過去の公開期間のオファーは作れません",
      can_not_set_future_period_over_three_month: "3ヶ月以上先のオファーは作成できません",
      can_not_set_period_that_is_before_one_business_day: "オファーが作成できるのは公開開始日の前営業日の17:00までです",
      must_input_penalty_in_yen: "金額を入力してください",
      must_be_difference_of_deal_amount_in_30: "包装形態の俵数差を30以内としてください",
    },
    individual_deliveries: {
      draft_can_not_set_delivery_contents: "下書きでは運送内容は設定できません",
      grade_is_duplicated: "等級が重複しています",
      can_not_load_package_count: "この等級の残数量が取得できませんでした",
      over_package_count: "運送未設定の数量を超えています",
      over_deal_amount: "指定できる数量は運送内容全体で１口分までです",
      can_not_set_before_possible_date: "引渡可能日以降の日時を設定してください",
      can_not_set_after_to_datetime: "終了日時以前の日時を設定してください",
      can_not_set_before_from_datetime: "開始日時以降の日時を設定してください",
    },
    after_delivery_inspections: {
      should_fill_reason: "問題の内容をメモとして記入してください",
    },
  },
  ui: {
    value: {
      not_set: "未設定",
      early: "上旬",
      mid: "中旬",
      late: "下旬",
      harvest_plan_not_set: "未定・不明",
      default_grade: "減額なし",
    },
    error: {
      unknown_error: "エラーが発生しました",
      file_size_is_too_big: "ファイルサイズが大きすぎます",
      filename_extension_is_not_allowed: "アップロードできないファイル形式です",
      image_processing_is_failed: "画像の処理中にエラーが発生しました",
      upload_is_failed: "アップロードに失敗しました",
    },
    action: {
      set_as_default: "既定にする",
      back_to_list: "一覧に戻る",
    },
    contract_type: {
      advance_contract: "事前契約",
      spot_contract: "現物契約",
    },
    dashboard: {
      title: "ダッシュボード",
      deals: "成約（事前契約）",
      spot_contract_deals: "成約（現物契約）",
      unanswered: "未回答",
      amends: "受渡（事前契約）",
      spot_contract_amends: "受渡（現物契約）",
      waiting_for_delivery_creation: "運送手配",
      waiting_for_after_delivery_inspection: "受取待ち",
      waiting_for_payment: "商品支払",
      show_more: "もっと見る",
    },
    offers: {
      can_adjust_unit_price_helper_text: "１俵あたり10円以上の単価増額を受けられる場合に「可」を選択してください",
    },
    deals: {
      amend_status: "対応状況",
      in_exception_flow: "この取引案件は米市場運営が対応中です。\n問題解決までしばらくお待ちください。",
      tabs: {
        info: "基本情報",
        contract: "契約情報",
        offer: "元オファー",
        delivery: "運送",
        payments: "支払",
      },
      agreement: {
        agreement: "承諾",
        disagreement: "不承諾",
        representation: "再提示",
      },
    },
    amends: {
      exceptional_unit_price_adjustment_in_yen: "その他事由による単価増減額",
    },
    individual_deliveries: {
      save_as_draft: "下書き保存",
      publish: "生産者に連絡",
      save_as_notify_driver_info: "ドライバー情報の連絡",
      register_hacobell: "案件を登録",
      unassigned_delivery_content: "運送未設定の数量",
      no_unassigned_delivery_content: "検査結果が入力されていません",
      inspect: "受取報告",
      draft_delivery: "下書きの個別運送情報",
      arranged_delivery: "ハコべルの手配",
      cancel_title: "%{name} をキャンセル",
      cancel_content: "キャンセルしてよろしいですか？",
      register_modal_title: "利用規約の確認",
      check_agree_term: "利用規約に同意する",
      shipping_charges: "運賃（税込）",
      agency_fee: "うち取次手数料",
      select_address: "受取場所を指定してください",
      tariff_not_found: "ご指定の受取場所と引渡場所の都道府県、車両の組み合わせはお取り扱いできません",
      charge_per_tawara: "1俵あたりの運賃",
      charge_unit: "円",
    },
    payment: {
      claim_buyer_payment_button: "請求",
      confirm_buyer_payment: "入金確認",
      report_producer_payment: "振込報告",
      report_wholesaler_payment: "支払報告",
      confirm_claim_buyer_payment: "請求処理を実行します。よろしいですか？",
      confirm_confirm_buyer_payment: "入金確認を実行します。よろしいですか？",
      confirm_report_producer_payment: "振込報告を実行します。よろしいですか？",
      confirm_report_wholesaler_payment: "支払報告を実行します。よろしいですか？",
    },
    producer_summaries: {
      tabs: {
        summary: "サマリー情報",
        producer: "生産者個別情報",
      },
      total_producers: "登録生産者数（うち検査可）",
    },
    offer_requests: {
      prefecture: "都道府県",
    },
  },
  resources: {
    wholesaler_accounts: {
      name: "アカウント",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_deleted: "削除状態",
        login_id: "ログインID",
        password: "パスワード",
        name: "氏名",
        name_kana: "氏名（カナ）",
        is_active: "状態",
        role: "権限",
        roles: {
          admin: "管理者",
          staff: "担当者",
          observer: "閲覧者",
        },
      },
    },
    wholesaler_addresses: {
      name: "受取場所",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_deleted: "削除状態",
        name: "所名",
        prefecture_id: "都道府県",
        city: "市区郡町村",
        address_1: "番地",
        address_2: "建物名",
        phone_number: "電話番号",
        memo: "メモ",
        is_default: "既定",
      },
    },
    wholesaler_setting_mail_notification: {
      name: "通知",
      fields: {
        when_added_pending_task: "取引に対しての「やること」が追加される、または期限が近づいてきたらメールへ通知する",
        when_processed_deal: "取引に進捗があったらメールへ通知する",
        when_changed_profile: "登録情報に更新があったらメールへ通知する",
        additional_email1: "追加の通知先メールアドレス 1",
        additional_email2: "追加の通知先メールアドレス 2",
        additional_email3: "追加の通知先メールアドレス 3",
      },
    },
    offers: {
      name: "事前契約オファー",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_deleted: "削除済",
        is_active: "状態",
        publish_status: "公開状態",
        offer_publication_period_id: "公開期間",
        publish_start_date: "公開開始日",
        publish_end_date: "公開終了日",
        brand_id: "銘柄",
        grade_id: "等級",
        annum_id: "年産",
        prefecture_ids: "県産",
        region_id: "地域",
        unit_price: "1俵あたり単価（円）",
        lowest_trading_units: "最低取引口数",
        highest_trading_units: "最大取引口数",
        total_trading_units: "募集口数",
        packaging_form_ids: "受入可能な包装形態",
        "packaging_form_ids.name": "包装形態",
        "packaging_form_ids.deal_amount": "1口あたりの俵数",
        can_adjust_unit_price: "単価増額可否",
        storage_period_ids: "保管期間",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        deal_ids: "取引案件",
        trading_units_closed: "成約口数",
        trading_units_provisional: "仮成約口数",
        trading_units_left: "残口数",
        created_by_account: {
          name: "登録者",
        },
        updated_by_account: {
          name: "編集者",
        },
        created_at: "登録日時",
        updated_at: "編集日時",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",
        offer_publication_period_name: "公開期間",
        brand_name: "銘柄",
        grade_name: "等級",
        annum_name: "年産",
        prefecture_name: "県産",
      },
    },
    spot_contract_offers: {
      name: "現物契約オファー",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_deleted: "削除済",
        is_active: "状態",
        publish_status: "公開状態",
        offer_publication_period_id: "公開期間",
        publish_start_date: "公開開始日",
        publish_end_date: "公開終了日",
        brand_id: "銘柄",
        grade_id: "等級",
        annum_id: "年産",
        prefecture_ids: "県産",
        region_id: "地域",
        unit_price: "1俵あたり単価（円）",
        lowest_trading_units: "最低取引口数",
        highest_trading_units: "最大取引口数",
        total_trading_units: "募集口数",
        packaging_form_ids: "受入可能な包装形態",
        "packaging_form_ids.name": "包装形態",
        "packaging_form_ids.deal_amount": "1口あたりの俵数",
        can_adjust_unit_price: "単価増額可否",
        storage_period_ids: "保管期間",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        deal_ids: "取引案件",
        trading_units_closed: "成約口数",
        trading_units_provisional: "仮成約口数",
        trading_units_left: "残口数",
        created_by_account: {
          name: "登録者",
        },
        updated_by_account: {
          name: "編集者",
        },
        created_at: "登録日時",
        updated_at: "編集日時",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",
        offer_publication_period_name: "公開期間",
        brand_name: "銘柄",
        grade_name: "等級",
        annum_name: "年産",
        prefecture_name: "県産",
      },
    },
    others_offers: {
      name: "事前契約他社オファー",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_active: "状態",
        publish_status: "公開状態",
        offer_publication_period_id: "公開期間",
        publish_start_date: "公開開始日",
        publish_end_date: "公開終了日",
        brand_id: "銘柄",
        grade_id: "等級",
        annum_id: "年産",
        prefecture_ids: "県産",
        region_id: "地域",
        unit_price: "1俵あたり単価（円）",
        lowest_trading_units: "最低取引口数",
        highest_trading_units: "最大取引口数",
        total_trading_units: "募集口数",
        packaging_form_ids: "受入可能な包装形態",
        "packaging_form_ids.name": "包装形態",
        "packaging_form_ids.deal_amount": "俵数",
        can_adjust_unit_price: "単価増額可否",
        storage_period_ids: "保管期間",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        deal_ids: "取引案件",
        trading_units_closed: "成約口数",
        trading_units_provisional: "仮成約口数",
        trading_units_provisional_waiting: "要承諾口数",
        trading_units_left: "残口数",
        created_by_account: {
          name: "登録者",
        },
        updated_by_account: {
          name: "編集者",
        },
        created_at: "登録日時",
        updated_at: "編集日時",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",
        offer_publication_period_name: "公開期間",
        brand_name: "銘柄",
        grade_name: "等級",
        annum_name: "年産",
        prefecture_name: "県産",
      },
    },
    spot_contract_others_offers: {
      name: "現物契約他社オファー",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_active: "状態",
        publish_status: "公開状態",
        offer_publication_period_id: "公開期間",
        publish_start_date: "公開開始日",
        publish_end_date: "公開終了日",
        brand_id: "銘柄",
        grade_id: "等級",
        annum_id: "年産",
        prefecture_ids: "県産",
        region_id: "地域",
        unit_price: "1俵あたり単価（円）",
        lowest_trading_units: "最低取引口数",
        highest_trading_units: "最大取引口数",
        total_trading_units: "募集口数",
        packaging_form_ids: "受入可能な包装形態",
        "packaging_form_ids.name": "包装形態",
        "packaging_form_ids.deal_amount": "俵数",
        can_adjust_unit_price: "単価増額可否",
        storage_period_ids: "保管期間",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        deal_ids: "取引案件",
        trading_units_closed: "成約口数",
        trading_units_provisional: "仮成約口数",
        trading_units_provisional_waiting: "要承諾口数",
        trading_units_left: "残口数",
        created_by_account: {
          name: "登録者",
        },
        updated_by_account: {
          name: "編集者",
        },
        created_at: "登録日時",
        updated_at: "編集日時",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",
        offer_publication_period_name: "公開期間",
        brand_name: "銘柄",
        grade_name: "等級",
        annum_name: "年産",
        prefecture_name: "県産",
      },
    },
    deals: {
      name: "事前契約取引案件",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        status: "成約状況",
        decision_date: "決定日",
        offer_id: "オファーID",
        statuses: {
          closed: "成約済",
          provisional: "仮成約",
          not_closed: "不成約",
          done: "完了",
          cancelled: "取消済",
        },
        without_offer: "ディール種別",
        producer_agreement_status: "生産者承諾状況",
        producer_agreement_at: "生産者承諾日時",
        wholesaler_agreement_status: "卸業者承諾状況",
        wholesaler_agreement_at: "卸業者承諾日時",
        inspection_adjustment_status: "WCI検査調整状況",
        inspection_adjustment_at: "WCI検査調整日時",
        inspection_adjustment_statuses: {
          not_needed: "なし",
          waiting: "検査確認待ち",
          ok: "検査確認OK",
          ng: "検査確認NG",
        },
        agreement_deadline: "承諾期限日",
        producer: {
          company_name: "生産者",
          invoice_registration_status: "適格請求書発行事業者",
        },
        producer_company_name: "生産者",
        created_at: "登録日時",
        "brand.name": "銘柄",
        brand_name: "銘柄",
        "grade.name": "等級",
        grade_name: "等級",
        "annum.name": "年産",
        annum_name: "年産",
        "prefecture.name": "県産",
        prefecture_name: "県産",
        "region.name": "地域",
        region_name: "地域",
        offer_unit_price: "オファー単価",
        unit_price: "契約単価",
        unit_price_adjustment_in_yen: "単価増額",
        "storage_period.name": "保管期間",
        "storage_period.bonus_in_yen": "保管による増額・減額",
        grade_penalty: {
          id: "等級格差減額",
        },
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        packaging_form_id: "包装形態",
        "packaging_form.name": "包装形態",
        trading_units: "取引口数",
        trading_sacks: "取引俵数",
        trading_kg: "取引重量（kg）",
        package_count: "納品物の数量",
        trading_volume_in_yen: "取引金額（税込）",
        special_conditions: "特別条件",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",
        expected_harvest_timeframe: "収穫予定時期",
        inspection_responsibility: "検査実施主体",

        total_bill_in_yen: "合計請求額（税込）",
        amend: {
          handover_possible_date: "引渡可能日",
          handover_deadline_date: "引渡期限",
        },
        handover_deadline_date: "引渡期限",
      },
    },
    spot_contract_deals: {
      name: "現物契約取引案件",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        status: "成約状況",
        decision_date: "決定日",
        offer_id: "オファーID",
        statuses: {
          closed: "成約済",
          provisional: "仮成約",
          not_closed: "不成約",
          done: "完了",
          cancelled: "取消済",
        },
        producer_agreement_status: "生産者承諾状況",
        producer_agreement_at: "生産者承諾日時",
        wholesaler_agreement_status: "卸業者承諾状況",
        wholesaler_agreement_at: "卸業者承諾日時",
        agreement_deadline: "承諾期限日",
        producer: {
          company_name: "生産者",
          invoice_registration_status: "適格請求書発行事業者",
        },
        producer_company_name: "生産者",
        created_at: "登録日時",
        "brand.name": "銘柄",
        brand_name: "銘柄",
        "grade.name": "等級",
        grade_name: "等級",
        "annum.name": "年産",
        annum_name: "年産",
        "prefecture.name": "県産",
        prefecture_name: "県産",
        "region.name": "地域",
        region_name: "地域",
        offer_unit_price: "オファー単価",
        unit_price: "契約単価",
        unit_price_adjustment_in_yen: "単価増額",
        "storage_period.name": "保管期間",
        "storage_period.bonus_in_yen": "保管による増額・減額",
        grade_penalty: {
          id: "等級格差減額",
        },
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        packaging_form_id: "包装形態",
        "packaging_form.name": "包装形態",
        trading_units: "取引口数",
        trading_sacks: "取引俵数",
        trading_kg: "取引重量（kg）",
        package_count: "納品物の数量",
        trading_volume_in_yen: "取引金額（税込）",
        special_conditions: "特別条件",
        special_condition_ids: "特別条件",
        special_conditions_memo: "特別条件メモ",

        total_bill_in_yen: "合計請求額（税込）",

        handover_possible_date: "引渡可能日",
        handover_address: "引渡場所",
        handover_memo: "運送手配時の注意点",
        inspection_results: "検査結果",
        inspection_results_memo: "検査結果のメモ",
        inspection_results_attachments: "検査結果の添付ファイル",
      },
    },
    offer_requests: {
      name: "生産者のオファー希望銘柄・価格",
      fields: {
        brand_id: "銘柄",
        grade_id: "等級",
        region_id: "地域",
        unit_price: "単価",
        special_condition_id: "特別条件",
        special_condition_ids: "特別条件",
        "updated_by_organization.prefecture_id": "都道府県",
        updated_at: "更新日",
        updated_at_filter: "更新日（指定日以降）",
        "updated_by_organization.prefecture_name": "都道府県",
        region_name: "地域",
        brand_name: "銘柄",
        grade_name: "等級",
        unit_price_filter: "単価（指定値以上）",
        special_condition_name: "特別条件",
      },
    },
    storage_periods: {
      fields: {
        name: "名前",
        storage_period_in_days: "日数",
        bonus_in_yen: "円",
      },
    },
    spot_contract_storage_periods: {
      fields: {
        name: "名前",
        storage_period_in_days: "日数",
        bonus_in_yen: "円",
      },
    },
    pending_tasks: {
      name: "やることリスト",
      fields: {
        id: "ID",
        task_key: "種別",
        deadline: "締切",
        trading_partner: "取引相手",
        description: "詳細",
        offer_id: "オファーID",
        deal_id: "取引案件ID",
        amend_id: "アメンドID",
        delivery_id: "デリバリーID",
        created_at: "作成日時",
      },
    },
    transaction_logs: {
      name: "お知らせ",
      fields: {
        id: "ID",
        log_key: "種別",
        log_text: "詳細",
        created_at: "作成日時",
      },
    },
    amends: {
      name: "取引案件",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_exception_flow: "例外フロー",
        current_step_seller: "売り手の状態",
        current_step_buyer: "買い手の状態",
        current_step_payment: "支払いの状態",
        deal_id: "取引案件",
        brand: "銘柄",
        grade: "等級",
        "grade.name": "等級",
        annum: "年産",
        prefecture: "県産",
        region: "地域",
        packaging_form: "包装形態",
        package_count: "納品物の数量",
        offer_unit_price: "オファー単価",
        unit_price_adjustment_in_yen: "単価増額",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        special_conditions: "特別条件",
        special_conditions_memo: "特別条件メモ",
        expected_harvest_timeframe: "収穫予定時期",
        storage_period: "保管期間",
        handover_contents: "引渡内容",
        handover_volume_in_yen: "引渡合計取引金額",
        handover_possible_date: "引渡可能日",
        can_handover_on_holiday: "土日祝日の引渡",
        handover_memo: "運送手配時の注意点",
        handover_address: "引渡場所",
        inspection_results: "検査結果",
        inspection_results_attachments: "検査結果の添付ファイル",
        inspection_results_memo: "検査結果のメモ",
        wholesaler: "卸事業者",
        producer: "生産者",
        commission_rate: "業務委託料率",
      },
    },
    spot_contract_amends: {
      name: "取引案件",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        is_exception_flow: "例外フロー",
        current_step_seller: "売り手の状態",
        current_step_buyer: "買い手の状態",
        current_step_payment: "支払いの状態",
        deal_id: "取引案件",
        brand: "銘柄",
        grade: "等級",
        "grade.name": "等級",
        annum: "年産",
        prefecture: "県産",
        region: "地域",
        packaging_form: "包装形態",
        package_count: "納品物の数量",
        offer_unit_price: "オファー単価",
        unit_price_adjustment_in_yen: "単価増額",
        grade_penalties: "等級格差減額",
        penalty_in_yen: "金額",
        special_conditions: "特別条件",
        special_conditions_memo: "特別条件メモ",
        storage_period: "保管期間",
        handover_contents: "引渡内容",
        handover_volume_in_yen: "引渡合計取引金額",
        handover_possible_date: "引渡可能日",
        can_handover_on_holiday: "土日祝日の引渡",
        handover_memo: "運送手配時の注意点",
        handover_address: "引渡場所",
        inspection_results: "検査結果",
        inspection_results_attachments: "検査結果の添付ファイル",
        inspection_results_memo: "検査結果のメモ",
        wholesaler: "卸事業者",
        producer: "生産者",
        commission_rate: "業務委託料率",
      },
    },
    individual_deliveries: {
      name: "事前契約個別配送情報",
      fields: {
        id: "運送ID",
        version_number: "バージョン番号",
        amend_id: "取引案件",
        delivery_type: "運送種類",
        status: "状態",
        has_producer_reviewed: "生産者の承諾",
        delivery_address: "受取場所",
        delivery_contents: "運送内容",
        attachments: "添付ファイル",
        after_delivery_inspection_memo: "受取報告のメモ",
        after_delivery_inspection_attachments: "受取報告の添付ファイル",
        self_delivery: "自己手配（自社運送）",
        hacobell: "ハコベル",

        "delivery_address.id": "受取場所",
        delivery_address_id: "受取場所",
        grade_id: "等級",
        package_count: "数量",

        "self_delivery.shipping_company_name": "運送会社名",
        "self_delivery.handover_date": "引渡日",
        "self_delivery.handover_time": "時間帯",
        "self_delivery.driver_name": "ドライバー名",
        "self_delivery.driver_phone_number": "ドライバー電話番号",
        "self_delivery.car_number": "車番",

        "hacobell.status": "運送手配の状態",
        "hacobell.arrival_preferred_from": "到着希望開始日時",
        "hacobell.arrival_preferred_to": "到着希望終了日時",
        "hacobell.preferred_vehicle": "希望車両",
        "hacobell.options": "配送オプション",
        "hacobell.shipping_company_name": "運送会社名",
        "hacobell.shipping_company_phone_number": "運送会社電話番号",
        "hacobell.handover_at": "引渡日時",
        "hacobell.driver_name": "ドライバー名",
        "hacobell.driver_phone_number": "ドライバー電話番号",
        "hacobell.customer_name": "荷主",
        "hacobell.shipment_notes": "運送のメモ",

        "grade.name": "等級",
        handover_at: "引渡日時",
        handover_possible_date: "引渡可能日",
        receipt_timing: "到着日時",
      },
    },
    spot_contract_individual_deliveries: {
      name: "現物契約個別配送情報",
      fields: {
        id: "運送ID",
        version_number: "バージョン番号",
        amend_id: "取引案件",
        delivery_type: "運送種類",
        status: "状態",
        has_producer_reviewed: "生産者の承諾",
        delivery_address: "受取場所",
        delivery_contents: "運送内容",
        attachments: "添付ファイル",
        after_delivery_inspection_memo: "受取報告のメモ",
        after_delivery_inspection_attachments: "受取報告の添付ファイル",
        self_delivery: "自己手配（自社運送）",
        hacobell: "ハコベル",

        "delivery_address.id": "受取場所",
        delivery_address_id: "受取場所",
        grade_id: "等級",
        package_count: "数量",

        "self_delivery.shipping_company_name": "運送会社名",
        "self_delivery.shipping_company_phone_number": "運送会社電話番号",
        "self_delivery.handover_date": "引渡日",
        "self_delivery.handover_time": "時間帯",
        "self_delivery.driver_name": "ドライバー名",
        "self_delivery.driver_phone_number": "ドライバー電話番号",
        "self_delivery.car_number": "車番",

        "hacobell.status": "運送手配の状態",
        "hacobell.arrival_preferred_from": "到着希望開始日時",
        "hacobell.arrival_preferred_to": "到着希望終了日時",
        "hacobell.preferred_vehicle": "希望車両",
        "hacobell.options": "配送オプション",
        "hacobell.shipping_company_name": "運送会社名",
        "hacobell.shipping_company_phone_number": "運送会社電話番号",
        "hacobell.handover_at": "引渡日時",
        "hacobell.driver_name": "ドライバー名",
        "hacobell.driver_phone_number": "ドライバー電話番号",
        "hacobell.customer_name": "荷主",
        "hacobell.shipment_notes": "運送のメモ",

        "grade.name": "等級",
        handover_at: "引渡日時",
        handover_possible_date: "引渡可能日",
        receipt_timing: "到着日時",
      },
    },
    individual_delivery_after_delivery_inspections: {
      name: "受取報告",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        amend_id: "取引案件",
        is_exception_flow: "検品結果",
        status: "状態",
        after_delivery_inspection_memo: "受取報告のメモ",
        after_delivery_inspection_attachment_uuids: "受取報告の添付ファイル",
        after_delivery_inspection_attachments: "受取報告の添付ファイル",

        has_trouble: "検品結果",
      },
    },
    spot_contract_individual_delivery_after_delivery_inspections: {
      name: "受取報告",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        amend_id: "取引案件",
        is_exception_flow: "検品結果",
        status: "状態",
        after_delivery_inspection_memo: "受取報告のメモ",
        after_delivery_inspection_attachment_uuids: "受取報告の添付ファイル",
        after_delivery_inspection_attachments: "受取報告の添付ファイル",

        has_trouble: "検品結果",
      },
    },
    amend_handovers: {
      name: "引渡情報",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        handover_possible_date: "引渡可能日",
        can_handover_on_holiday: "土日祝の引渡",
        handover_memo: "運送手配時の注意点",
        handover_address_id: "引渡場所",
        payment_bank_account_id: "振込先銀行口座",
      },
    },
    payments: {
      name: "支払",
      fields: {
        id: "支払ID",
        version_number: "バージョン番号",
        amend_id: "アメンド",
        individual_delivery_id: "個別運送情報",
        status: "状態",
        product_payments: "商品代金〔調整前〕",
        delivery_payments: "運送料",
        adjustments: "調整金額",
        commission_fee_in_yen: "業務委託料（税込）〔調整前〕",
        commission_fee_tax_rate: "業務委託料の税率",
        total_bill_in_yen: "合計請求額（税込）",
        payment_due_date: "支払期日",
        invoice_uuid: "請求書",
        invoice: "請求書（1通目）",
        attachment_uuids: "添付ファイル",
        attachments: "請求書（2通目以降）・添付書類",
        transfer_due_date: "支払期日",
        transfer_notice_uuid: "支払通知書",
        transfer_notice: "支払通知書",
        internal_memo: "支払メモ（社内用）",

        "grade.name": "等級",
        tax_rate: "税率",
        unit_price: "単価",
        sacks: "俵数",
        package_count: "数量",
        trading_volume_in_yen: "金額（税込）",
        category: "カテゴリー",
        subcategory: "サブカテゴリー",
        fee_in_yen: "料金（税込）",
        title: "調整内容",
        adjustment_in_yen: "調整金額（税込）",
        is_product_adjustment: "売買代金に関わる（税8％）",
        memo: "調整金額のメモ",
      },
    },
    spot_contract_payments: {
      name: "支払",
      fields: {
        id: "ID",
        version_number: "バージョン番号",
        amend_id: "アメンド",
        individual_delivery_id: "個別運送情報",
        status: "状態",
        product_payments: "商品代金〔調整前〕",
        delivery_payments: "運送料",
        adjustments: "調整金額",
        commission_fee_in_yen: "業務委託料（税込）〔調整前〕",
        commission_fee_tax_rate: "業務委託料の税率",
        total_bill_in_yen: "合計請求額（税込）",
        payment_due_date: "支払期日",
        invoice_uuid: "請求書",
        invoice: "請求書（1通目）",
        attachment_uuids: "添付ファイル",
        attachments: "請求書（2通目以降）・添付書類",
        transfer_due_date: "支払期日",
        transfer_notice_uuid: "支払通知書",
        transfer_notice: "支払通知書",
        internal_memo: "支払メモ（社内用）",

        "grade.name": "等級",
        tax_rate: "税率",
        unit_price: "単価",
        sacks: "俵数",
        package_count: "数量",
        trading_volume_in_yen: "金額（税込）",
        category: "カテゴリー",
        subcategory: "サブカテゴリー",
        fee_in_yen: "料金（税込）",
        title: "調整内容",
        adjustment_in_yen: "調整金額（税込）",
        is_product_adjustment: "売買代金に関わる（税8％）",
        memo: "調整金額のメモ",
      },
    },
    producer_summaries: {
      name: "生産者情報",
      fields: {
        id: "ID",
        name: "都道府県",
        total: "登録生産者数（うち検査可）",
        total_self_inspect: "検査可",
        details_by_region: "地域別内訳（うち検査可）",
        details_by_area: "経営面積別内訳（うち検査可）",
        details_by_storage_period: "保管可能期間別内訳（うち検査可）",
        details_by_farming_certification: "有機JAS等の取り組み別内訳（うち検査可）",
        details_by_producer: "生産者個別情報一覧",
        region_id: "地域",
        can_self_inspect: "検査可否",
        cropland_area: "経営面積",
        storage_period_id: "保管可能期間",
        invoice_registration_status: "適格請求書発行事業者",
        farming_certification_ids: "有機JAS等の取り組み",
        offer_requests: "生産者のオファー希望銘柄・価格",
        updated_at: "更新日",
        brand_id: "銘柄",
        grade_id: "等級",
        unit_price: "単価",
        special_condition_ids: "特別条件",
      },
    },
  },
};
