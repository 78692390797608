import React from "react";

type Props = {
  record?: {
    special_condition_ids: number[];
  };
  sortable?: boolean;
  source?: string;
};

export const ExistsSpecialConditionsField = (props: Props) => {
  const { record } = props;

  if (!record) {
    return null;
  }

  if (record.special_condition_ids.length === 0) {
    return <span>無</span>;
  }

  return <span>有</span>;
};
