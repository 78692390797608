import React from "react";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { HandoverAddress } from "../api/amend";
import { usePrefectures } from "../hooks/usePrefectures";

type Props = {
  source: string;
  record?: any;
  label?: string;
  sortable?: false;
};

export const AddressField = (props: Props) => {
  const { record, source } = props;
  const [loadingPrefectures, prefectures] = usePrefectures();
  const translate = useTranslate();

  if (!record || loadingPrefectures) {
    return null;
  }

  const address: HandoverAddress = record[source];
  if (!address) {
    return <Typography variant="body2">{translate("ui.value.not_set")}</Typography>;
  }
  const prefecture = prefectures.find((p) => p.id === address.prefecture_id);
  const prefectureName = prefecture ? prefecture.name : "";
  const city = address.city ?? "";
  const address1 = address.address_1 ?? "";
  const address2 = address.address_2 ?? "";

  return <Typography variant="body2">{`${prefectureName}${city}${address1}${address2}`}</Typography>;
};

AddressField.defaultProps = {
  addLabel: true,
};
