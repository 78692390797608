import React from "react";
import styled from "@emotion/styled";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const ProducerSummaryIcon = ({ width = 24, height = 24, color = "#35363a" }: Props) => (
  <Svg width={width} height={height} color={color} viewBox="0 0 239.83 238.48">
    <g>
      <path d="m69.9,145.64c-.53,6.86-1.94,13.35-4.03,19.23,1.86-1.33,9.24-12.46,12.3-12.77,1.37-.2,6.19-3.13,10.06-5.49,11.42-6.95,20.55-5.49,28.74-.41,11.45,7.1,17.1,38.69,2.19,62.73-11.44,18.43-28.84,29.57-43.73,29.57-4.85,0-9.42-1.18-13.44-3.67-8.17-5.07-12.8-14.56-13.03-26.71-.13-7.18,1.29-14.81,4.1-22.33-5.91,5.81-12.87,9.24-20.18,9.24-.61,0-1.22-.02-1.83-.07C11.85,193.44-1.97,160.48.25,131.81c.37-4.75.83-10.66,7.28-14.89,1.13-.81,4.05-5.63,6.41-9.5,6.94-11.42,15.58-17.24,25.16-16.46,13.43,1.04,32.98,26.49,30.8,54.69Zm-14.41-1.11c1.47-19.03-11.78-34.05-17.87-34.52-.06,0-.11,0-.17,0-3.96,0-10.18,2.65-13.54,8.19-2.95,4.86-4.56,7.44-6.2,8.51-2.54,1.66-2.81,3.67-3.07,7.08-1.41,18.17,6.61,41.24,17.87,42.12,11.25.86,21.57-13.2,22.98-31.36Zm16.37,35.26c-4.75,7.66-7.29,16.02-7.15,23.54.13,7.12,2.67,12.57,7.14,15.34,9.6,5.95,25.22-1.8,34.83-17.28,10.06-16.22,6.82-37.14,1.63-40.36-.46-.28-2.61-1.13-3.23-1.42-4.15-1.97-8.41-1.46-13.26,1.5-4.85,2.96-7.47,4.51-9.41,4.7-3.02.31-8.74,11.07-10.54,13.98Z" />
      <path
        className="cls-1"
        d="m159.22,87.77c-2.19.04-5.23-1.23-7.98-7.02-1.93-4.06-3.31-9.52-3.89-15.39-.93-9.54.49-18.16,3.63-21.96,1.46-1.78,3.05-2.27,4.12-2.38h0c6.6-.62,11.16,10.78,12.3,22.39.58,5.86.28,11.48-.84,15.85-1.69,6.62-4.69,8.27-6.91,8.49-.14.01-.28.02-.43.03Z"
      />
      <path
        className="cls-1"
        d="m117.95,116.07c-3.81.07-7.02-.47-9.15-1.64-2.03-1.11-2.81-2.58-3.11-3.62-1.85-6.34,8.52-12.97,19.72-16.24h0c11.19-3.26,23.48-3.24,25.34,3.1.3,1.03.44,2.69-.67,4.71-2.36,4.32-9.84,8.85-19.04,11.53-4.65,1.36-9.18,2.09-13.09,2.16Z"
      />
      <path
        className="cls-1"
        d="m132.43,63.58c-6.07.12-11.42-10.19-13.7-21.01-1.21-5.76-1.53-11.39-.9-15.85.96-6.77,3.75-8.74,5.93-9.2h0c4.74-1,11.37,5.12,14.7,20.92,2.38,11.41,1.42,23.67-5.04,25.03-.33.07-.66.11-.99.11Z"
      />
      <path
        className="cls-1"
        d="m94.88,95.72c-4.22.08-7.77-.54-10.05-1.86-2-1.16-2.75-2.65-3.03-3.7h0c-1.67-6.4,8.87-12.76,20.14-15.73,11.26-2.96,23.56-2.63,25.27,3.74,1.68,6.42-8.86,12.76-20.14,15.72-4.31,1.15-8.5,1.76-12.19,1.83Z"
      />
      <path
        className="cls-1"
        d="m103.96,52.17c-5.69.11-12.5-8.26-16.79-17.65-4.85-10.59-6.62-22.76-.61-25.53h0c4.4-2.01,12.2,2.5,18.94,17.16,4.85,10.61,6.61,22.79.61,25.53-.69.32-1.41.47-2.15.49Z"
      />
      <path
        className="cls-1"
        d="m62.68,79.06c-6.4.12-11.51-1.43-12.63-5.36-1.77-6.39,8.67-12.9,19.91-16.04,11.21-3.14,23.51-2.99,25.32,3.37v.02c1.77,6.38-8.68,12.88-19.91,16.01-4.29,1.2-8.73,1.92-12.69,2Z"
      />
      <path
        className="cls-1"
        d="m72.44,40.63c-1.88.04-4.38-.71-7.62-3.04-3.65-2.62-7.49-6.75-10.83-11.62-6.58-9.62-10.38-21.32-4.94-25.07h.02c4-2.72,12.45.41,21.57,13.69,6.58,9.63,10.39,21.33,4.93,25.06-.76.52-1.79.94-3.13.97Z"
      />
      <path
        className="cls-1"
        d="m31.27,64.16c-7.22.14-13.13-1.59-14.15-5.94-1.48-6.46,9.25-12.49,20.61-15.12,11.35-2.63,23.62-1.92,25.15,4.51h0c.5,2.18-.11,5.55-5.85,9.27-3.78,2.45-9.03,4.52-14.76,5.84-3.7.86-7.5,1.37-10.99,1.44Z"
      />
      <path
        className="cls-1"
        d="m32.19,36.08c-3.44.07-7.19-.27-10.86-.96C9.88,33.01-1.09,27.47.09,20.96c.2-1.07.84-2.61,2.74-3.92,4.07-2.79,12.79-3.46,22.19-1.7,11.46,2.12,22.44,7.67,21.25,14.18-.83,4.43-6.76,6.42-14.08,6.56Z"
      />
      <path
        className="cls-1"
        d="m87.82,122.56c16.09-3.77,64.36,1.41,88.1,20.29,16.01,12.73,22.02,26.29,31.31,43.11-7.77-34.06-22.47-62.27-47.48-86.72-.67-.66-.88-1.59-.93-2.28-.11-1.55,1.22-3.58,1.94-4.25,1.7-1.57,3.19-1.64,5.11-1.71,1.35-.05,2.64.13,3.64,1.14,31.78,32.12,42.91,55.93,50.12,100.5,1.44-38.48,1.5-77.33-15.98-112.63,0,0-1.57-3.14-4.23-9.66,1.45-.97,8.37,7.01,8.37,7.01,4.03,5.17,18.29,19.27,26.66,45.08,7.71,23.77,9.91,75.25-12.76,101.93.12,4.23.17,7.81.14,8.99-.03,1.18-.52,2.29-1.39,3.15-.87.86-1.92,1.18-3.12,1.28-.04,0-.07,0-.11,0-1.68-.1-3.31-.81-4.49-1.92-.8-.75-1.02-1.65-1.07-2.53-.43-6.82.44-9.29-1.31-16.08-.57-2.21-2.06-6.76-2.06-6.76-13.72-31-34.23-45.56-56.92-57.49,0,0-16.67-11.11-56.3-21-28.51-5.31-7.25-9.42-7.25-9.42Z"
      />
    </g>
  </Svg>
);

const Svg = styled.svg`
  .cls-1 {
    fill: ${(props) => props.color};
  }
`;
