import { useDataProvider } from "react-admin";
import React, { useEffect, useState } from "react";

import { FetchedListData } from "../infrastructure/types";
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from "../utils/parameters";
import LinearProgress from "./LinerProgress";

let cachedMasterRecords: FetchedListData<ImmutableMasterItem> = null;

type ImmutableMasterItem = {
  id: number;
  name: string;
  is_active: boolean;
};

type FieldProps = {
  record?: any;
  reference?: string;
  sortable?: boolean;
  source: string;
};

export const OmitReferenceArrayField = (props: FieldProps) => {
  const { record, ...rest } = props;

  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [masterRecords, setMasterRecords] = useState<ImmutableMasterItem[]>([]);

  let ids: number[] = [];
  if (record !== undefined) {
    ids = record[props.source];
  }

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }
      const params = {
        ...FETCH_PARAMS_FOR_THOUSAND_ITEMS,
      };

      if (cachedMasterRecords === null) {
        cachedMasterRecords = dataProvider.getList(props.reference, params);
      }
      const results = await cachedMasterRecords;

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefinedになるため
        if (results) {
          setMasterRecords(results.data);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, ids]);

  if (isLoading) {
    return <LinearProgress />;
  }

  const filteredMasterRecords = masterRecords.filter((p) => ids.includes(p.id));

  let label = "なし"; // 通常はありえない
  if (filteredMasterRecords.length === 1) {
    label = filteredMasterRecords[0].name;
  } else if (filteredMasterRecords.length > 1) {
    label = `${filteredMasterRecords[0].name}、他`;
  }

  return <span {...rest}>{label}</span>;
};
