import React from "react";
import Typography from "@material-ui/core/Typography";
import { calcPackageCountPerDeal } from "../utils/deal";

type Props = {
  source?: string;
  record?: {
    packaging_form?: {
      amount_in_kg: number;
      deal_amount: number;
    };
    [key: string]: any;
  };
  label?: string;
  sortable?: false;
};

export const PackageCountPerDealField = (props: Props) => {
  const { record } = props;
  const packaging_form = record?.packaging_form;
  if (!record || !packaging_form) {
    return null;
  }
  const { amount_in_kg, deal_amount } = packaging_form;
  const packageCount = calcPackageCountPerDeal(amount_in_kg, deal_amount);
  return <Typography variant="body2">{packageCount.toString()}</Typography>;
};

PackageCountPerDealField.defaultProps = {
  addLabel: true,
};
