import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

type NodeEnv = "production" | "development" | "testing";

const getGaMeasurementId = (): string | null => {
  const deployTarget: NodeEnv = process.env.REACT_APP_DEPLOY_TARGET as NodeEnv;

  if (deployTarget === "testing") {
    return "G-W1CERKFR5M";
  }

  if (deployTarget === "production") {
    return "G-KJHEY94D8N";
  }

  return null;
};

export const GoogleAnalytics4 = () => {
  const history = useHistory();

  useEffect(() => {
    const gaMeasurementId = getGaMeasurementId();
    if (!gaMeasurementId) {
      return;
    }

    ReactGA.initialize(gaMeasurementId, {
      legacyDimensionMetric: false,
    });

    const unlisten = history.listen(({ pathname }: { pathname: string }) => {
      const send = () => {
        ReactGA.send({
          hitType: "pageview",
          page: pathname,
        });
      };
      setTimeout(send, 1000);
    });

    return unlisten;
  }, []);

  return null;
};
