import React, { useCallback, useReducer } from "react";
import { EditButton, useNotify, useRefresh, useTranslate } from "react-admin";

import { getHttpClient } from "../infrastructure/dataProvider";
import { getApiBase } from "../utils/api";
import { ActionProps, ShowToolbar } from "./ShowToolbar";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    textAlign: "right",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  asDefaultButton: {
    color: "rgb(30, 136, 229)",
    "&:hover": {
      backgroundColor: "rgb(30, 136, 229, 0.12)",
    },
  },
}));

type State = boolean;
type Action = {
  type: "fetched" | "fetching";
};
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "fetching":
      return true;
    case "fetched":
      return false;
    default:
      throw new Error();
  }
}

const canEdit = (permissions: any) => {
  return ["admin", "staff"].includes(permissions);
};

export const WholesalerAddressShowActions = (
  props: ActionProps & {
    permissions: string;
  }
) => {
  const { permissions, data, basePath } = props;

  const classes = useStyles();

  if (!permissions || !data) {
    return null;
  }

  return (
    <ShowToolbar {...props} listPath="/wholesaler_addresses">
      <Box className={classes.toolbar}>
        {canEdit(permissions) && (
          <span className={classes.button}>
            <AsDefaultButton record={data} />
          </span>
        )}
        {canEdit(permissions) && (
          <span className={classes.button}>
            <EditButton basePath={basePath} record={data} />
          </span>
        )}
      </Box>
    </ShowToolbar>
  );
};

export const AsDefaultButton = (props: { record: any }) => {
  const { record } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, dispatch] = useReducer(reducer, false);
  const notify = useNotify();
  const refresh = useRefresh();

  const { id } = record;

  const onClickButton = useCallback(async () => {
    dispatch({ type: "fetching" });
    try {
      const httpClient = getHttpClient();
      const url = `${getApiBase()}/wholesalers/me/default_wholesaler_address`;
      const opts = {
        method: "PUT",
        body: JSON.stringify({ id }),
      };
      const res = await httpClient(url, opts);
      if (res.body.id === id) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      notify((e as any).message, "error");
    }
    dispatch({ type: "fetched" });
  }, [dispatch, id, notify, refresh]);

  return (
    <Button
      className={classes.asDefaultButton}
      size="small"
      startIcon={<DoneIcon />}
      disabled={isLoading || record.is_default}
      onClick={onClickButton}
    >
      {translate("ui.action.set_as_default")}
    </Button>
  );
};
