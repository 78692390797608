import React from "react";
import {
  Create,
  Edit,
  Datagrid,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectField,
  SelectInput,
  Show,
  SimpleShowLayout,
  minLength,
  maxLength,
  required,
  regex,
} from "react-admin";
import { HiddenInput } from "../components/hidden";
import { ShowToolbar } from "../components/ShowToolbar";

const roles = ["admin", "staff", "observer"];

const createRoleChoices = () =>
  roles.map((role) => ({
    id: role,
    name: `resources.wholesaler_accounts.fields.roles.${role}`,
  }));

const createStateChoices = () =>
  [true, false].map((state) => ({
    id: state,
    name: `commons.states.${state ? "active" : "inactive"}`,
  }));

export const WholesalerAccountList = (props: any) => (
  <List {...props} bulkActionButtons={false} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="show">
      <TextField source="name" sortable={false} />
      <TextField source="name_kana" sortable={false} />
      <TextField source="login_id" sortable={false} />
      <SelectField source="role" choices={createRoleChoices()} sortable={false} />
      <SelectField source="is_active" choices={createStateChoices()} sortable={false} />
    </Datagrid>
  </List>
);

export const WholesalerAccountShow = (props: any) => (
  <Show {...props} title="アカウント" actions={<ShowToolbar listPath="/wholesaler_accounts" />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="name_kana" />
      <TextField source="login_id" />
      <SelectField source="role" choices={createRoleChoices()} />
      <SelectField source="is_active" choices={createStateChoices()} />
    </SimpleShowLayout>
  </Show>
);

const nameKanaRegExp = /^([ァ-ン]|ー|\s)+$/;
const loginIdRegExp = /^[a-z0-9_]{4,32}$/i;
const passwordRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*$/;

const validateRequired = [required()];
const validateName = [required(), maxLength(50)];
const validateNameKana = [required(), maxLength(50), regex(nameKanaRegExp, "validation.invalid_katakana_string")];
const validateLoginId = [
  required(),
  minLength(4),
  maxLength(32),
  regex(loginIdRegExp, "validation.invalid_login_id_string"),
];
const validateCreatePassword = [
  required(),
  minLength(8),
  maxLength(32),
  regex(passwordRegExp, "validation.invalid_password_string"),
];
const validateEditPassword = [minLength(8), maxLength(32), regex(passwordRegExp, "validation.invalid_password_string")];

export const WholesalerAccountEdit = (props: any) => (
  <Edit {...props} undoable={false} title={"アカウント を編集"}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={validateName} />
      <TextInput source="name_kana" validate={validateNameKana} />
      <TextInput source="login_id" validate={validateLoginId} />
      <TextInput type="password" source="password" validate={validateEditPassword} />
      <SelectInput source="role" choices={createRoleChoices()} validate={validateRequired} />
      <SelectInput source="is_active" choices={createStateChoices()} validate={validateRequired} />
    </SimpleForm>
  </Edit>
);

export const WholesalerAccountCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={validateName} />
      <TextInput source="name_kana" validate={validateNameKana} />
      <TextInput source="login_id" validate={validateLoginId} />
      <TextInput type="password" source="password" validate={validateCreatePassword} />
      <SelectInput source="role" choices={createRoleChoices()} validate={validateRequired} />
      <HiddenInput source="is_active" value={true} validate={validateRequired} />
    </SimpleForm>
  </Create>
);
