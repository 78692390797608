import { useFetchMore } from "./useFetchMore";
import { TransactionLog } from "../api/transaction_logs";

export function useTransactionLogs() {
  return useFetchMore<TransactionLog>("transaction_logs", {
    pagination: {
      perPage: 5,
    },
    sort: {
      order: "DESC",
      field: "id",
    },
    filter: {},
  });
}
