import polyglotI18nProvider from "ra-i18n-polyglot";

import japaneseMessages from "@bicstone/ra-language-japanese";

import { resourceJa } from "./resource-ja";

const messages: { [key: string]: any } = {
  ja: {
    ...japaneseMessages,
    ...resourceJa,
  },
};
export const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ja");
