import React from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  Pagination,
  Filter,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { UnitPriceField } from "../components/producer_summaries/unit_price_field";

const useStyles = makeStyles({
  dateInput: {
    minWidth: "180px",
  },
});

const OfferRequestsFilter = (props: any) => {
  const classes = useStyles();

  return (
    <Filter {...props}>
      <DateInput
        source="updated_at"
        label="resources.offer_requests.fields.updated_at_filter"
        className={classes.dateInput}
      />
      <ReferenceInput
        label="ui.offer_requests.prefecture"
        source="prefecture_id"
        reference="prefectures"
        filter={{ is_active: true }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="region_name" />
      <TextInput source="brand_name" />
      <TextInput source="grade_name" />
      <NumberInput source="unit_price" label="resources.offer_requests.fields.unit_price_filter" />
      <ReferenceInput source="special_condition_id" reference="special_conditions" filter={{ is_active: true }}>
        <SelectInput optionText="display_text" />
      </ReferenceInput>
    </Filter>
  );
};

export const OfferRequestList = (props: any) => (
  <List
    {...props}
    sort={{ field: "updated_at", order: "DESC" }}
    filters={<OfferRequestsFilter />}
    bulkActionButtons={false}
    exporter={false}
    perPage={50}
    pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
  >
    <Datagrid>
      <DateField source="updated_at" locales={"ja"} showTime={false} />
      <ReferenceField
        source="updated_by_organization.prefecture_id"
        reference="prefectures"
        link={false}
        sortBy="prefecture_id"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="region_id" reference="regions" link={false} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="brand_id" reference="brands" link={false} sortBy="brand_name_kana">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="grade_id" reference="grades" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <UnitPriceField source="unit_price" />
      <ReferenceArrayField source="special_condition_ids" reference="special_conditions" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="display_text" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
