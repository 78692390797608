import { DateTimeString } from "./common";

export const TASK_KEYS_OF_ADVANCE_CONTRACT = {
  TASK_BUYER_AGREE_BID_DEAL: "TASK_BUYER_AGREE_BID_DEAL",
  TASK_BUYER_AGREE_DEAL: "TASK_BUYER_AGREE_DEAL",
  TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY: "TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY",
  TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  TASK_BUYER_PAYS_AND_REPORTS: "TASK_BUYER_PAYS_AND_REPORTS",
} as const;
export type TaskKeyOfAdvanceContract = typeof TASK_KEYS_OF_ADVANCE_CONTRACT[keyof typeof TASK_KEYS_OF_ADVANCE_CONTRACT];

export const TASK_KEYS_OF_SPOT_CONTRACT = {
  TASK_BUYER_AGREE_BID_DEAL: "SPOT_CONTRACT_TASK_BUYER_AGREE_BID_DEAL",
  TASK_BUYER_AGREE_DEAL: "SPOT_CONTRACT_TASK_BUYER_AGREE_DEAL",
  TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "SPOT_CONTRACT_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY:
    "SPOT_CONTRACT_TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY",
  TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "SPOT_CONTRACT_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  TASK_BUYER_PAYS_AND_REPORTS: "SPOT_CONTRACT_TASK_BUYER_PAYS_AND_REPORTS",
} as const;
export type TaskKeyOfSpotContract = typeof TASK_KEYS_OF_SPOT_CONTRACT[keyof typeof TASK_KEYS_OF_SPOT_CONTRACT];

export type PendingTask = {
  id: number;
  task_key: TaskKeyOfAdvanceContract | TaskKeyOfSpotContract;
  deadline: DateTimeString;
  trading_partner: string;
  description: string;
  offer_id: number;
  deal_id: number;
  amend_id: number;
  delivery_id: number;
  spot_contract_deal_id: number;
  spot_contract_amend_id: number;
  created_at: DateTimeString;
};
