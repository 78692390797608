import { DateTime } from "luxon";

import { PublicationPeriod } from "../pages/offers";
import { Holiday } from "../infrastructure/types";
import { getPrevBusinessDay } from "./date";
import { PackagingForm } from "../api/amend";

export const steppedPrice = (value?: number) => {
  if (typeof value === "number" && value % 10 !== 0) {
    return "10円刻みの数値である必要があります";
  }
  return null;
};

export const likeInteger = (value?: number | string | null) => {
  if (value === null || value === undefined) {
    return null;
  }

  // 編集で開いた直後はnumberが渡される、この値は保存できている正常な値なのでvalid扱い
  if (typeof value === "number") {
    return null;
  }

  if (value.includes(".")) {
    return "小数は入力できません";
  }

  return null;
};

export const steppedStringPrice = (value?: number | string | null) => {
  if (value === null || value === undefined) {
    return null;
  }

  const parsedValue = typeof value === "number" ? value : parseInt(value, 10);
  // 数字であるかは別のvalidatorでチェックする
  if (Number.isNaN(parsedValue)) {
    return null;
  }

  if (parsedValue % 10 !== 0) {
    return "10円刻みの数値である必要があります";
  }
  return null;
};

export const lowestTradingUnits = (value?: number, allValues?: any) => {
  if (typeof value !== "number" || !allValues) {
    return null;
  }
  if (typeof allValues.total_trading_units === "number" && value > allValues.total_trading_units) {
    return "募集口数より大きな値は指定できません";
  }
  if (typeof allValues.highest_trading_units === "number" && value > allValues.highest_trading_units) {
    return "最大取引口数より大きな値は指定できません";
  }
  return null;
};

export const highestTradingUnits = (value?: number, allValues?: any) => {
  if (typeof value !== "number" || !allValues) {
    return null;
  }
  if (typeof allValues.total_trading_units === "number" && value > allValues.total_trading_units) {
    return "募集口数より大きな値は指定できません";
  }
  if (typeof allValues.lowest_trading_units === "number" && value < allValues.lowest_trading_units) {
    return "最小取引口数より小さな値は指定できません";
  }
  return null;
};

export const notSameGrade = (value?: string, allValues?: any, input?: any) => {
  if (value === undefined || allValues === undefined || input === undefined) {
    return null;
  }

  const { grade_id, grade_penalties } = allValues;
  if (value === grade_id) {
    return "オファーの等級と同じ等級は選択できません";
  }

  const { name } = input;
  const idRegExp = /^.*\[(\d+)\].*$/;
  const result = idRegExp.exec(name);
  if (!result || result.length < 2) {
    // おそらく起きない
    return null;
  }
  const index = parseInt(result[1], 10);

  if (grade_penalties) {
    return grade_penalties
      .filter((item: any) => item !== undefined)
      .reduce((message: string | null, item: any, i: number) => {
        if (i !== index && item.grade_id === value) {
          return "同じ等級の設定が存在します";
        }
        return message;
      }, null);
  }

  return null;
};

export const validPublicationPeriod = (publicationPeriods: PublicationPeriod[], now: Date, holidays: Holiday[]) => (
  value?: number
) => {
  const selectedItem = publicationPeriods.find((item) => item.id === value);
  if (!selectedItem) {
    return "validation.offers.must_set_period";
  }

  const startDateTime = DateTime.fromISO(selectedItem.publish_start_date);
  const endDateTime = DateTime.fromISO(selectedItem.publish_end_date);
  const nowDateTime = DateTime.fromJSDate(now);

  // 現在が公開期間内の場合はオファーを作れない
  if (startDateTime <= nowDateTime && nowDateTime <= endDateTime) {
    return "validation.offers.can_not_set_period_that_includes_today";
  }

  // 公開期間が過去になるオファーは作れない
  if (endDateTime <= nowDateTime) {
    return "validation.offers.can_not_set_past_period";
  }

  // 翌月を1として2か月目の月末までに公開終了するオファーしか作れない
  if (nowDateTime.set({ day: 1 }).plus({ months: 3 }).minus({ day: 1 }) < endDateTime) {
    return "validation.offers.can_not_set_future_period_over_three_month";
  }

  // 公開開始日の前営業日の場合、17:00より後になるオファーは作れない
  const holidaysInThisOrLastYear = holidays
    .filter((h) => h.is_active)
    .filter((h) => h.year === nowDateTime.year || h.year === nowDateTime.year + 1)
    .map((h) => h.dates)
    .flat();
  const prevBusinessDay = getPrevBusinessDay(selectedItem.publish_start_date, holidaysInThisOrLastYear);
  const prevBusinessDateTime = DateTime.fromISO(prevBusinessDay);
  const diffDays = nowDateTime.startOf("day").diff(prevBusinessDateTime, "days").days;
  const isInHoliday = diffDays >= 1;
  const isPrevBusinessDay = diffDays === 0;

  if (isInHoliday || (isPrevBusinessDay && 17 <= nowDateTime.hour)) {
    return "validation.offers.can_not_set_period_that_is_before_one_business_day";
  }

  return null;
};

export const validGradePenaltyPrice = (value?: string, allValues?: any, input?: any) => {
  if (allValues === undefined || input === undefined) {
    return null;
  }

  // 減額して受け取るで数値入力が空の場合にundefinedを受け取る
  // 受け取らない場合はnullを受け取る
  // データ構造の都合上、undefinedとnullを区別するナイーブな実装になっている
  if (value === undefined) {
    return {
      message: "validation.offers.must_input_penalty_in_yen",
    };
  }

  return null;
};

export const validPackagingFormDifference = (packagingForms: PackagingForm[]) => (
  value?: string,
  allValues?: any,
  input?: any
) => {
  if (allValues === undefined || input === undefined || packagingForms.length === 0) {
    return null;
  }

  const { packaging_form_ids } = allValues;
  if (packaging_form_ids.length === 1 || packaging_form_ids[0] === undefined) {
    return null;
  }

  const selectedPackagingForms = packagingForms.filter((form) => packaging_form_ids.includes(form.id));
  const dealAmounts = selectedPackagingForms.map((form) => form.deal_amount);
  const maxDealAmount = Math.max(...dealAmounts);
  const minDealAmount = Math.min(...dealAmounts);

  if (maxDealAmount - minDealAmount > 30) {
    return {
      message: "validation.offers.must_be_difference_of_deal_amount_in_30",
    };
  }
};
