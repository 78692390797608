import querystring from "query-string";

import { PendingTask, TASK_KEYS_OF_ADVANCE_CONTRACT, TASK_KEYS_OF_SPOT_CONTRACT } from "../api/pending_tasks";
import { LOG_KEYS_OF_ADVANCE_CONTRACT, LOG_KEYS_OF_SPOT_CONTRACT, TransactionLog } from "../api/transaction_logs";

export const createPendingTaskLink = (task: PendingTask) => {
  switch (task.task_key) {
    // 事前
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_AGREE_BID_DEAL:
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_AGREE_DEAL:
      return `/deals/${task.deal_id}/show`;
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY:
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
      return `/deals/${task.amend_id}/show/delivery`;
    case TASK_KEYS_OF_ADVANCE_CONTRACT.TASK_BUYER_PAYS_AND_REPORTS:
      return `/deals/${task.amend_id}/show/payment`;

    // 現物
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_AGREE_BID_DEAL:
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_AGREE_DEAL:
      return `/spot_contract_deals/${task.spot_contract_deal_id}/show`;
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_SET_DRIVER_INFO_TO_AMEND_INDIVIDUAL_DELIVERY:
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
      return `/spot_contract_deals/${task.spot_contract_amend_id}/show/delivery`;
    case TASK_KEYS_OF_SPOT_CONTRACT.TASK_BUYER_PAYS_AND_REPORTS:
      return `/spot_contract_deals/${task.spot_contract_amend_id}/show/payment`;
    default:
      return "/";
  }
};

export const createTransactionLogLink = (log: TransactionLog) => {
  switch (log.log_key) {
    // 事前
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_BUYER_DISAGREE_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CREATE_TASK_BUYER_AGREE_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_MANAGER_CANCEL_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_SELLER_AND_BUYER_AGREE_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_SELLER_DISAGREE_BID_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_SELLER_DISAGREE_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_REMIND_TASK_BUYER_AGREE_DEAL:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_MANAGER_CREATE_PRENEGOTIATED_DEAL:
      return `/deals/${log.deal_id}/show`;
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_UPDATE_STORAGE_PERIOD_FOR_EXCEPTION_FLOW:
      return `/deals/${log.amend_id}/show/contract`;
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_BUYER_REPORT_PRODUCTS_DELIVERED:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_REMIND_BUYER_CHECK_DELIVERY_INFO:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_TOMORROW_IS_HANDOVER_DATE:
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW:
      return `/deals/${log.amend_id}/show/delivery`;
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS:
      return `/deals/${log.amend_id}/show/payment`;

    // 現物
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_BUYER_DISAGREE_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_CREATE_TASK_BUYER_AGREE_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_MANAGER_CANCEL_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_SELLER_AND_BUYER_AGREE_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_SELLER_DISAGREE_BID_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_SELLER_DISAGREE_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_REMIND_TASK_BUYER_AGREE_DEAL:
      return `/spot_contract_deals/${log.spot_contract_deal_id}/show`;
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_BUYER_REPORT_PRODUCTS_DELIVERED:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_REMIND_BUYER_CHECK_DELIVERY_INFO:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_TOMORROW_IS_HANDOVER_DATE:
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW:
      return `/spot_contract_deals/${log.spot_contract_amend_id}/show/delivery`;
    case LOG_KEYS_OF_SPOT_CONTRACT.LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS:
      return `/spot_contract_deals/${log.spot_contract_amend_id}/show/payment`;

    // 登録情報
    case LOG_KEYS_OF_ADVANCE_CONTRACT.LOG_CHANGE_BUYER_INFO:
      return createLogChangeBuyerInfoLink(log);
    default:
      return "/";
  }
};

function createLogChangeBuyerInfoLink(log: TransactionLog): string {
  if (log.log_text.includes("受取場所")) {
    return "/wholesaler_addresses";
  } else if (log.log_text.includes("アカウント")) {
    return "/wholesaler_accounts";
  } else {
    return "/";
  }
}

export function createWaitingForDealAgreementParams() {
  const params = {
    displayedFilters: JSON.stringify({
      status: true,
      wholesaler_agreement_status: true,
    }),
    filter: JSON.stringify({
      status: "provisional",
      wholesaler_agreement_status: "waiting",
    }),
  };
  return querystring.stringify(params);
}

export function createWaitingForDeliveryCreationParams() {
  const params = {
    displayedFilters: JSON.stringify({
      amend_status: true,
    }),
    filter: JSON.stringify({
      amend_status: "waiting_for_delivery_creation",
    }),
  };
  return querystring.stringify(params);
}

export function createWaitingForAfterDeliveryInspectionParams() {
  const params = {
    displayedFilters: JSON.stringify({
      amend_status: true,
    }),
    filter: JSON.stringify({
      amend_status: "waiting_for_after_delivery_inspection",
    }),
  };
  return querystring.stringify(params);
}

export function createWaitingForPaymentParams() {
  const params = {
    displayedFilters: JSON.stringify({
      amend_status: true,
    }),
    filter: JSON.stringify({
      amend_status: "waiting_for_payment",
    }),
  };
  return querystring.stringify(params);
}
