import React from "react";
import styled from "@emotion/styled";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const OfferRequestIcon = ({ width = 24, height = 24, color = "#35363a" }: Props) => (
  <Svg width={width} height={height} color={color} viewBox="0 0 30 30">
    <g transform="translate(1.183 0.705)">
      <rect className="cls-1" width="8.989" height="1.124" transform="translate(8.442 10.475)" />
      <rect className="cls-1" width="5.535" height="1.124" transform="translate(8.442 12.584)" />
      <path
        className="cls-1"
        d="M33.682,36.7H25.491a2.993,2.993,0,0,1-2.045-.8L30,29.24l3.233,1.876,3.239-1.876L39.094,31.9a1.852,1.852,0,0,1,.565-1.089l-2.144-2.179L44,24.87V31.78l2.167,1.253v-8.3a5.079,5.079,0,0,0-.087-.944,4.926,4.926,0,0,0-.3-1.025v-.006h0a5.215,5.215,0,0,0-1-1.556l-.012-.012-.023-.023-.006-.006L41.9,18.421l-.058-.052L37.02,13.731,37,13.708h0a5.188,5.188,0,0,0-7.329-.192c-.064.064-.134.128-.192.2h0l-.023.023-4.818,4.632-.047.047-.006.006-2.849,2.744-.017.017-.012.012-.012.012a5.332,5.332,0,0,0-1,1.556h0a5.306,5.306,0,0,0-.3,1.025h0a5.763,5.763,0,0,0-.093.95v8.943a5.174,5.174,0,0,0,.862,2.866h0a5.148,5.148,0,0,0,.658.8h0a5.152,5.152,0,0,0,3.664,1.515h8.2a1.419,1.419,0,0,1-.006-.157Zm7.882-15.59L43.2,22.674a3.107,3.107,0,0,1,.6.961l-2.231,1.293ZM31.02,15.229a3.028,3.028,0,0,1,4.276-.157c.052.052.111.1.157.157l3.635,3.5h-11.7Zm-4.94,4.754.087-.082H40.312l.082.082V25.61l-7.16,4.154-7.154-4.148Zm-2.808,2.692L24.9,21.107v3.822l-2.231-1.293A3.107,3.107,0,0,1,23.271,22.674Zm-.8,11v-8.8l6.478,3.764-6.2,6.3A3.047,3.047,0,0,1,22.473,33.673Z"
        transform="translate(-20.3 -12.094)"
      />
      <path
        className="cls-1"
        d="M295.283,361.156l-7.888-4.556v2.458H282v4.084h5.395v2.569Z"
        transform="translate(-266.754 -336.53)"
      />
    </g>
  </Svg>
);

const Svg = styled.svg`
  .cls-1 {
    fill: ${(props) => props.color};
  }
`;
