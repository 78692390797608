import React, { ReactNode } from "react";

import { green, blue, amber, red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

export const Alert = (props: { severity: "error" | "info" | "success" | "warning"; children?: ReactNode }) => {
  const { severity } = props;

  let Icon = InfoIcon;
  let color: any = blue;

  switch (severity) {
    case "success": {
      Icon = CheckCircleOutlineIcon;
      color = green;
      break;
    }
    case "warning": {
      Icon = WarningIcon;
      color = amber;
      break;
    }
    case "error": {
      Icon = ErrorOutlineIcon;
      color = red;
      break;
    }
  }
  const backgroundColor = color["50"];
  const iconColor = color["600"];
  const textColor = color["900"];
  return (
    <Box alignItems="center" bgcolor={backgroundColor} borderRadius={4} display="flex" mb={2} px={2} py={1}>
      <Box mr={2} color={iconColor}>
        <Icon fontSize="small" />
      </Box>
      <Box color={textColor}>
        <Box fontSize={14}>{props.children}</Box>
      </Box>
    </Box>
  );
};
