export const FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    order: "DESC",
    field: "id",
  },
  filter: {
    is_active: true,
  },
};

export const FETCH_PARAMS_FOR_THOUSAND_ITEMS = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    order: "DESC",
    field: "id",
  },
  filter: {},
};

export const INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS = {
  perPage: 1000,
  sort: {
    order: "DESC",
    field: "id",
  },
  filter: {
    is_active: true,
  },
};
