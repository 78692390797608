export const MONTHS = {
  ja: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
} as const;

export const WEEKDAYS_SHORT = {
  ja: ["日", "月", "火", "水", "木", "金", "土"],
} as const;

export const WEEKDAYS_LONG = {
  ja: ["日", "月", "火", "水", "木", "金", "土"],
} as const;

export const FIRST_DAY_OF_WEEK = {
  ja: 0,
} as const;

export const LABELS = {
  ja: { nextMonth: "次月", previousMonth: "前月" },
} as const;

export const dateTimeProps = {
  locales: "ja",
  showTime: true,
  options: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
} as const;

export const dateProps = {
  locales: "ja",
  showTime: true,
  options: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
} as const;

export function formatStringAsCurrency(value: string) {
  const num = parseFloat(value);
  if (Number.isNaN(num)) {
    return "";
  }
  return num.toLocaleString();
}

/**
 * 小数を3桁区切りで表示する関数を返す
 *
 * 小数点以下の桁は末尾の0は消す。
 * FunctionFieldのrenderでの使用を想定している。
 *
 * @param column 列名
 */
export function getFormatNumeric(column: string) {
  return (record: any) => {
    const value = record[column];
    if (value === undefined) {
      return "";
    }
    return formatStringAsCurrency(record[column]);
  };
}

export function getDateString(value: string) {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  const { year, month, day } = dateTimeProps.options;
  return date.toLocaleDateString(dateTimeProps.locales, { year, month, day });
}

export function getFormatRateString(column: string) {
  return (record: any) => {
    const value = record[column];
    if (value === undefined) {
      return "";
    }
    return `${record[column]}%`;
  };
}
