import React, { ReactElement } from "react";
import { useTranslate } from "react-admin";

import { Link } from "react-router-dom";
import { stringify } from "query-string";

import Button from "@material-ui/core/Button";
import Queue from "@material-ui/icons/Queue";

const defaultIcon = <Queue />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const omit = (record: any, omitKeys: string[]) => {
  const _record = { ...record };
  omitKeys.forEach((key) => {
    delete _record[key];
  });
  return _record;
};

const filtering = (record: any, validValues: { [key: string]: any }) => {
  let _record = { ...record };
  const invalidKeys: string[] = [];

  Object.keys(validValues).map((key: string) => {
    if (Array.isArray(record[key])) {
      if (record[key].some((value: any) => validValues[key].find((master: any) => master.id === value) === undefined)) {
        delete _record[key];
        invalidKeys.push(key);
      }
    } else {
      if (record[key] && validValues[key].find((master: any) => master.id === record[key]) === undefined) {
        delete _record[key];
        invalidKeys.push(key);
      }
    }
  });

  _record = { invalidKeys: invalidKeys, ..._record };

  return { ..._record };
};

type Props = {
  omitKeys: string[];
  disabled: boolean;
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  record?: any;
  validValues: { [key: string]: any };
};

export const CloneButton = (props: Props) => {
  const translate = useTranslate();
  const {
    basePath = "",
    label = translate("ra.action.clone"),
    record,
    icon = defaultIcon,
    omitKeys,
    disabled,
    validValues,
  } = props;

  const to = record
    ? {
        pathname: `${basePath}/create`,
        search: stringify({
          source: JSON.stringify(filtering(omit(record, omitKeys), validValues)),
        }),
      }
    : `${basePath}/create`;

  // HACK: Buttonがdisabledなときのためにspanで囲んでいる
  // Buttonがdisabledな場合にonClickが無効になり、背面の行がクリック扱いになるのを防いでいる
  return (
    <span onClick={stopPropagation}>
      <Button
        component={Link}
        color="primary"
        onClick={stopPropagation}
        size="small"
        startIcon={icon}
        to={to}
        disabled={disabled}
      >
        {label}
      </Button>
    </span>
  );
};
