import React, { isValidElement, ReactNode } from "react";
import { FormDataConsumer } from "react-admin";

export const DelivererForm = (props: { children?: ReactNode; deliverer: "self_delivery" | "hacobell" }) => {
  const { children, deliverer, ...rest } = props;
  return (
    <FormDataConsumer>
      {({ formData }: { formData: any; rest: any }) => {
        if (formData.delivery_type === deliverer) {
          return React.Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return null;
            }
            return <div>{React.cloneElement(child, { ...rest })}</div>;
          });
        }
        return null;
      }}
    </FormDataConsumer>
  );
};
