class MyError extends Error {
  constructor(...args: any) {
    super(args);
    this.name = new.target.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MyError);
    }
  }
}

export class FileSizeError extends MyError {}
export class FileNameExtensionError extends MyError {}
export class ImageProcessingError extends MyError {}

export const errorMessageFromObject = (value: any) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "object" && typeof value.message === "string") {
    return value.message;
  }
  return null;
};
