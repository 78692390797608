import { useFetchMore } from "./useFetchMore";
import { PendingTask } from "../api/pending_tasks";

export function usePendingTasks() {
  return useFetchMore<PendingTask>("pending_tasks", {
    pagination: {
      perPage: 5,
    },
    sort: {
      order: "ASC",
      field: "deadline",
    },
    filter: {},
  });
}
