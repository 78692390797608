import React, { useCallback, useReducer, useState } from "react";
import {
  email,
  maxLength,
  Toolbar,
  SaveButton,
  SimpleForm,
  Title,
  useInput,
  useTranslate,
  useNotify,
  useRefresh,
  TextInput,
} from "react-admin";
import { Checkbox, FormControlLabel, Card, CardContent } from "@material-ui/core";
import { useSettingMailNotification } from "../hooks/useSettingMailNotification";
import { getApiBase, getHttpClient, preventSendNull } from "../utils/api";
import { HiddenInput } from "../components/hidden";

type State = boolean;
type Action = {
  type: "fetched" | "fetching";
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "fetching":
      return true;
    case "fetched":
      return false;
    default:
      throw new Error();
  }
}

const MyToolbar = (props: { onSave: any; isSaving: boolean; changed: boolean; [key: string]: any }) => (
  <Toolbar {...props}>
    <SaveButton {...props} type="button" disabled={!props.changed} saving={props.isSaving} />
  </Toolbar>
);

const MyCheckbox = (props: { setSetting: any; [key: string]: any }) => {
  const translate = useTranslate();

  const {
    input: { onChange },
  } = useInput(props);

  const [value, setValue] = useState(props.record[props.name]);

  const onChangeValue = () => {
    const newSetting = { ...props.record, ...{ [props.name]: !value } };
    props.setSetting(newSetting);
    setValue(!value);
    onChange(!value);
  };

  return (
    <FormControlLabel
      label={translate(`resources.wholesaler_setting_mail_notification.fields.${props.name}`)}
      control={<Checkbox name={props.name} checked={value} onChange={onChangeValue} />}
    />
  );
};

const validateEmail = [maxLength(254), email()];

const NotificationEmailInput = (props: { setSetting: any; [key: string]: any }) => {
  const translate = useTranslate();

  const {
    input: { onChange },
  } = useInput(props);

  const onChangeValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newSetting = { ...props.record, ...{ [props.name]: ev.target.value } };
    props.setSetting(newSetting);
    onChange(newSetting[props.name]);
  };

  return (
    <TextInput
      source={props.name}
      label={translate(`resources.wholesaler_setting_mail_notification.fields.${props.name}`)}
      parse={preventSendNull}
      onChange={onChangeValue}
      validate={validateEmail}
      fullWidth
    />
  );
};

const MySimpleForm = (props: any) => {
  const translate = useTranslate();

  const [setting, setSetting] = useState(props.record);
  const [initialSetting, setInitialSetting] = useState(props.record);

  const [isChanged, setChanged] = useState(false);

  const [saving, dispatch] = useReducer(reducer, false);

  const notify = useNotify();
  const refresh = useRefresh();

  const onSave = useCallback(async () => {
    dispatch({ type: "fetching" });
    try {
      const httpClient = getHttpClient();
      const url = `${getApiBase()}/wholesalers/me/setting_mail_notification`;
      const opts = {
        method: "PUT",
        body: JSON.stringify({ ...setting }),
      };
      const res = await httpClient(url, opts);
      if (res.body.version_number > setting.version_number) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        setSetting(res.body);
        setInitialSetting(res.body);
        setChanged(false);
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      if (e instanceof Error) {
        notify(e.message, "error");
      }
    }
    dispatch({ type: "fetched" });
  }, [dispatch, notify, refresh, setting]);

  if (initialSetting !== setting) {
    const diff = Object.keys(initialSetting).map((key) => initialSetting[key] === setting[key]);
    const changed = Array.from(new Set(diff)).length === 2; // version_numberが常にtrueとなるので、すべて等しい時はlengthが1となる
    if (isChanged !== changed) {
      setChanged(changed);
    }
  }

  return (
    <SimpleForm
      {...props}
      record={setting}
      toolbar={<MyToolbar onSave={onSave} isSaving={saving} changed={isChanged} />}
    >
      <h2>{translate("resources.wholesaler_setting_mail_notification.name")}</h2>
      <MyCheckbox name="when_added_pending_task" setSetting={setSetting} />
      <MyCheckbox name="when_processed_deal" setSetting={setSetting} />
      <MyCheckbox name="when_changed_profile" setSetting={setSetting} />
      <NotificationEmailInput name="additional_email1" setSetting={setSetting} />
      <NotificationEmailInput name="additional_email2" setSetting={setSetting} />
      <NotificationEmailInput name="additional_email3" setSetting={setSetting} />
      <HiddenInput source="version_number" />
    </SimpleForm>
  );
};

export const WholesalerSettingMailNotificationEdit = (props: any) => {
  const translate = useTranslate();
  const [loadingSetting, initialSetting] = useSettingMailNotification();

  if (loadingSetting) {
    return null;
  }

  return (
    <Card>
      <Title title={translate(`resources.wholesaler_setting_mail_notification.name`)} />
      <CardContent>
        <MySimpleForm {...props} record={initialSetting} />
      </CardContent>
    </Card>
  );
};
