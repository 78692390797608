type LoginParams = {
  username: string;
  password: string;
};

export default {
  login: (params: LoginParams) => {
    const { username, password } = params;
    if (username !== password) {
      return Promise.reject("Invalid id or password");
    }
    localStorage.setItem("token", "hogehoge");
    switch (username) {
      case "admin":
        localStorage.setItem("permissions", "admin");
        break;
      case "staff":
        localStorage.setItem("permissions", "staff");
        break;
      default:
        localStorage.setItem("permissions", "observer");
        break;
    }
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkError: (error: any) => {
    return error;
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
