import { useEffect, useRef, useState } from "react";
import { getDataProvider } from "../utils/api";
import { Record } from "ra-core";

type Params = {
  pagination: {
    perPage: number;
  };
  sort: {
    order: "ASC" | "DESC";
    field: string;
  };
  filter: {};
};

function getParams(params: Params, page: number) {
  return {
    ...params,
    pagination: {
      page,
      perPage: params.pagination.perPage,
    },
  };
}

export function useFetchMore<T extends Record>(
  resource: string,
  params: Params
): [boolean, T[], boolean, () => Promise<void>] {
  const dataProvider = getDataProvider();

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [last, setLast] = useState(false);
  const isCleanedUp = useRef(false);

  const getNextPage = async () => {
    setLoading(true);
    const response: { data: Record[]; total: number } = await dataProvider.getList(resource, getParams(params, page));
    const isLast = page * params.pagination.perPage >= response.total;
    const nextPage = page + 1;
    const _data: T[] = response.data as T[];
    const nextResources = resources.concat(_data);
    if (!isCleanedUp.current) {
      setLast(isLast);
      setPage(nextPage);
      setResources(nextResources);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => getNextPage())();
    return () => {
      isCleanedUp.current = true;
    };
  }, []);

  return [loading, resources, last, getNextPage];
}
