import React from "react";

type CanSelfInspectFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
};

export const CanSelfInspectField = (props: CanSelfInspectFieldProps) => {
  const { record, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const isSelfInspect: boolean = record[props.source];

  return <span {...rest}>{isSelfInspect ? "可能" : "不可能"}</span>;
};
