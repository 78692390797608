import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import { INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";
import { preventSendNull } from "../utils/api";
import { validateMaxLengthText, validateRequired, validateRequiredMaxLengthText } from "../utils/validator";
import { CheckedOnlyField } from "../components/checked_only_field";
import { WholesalerAddressShowActions } from "../components/WholesalerAddressShowActions";

export const WholesalerAddressList = (props: any) => (
  <List bulkActionButtons={false} exporter={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <ReferenceField source="prefecture_id" reference="prefectures" sortable={false} link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="city" sortable={false} />
      <TextField source="address_1" sortable={false} />
      <TextField source="address_2" sortable={false} />
      <TextField source="phone_number" sortable={false} />
      <CheckedOnlyField source="is_default" sortable={false} />
    </Datagrid>
  </List>
);

export const WholesalerAddressShow = (props: any) => (
  <Show actions={<WholesalerAddressShowActions permissions={props.permissions} />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="prefecture_id" reference="prefectures" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="city" />
      <TextField source="address_1" />
      <TextField source="address_2" />
      <TextField source="phone_number" />
      <TextField source="memo" component="pre" />
    </SimpleShowLayout>
  </Show>
);

const validateName = validateMaxLengthText(50);
const validateCity = validateRequiredMaxLengthText(50);
const validateAddress1 = validateRequiredMaxLengthText(50);
const validateAddress2 = validateMaxLengthText(50);
const validatePhoneNumber = validateRequiredMaxLengthText(50);
const validateMemo = validateMaxLengthText(256);

export const WholesalerAddressCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show" submitOnEnter={false}>
      <TextInput source="name" parse={preventSendNull} initialValue="" validate={validateName} />
      <ReferenceInput source="prefecture_id" reference="prefectures" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
        <SelectInput optionText="name" validate={validateRequired} />
      </ReferenceInput>
      <TextInput source="city" parse={preventSendNull} initialValue="" validate={validateCity} />
      <TextInput source="address_1" parse={preventSendNull} initialValue="" validate={validateAddress1} />
      <TextInput source="address_2" parse={preventSendNull} initialValue="" validate={validateAddress2} />
      <TextInput source="phone_number" parse={preventSendNull} validate={validatePhoneNumber} />
      <TextInput source="memo" parse={preventSendNull} initialValue="" validate={validateMemo} fullWidth multiline />
    </SimpleForm>
  </Create>
);

export const WholesalerAddressEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="show" submitOnEnter={false}>
      <TextField source="id" />
      <TextInput source="name" parse={preventSendNull} validate={validateName} />
      <ReferenceInput source="prefecture_id" reference="prefectures" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
        <SelectInput optionText="name" validate={validateRequired} />
      </ReferenceInput>
      <TextInput source="city" parse={preventSendNull} validate={validateCity} />
      <TextInput source="address_1" parse={preventSendNull} validate={validateAddress1} />
      <TextInput source="address_2" parse={preventSendNull} validate={validateAddress2} />
      <TextInput source="phone_number" parse={preventSendNull} validate={validatePhoneNumber} />
      <TextInput source="memo" parse={preventSendNull} validate={validateMemo} fullWidth multiline />
    </SimpleForm>
  </Edit>
);
