import React from "react";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { DateTime } from "luxon";

type Props = {
  source: "wholesaler_agreement_status";
  record?: {
    wholesaler_agreement_status: string;
    wholesaler_agreement_at: string;
    resubmit_requested_at: string;
  };
  sortable?: boolean;
  showTime?: boolean;
};

export const WholesalerAgreementStatusField = (props: Props) => {
  const translate = useTranslate();

  const { record, showTime } = props;
  if (!record) {
    return null;
  }

  const { resubmit_requested_at, wholesaler_agreement_at, wholesaler_agreement_status } = record;

  let text = translate(`commons.agreement_statuses.${wholesaler_agreement_status}`);

  // 再提示がされている場合は再提示と表示する
  if (resubmit_requested_at) {
    text = "再提示";
  }

  if (showTime === true) {
    const time = DateTime.fromISO(wholesaler_agreement_at);
    return (
      <Typography variant="body2">
        {text}
        {time.isValid && (
          <Typography variant="caption" display="inline" style={{ marginLeft: "1em" }}>
            {time.toFormat("yyyy/LL/dd HH:mm:ss")}
          </Typography>
        )}
      </Typography>
    );
  }

  return <Typography variant="body2">{text}</Typography>;
};

WholesalerAgreementStatusField.defaultProps = {
  addLabel: true,
};
