import React, { useCallback, useEffect, useState } from "react";
import { FormDataConsumer, useDataProvider, useInput, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/styles";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import LinearProgress from "./LinerProgress";
import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";

import { Brand, FetchedListData, Prefecture } from "../infrastructure/types";

const useStyles = makeStyles({
  form: {
    width: 256,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  checkbox: {
    height: 52,
    margin: "8px 0 4px",
  },
});

export const BrandSelectInput = (props: any) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const { input, isRequired, meta } = useInput(props);
  const { onBlur, onChange, value } = input;
  const { error, touched } = meta;

  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const [isFiltered, setIsFiltered] = useState(true);

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const params = {
        ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS,
      };
      const fetchedBrands: FetchedListData<Brand> = await dataProvider.getList("brands", params);
      const fetchedPrefectures: FetchedListData<Prefecture> = await dataProvider.getList("prefectures", params);

      if (!isCleanedUp) {
        if (fetchedBrands && fetchedPrefectures) {
          const initialBrandId = props.record.brand_id;
          onChange(initialBrandId);
          setBrands(fetchedBrands.data);
          setPrefectures(fetchedPrefectures.data);

          const _prefecture_ids = props.record.prefecture_ids || [];
          const prefectureIds = fetchedPrefectures.data.filter((p) => _prefecture_ids.includes(p.id)).map((p) => p.id);

          // 新規登録時は絞り込みありとし、編集 or 複製時はその銘柄に県産が登録されているかで絞り込みを判断
          setIsFiltered(() => {
            if (!prefectureIds.length) {
              // 県産未選択
              return !initialBrandId;
            }
            const filteredBrands = fetchedBrands.data.filter((brand) =>
              prefectureIds.every((pid) => brand.prefecture_ids.includes(pid))
            );
            return filteredBrands.some((brand) => brand.id === initialBrandId);
          });
        }
        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, props.record]);

  const handleChangeCheckbox = useCallback(() => setIsFiltered(!isFiltered), [isFiltered, setIsFiltered]);
  const handleChangeSelect = (ev: React.ChangeEvent<{ value: unknown }>) => onChange(ev.target.value);

  const label = translate(`resources.offers.fields.brand_id`);
  const requiredLabel = isRequired ? " *" : "";

  if (isLoading) {
    return (
      <div>
        <InputLabel shrink={true}>{`${label}${requiredLabel}`}</InputLabel>
        <LinearProgress />
      </div>
    );
  }

  return (
    <FormDataConsumer>
      {(formProps: any) => {
        const { formData } = formProps;
        let items = brands.concat([]);

        if (isFiltered) {
          const _prefecture_ids = formData.prefecture_ids ? formData.prefecture_ids : [];
          const prefectureIds = prefectures.filter((p) => _prefecture_ids.includes(p.id)).map((p) => p.id);

          // 県産が選択されている場合、選択している県産が全て含まれている銘柄のみを抽出する
          // 県産が未選択の場合はフィルタリングをしない
          if (prefectureIds.length !== 0) {
            items = items.filter((brand) => prefectureIds.every((pid) => brand.prefecture_ids.includes(pid)));
          }

          // 選択している県産を含まない銘柄の場合は選択をリセットする
          const item_ids = items.map((i) => i.id);
          if (!item_ids.includes(value)) {
            onChange("");
          }
        }

        const sortedItems = items.concat([]).sort((a, b) => {
          // 銘柄不問は一番最後に来るようにする
          if (a.name.includes("銘柄不問")) {
            return 1;
          }
          if (b.name.includes("銘柄不問")) {
            return -1;
          }

          // それ以外はふりがなであいうえお順
          return a.name_kana.localeCompare(b.name_kana, "ja");
        });

        return (
          <>
            <FormControl variant="filled" className={classes.form} margin="dense" error={touched && error}>
              <InputLabel id="label">{`${label}${requiredLabel}`}</InputLabel>
              <Select
                labelId="label"
                id="select"
                value={value}
                onBlur={(event) => onBlur(event)}
                onChange={handleChangeSelect}
              >
                {sortedItems.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{touched && error && translate(error)}&#8203;</FormHelperText>
            </FormControl>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox checked={isFiltered} color="primary" name="filtered" onChange={handleChangeCheckbox} />
              }
              label="指定した県産に登録のある銘柄に絞り込む"
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};
