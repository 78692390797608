import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";

import { common } from "@material-ui/core/colors";
import Grey from "@material-ui/core/colors/grey";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import { usePendingTasks } from "../hooks/usePendingTasks";
import { useTransactionLogs } from "../hooks/useTransactionLogs";
import { createPendingTaskLink, createTransactionLogLink } from "../utils/url";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: Grey["700"],
  },
  more: {
    fontSize: 14,
  },
  empty: {
    color: Grey["700"],
  },
});

const More = () => {
  const styles = useStyles();
  const translate = useTranslate();
  return (
    <Typography align="center" className={styles.more}>
      {translate("ui.dashboard.show_more")}
    </Typography>
  );
};

const ListContainer = (props: { children?: ReactNode }) => {
  return (
    <Box bgcolor={common.white} borderRadius={4} boxShadow={2}>
      {props.children}
    </Box>
  );
};

const LinkList = (props: {
  name: string;
  items: {
    id: number;
    text: string;
    to: string;
  }[];
  loading: boolean;
  more: boolean;
  onClickMore: () => void;
}) => {
  const { items, loading, more, name, onClickMore } = props;
  const styles = useStyles();
  const translate = useTranslate();

  if (items.length === 0) {
    if (loading) {
      return (
        <ListContainer>
          <Box p={8} textAlign="center">
            <CircularProgress />
          </Box>
        </ListContainer>
      );
    }
    return (
      <ListContainer>
        <Box p={4} className={styles.empty}>
          {translate("ra.page.empty", { name })}
        </Box>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <List>
        {items.map((item, index) => {
          const divider = more || items.length - 1 !== index;
          return (
            <Link key={item.id} to={item.to} className={styles.link}>
              <ListItem button={true} divider={divider}>
                <ListItemText primary={item.text} />
              </ListItem>
            </Link>
          );
        })}
        {loading && (
          <Box p={2} textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && more && (
          <ListItem key={"more"} button={true} divider={false} onClick={onClickMore}>
            <ListItemText primary={<More />} disableTypography={true} />
          </ListItem>
        )}
      </List>
    </ListContainer>
  );
};

export const PendingTaskList = () => {
  const translate = useTranslate();
  const [loading, pendingTasks, last, getNextPage] = usePendingTasks();
  const pendingTaskItems = pendingTasks.map((pt) => ({
    id: pt.id,
    text: pt.description,
    to: createPendingTaskLink(pt),
  }));

  const more = !last;
  const onClickMore = async () => await getNextPage();
  return (
    <LinkList
      more={more}
      loading={loading}
      onClickMore={onClickMore}
      items={pendingTaskItems}
      name={translate("resources.pending_tasks.name")}
    />
  );
};

export const TransactionLogList = () => {
  const translate = useTranslate();
  const [loading, transactionLogs, last, getNextPage] = useTransactionLogs();
  const transactionLogItems = transactionLogs.map((log) => ({
    id: log.id,
    text: log.log_text,
    to: createTransactionLogLink(log),
  }));

  const more = !last;
  const onClickMore = async () => await getNextPage();
  return (
    <LinkList
      more={more}
      loading={loading}
      onClickMore={onClickMore}
      items={transactionLogItems}
      name={translate("resources.transaction_logs.name")}
    />
  );
};
