import React from "react";
import { useCallback } from "react";
import { useRedirect, useTranslate } from "ra-core";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

type Props = {
  reference: string;
  target: string;
  permissions: any;

  hasVersion?: boolean;

  // react-adminから暗黙的に渡されるprops
  basePath?: string;
  resource?: string;
  record?: { [key: string]: any };
};

const useStyles = makeStyles((theme: any) => {
  return {
    toolbar: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    button: {
      color: "#008ee5",
      "&:hover": {
        backgroundColor: "rgba(0, 142, 229, 0.04)",
      },
    },
  };
});

const CreateRelatedResourceButton = (props: Props) => {
  const { hasVersion, permissions, reference, record, target } = props;

  const classes = useStyles();
  const redirect = useRedirect();
  const translate = useTranslate();

  const createOnClick = useCallback(
    (source: { [key: string]: any }) => () => {
      const params = `source=${JSON.stringify(source)}`;
      redirect(`/${reference}/create?${params}`);
    },
    [redirect, reference]
  );

  if (!record || !["admin", "staff"].includes(permissions)) {
    return null;
  }

  let onClick;
  if (hasVersion) {
    const { id, version_number } = record;
    const source = {
      [target]: id,
      version_number,
    };
    onClick = createOnClick(source);
  } else {
    const { id } = record;
    const source = {
      [target]: id,
    };
    onClick = createOnClick(source);
  }

  return (
    <div className={classes.toolbar}>
      <Button size="small" startIcon={<AddIcon />} className={classes.button} onClick={onClick}>
        {translate("ra.action.create")}
      </Button>
    </div>
  );
};

export default CreateRelatedResourceButton;
