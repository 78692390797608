import React from "react";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";

type Props = {
  source: string;
  record?: any;
  fromDeal?: boolean;
};

export const getExpectedHarvestTimeframeString = (value: string | null | undefined, translate: any) => {
  if (value === undefined) {
    return null;
  }

  if (value === null) {
    return translate("ui.value.harvest_plan_not_set");
  }

  const [year, month, day] = value.split("-");
  let shun = "";
  switch (day) {
    case "01":
      shun = translate("ui.value.early");
      break;
    case "11":
      shun = translate("ui.value.mid");
      break;
    case "21":
      shun = translate("ui.value.late");
      break;
  }

  return `${year}年${month}月${shun}`;
};

export const HarvestPlanField = (props: Props) => {
  const { record, source, fromDeal } = props;
  const translate = useTranslate();
  if (!record) {
    return null;
  }

  let value = null;
  if (!fromDeal && record["amend"]) {
    value = record["amend"][source];
  } else {
    value = record[source];
  }

  return <Typography variant="body2">{getExpectedHarvestTimeframeString(value, translate)}</Typography>;
};

HarvestPlanField.defaultProps = {
  addLabel: true,
};
