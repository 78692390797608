import { parse as parseCookie } from "cookie";
import { redirect503 } from "../utils/route";

type LoginParams = {
  username: string;
  password: string;
};

const CSRF_TOKEN_KEY = "__Host-an-ki-b-csrf-token";

const getToken = async (username: string, password: string) => {
  const body = {
    login_id: username,
    password,
  };
  const postTokenOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify(body),
  };
  const tokenResponse = await fetch("/buyer-api/v1/tokens", postTokenOpts);
  return tokenResponse.status;
};

const deleteToken = async () => {
  const myCookie = parseCookie(document.cookie);
  const CsrfToken = myCookie[CSRF_TOKEN_KEY];
  const deleteTokenOpts = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-An-Ki-Csrf-Token": CsrfToken,
    },
  };
  await fetch("/buyer-api/v1/tokens", deleteTokenOpts);
  localStorage.removeItem("permissions");
};

const fetchLoginInfo = async () => {
  const myCookie = parseCookie(document.cookie);
  const CsrfToken = myCookie[CSRF_TOKEN_KEY];
  const getLoginInfoOpts = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-An-Ki-Csrf-Token": CsrfToken,
    },
  };
  return await fetch("/buyer-api/v1/login_info", getLoginInfoOpts);
};

const ping = async () => {
  const response = await fetchLoginInfo();
  return response.status;
};

const getLoginInfo = async () => {
  const response = await fetchLoginInfo();
  return await response.json();
};

export default {
  login: async (params: LoginParams) => {
    const { username, password } = params;
    const statusCode = await getToken(username, password);

    if (statusCode === 401) {
      return Promise.reject("Invalid Credential");
    } else if (statusCode === 503) {
      redirect503();
      return Promise.reject("Under Maintenance");
    } else if (statusCode !== 201) {
      return Promise.reject("Unknown Error");
    }

    const loginInfo = await getLoginInfo();
    const { role } = loginInfo.account;
    switch (role) {
      case "admin":
        localStorage.setItem("permissions", "admin");
        break;
      case "staff":
        localStorage.setItem("permissions", "staff");
        break;
      default:
        localStorage.setItem("permissions", "observer");
        break;
    }
    return Promise.resolve();
  },
  logout: async () => {
    await deleteToken();
    return Promise.resolve();
  },
  checkError: async (error: any) => {
    const status = error.status;
    if (status === 401) {
      await deleteToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const status = await ping();
    if (status === 401) {
      await deleteToken();
      return Promise.reject();
    } else if (status === 503) {
      redirect503();
      return Promise.reject();
    }

    const myCookie = parseCookie(document.cookie);
    const hasToken = !!myCookie[CSRF_TOKEN_KEY];
    return hasToken ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
