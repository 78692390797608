// in src/customRoutes.js
import * as React from "react";
import { Route } from "react-router-dom";
import { WholesalerSettingMailNotificationEdit } from "./pages/wholesaler_setting_mail_notification";

export default [
  <Route
    key="wholesaler_setting_mail_notification"
    exact
    path="/wholesalers/me/setting_mail_notification"
    component={WholesalerSettingMailNotificationEdit}
  />,
];
