import React from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Typography from "@material-ui/core/Typography";

type Props = {
  source: string;
  record?: any;
  label?: string;
  sortable?: boolean;
};

export const InvoiceRegistrationStatusField = (props: Props) => {
  const { record } = props;

  if (!record) {
    return null;
  }

  const producer = record["producer"];
  const canDisplayLink =
    producer["invoice_registration_status"] === "registered" && !!producer["invoice_registration_number"];

  const statusLabel = getInvoiceRegistrationStatus(producer);

  if (producer["invoice_registration_status"] === "registered") {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">{statusLabel}</Typography>
        {canDisplayLink && (
          <Link
            variant="body2"
            href={`https://www.invoice-kohyo.nta.go.jp/regno-search/detail?selRegNo=${producer["invoice_registration_number"]}`}
            target="_blank"
          >
            <OpenInNewIcon fontSize="small" />
            「適格請求書発行事業者公表サイト」で確認
          </Link>
        )}
      </Box>
    );
  } else {
    return <Typography variant="body2">{statusLabel}</Typography>;
  }
};

function getInvoiceRegistrationStatus(producer: any) {
  const value = producer["invoice_registration_status"];
  if (value === "registered") {
    return (
      "取得済" +
      (producer["invoice_registration_number"] ? `（T${producer["invoice_registration_number"]}）` : "（番号未登録）")
    );
  } else if (value === "will_register") {
    const plan = producer["expected_registration_timeframe"] && new Date(producer["expected_registration_timeframe"]);
    const planLabel = plan ? `${plan.getFullYear()}年${plan.getMonth() + 1}月` : "時期未定";
    return `取得予定（${planLabel}）`;
  } else if (value === "wont_register") {
    return "取得しない";
  } else {
    return "未定/わからない";
  }
}

InvoiceRegistrationStatusField.defaultProps = {
  addLabel: true,
};
