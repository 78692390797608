import React from "react";

export const ProducerSummaryTitle = (props: any) => {
  const { record } = props;
  if (record === undefined) {
    return <span {...props}>生産者情報</span>;
  }

  return <span {...props}>{`生産者情報 ${record["name"]}`}</span>;
};
