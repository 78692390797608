import { useEffect, useRef, useState } from "react";
import { getDataProvider } from "../utils/api";
import { WholesalerAddress } from "../api/wholesaler_addresses";

export function useWholesalerAddresses(): [boolean, WholesalerAddress[]] {
  const [loading, setLoading] = useState(false);
  const [wholesalerAddresses, setWholesalerAddresses] = useState<WholesalerAddress[]>([]);
  const isCleanedUp = useRef(false);
  const dataProvider = getDataProvider();

  useEffect(() => {
    (async () => {
      setLoading(true);
      // TODO 100件だけでなく再帰的に全件取得する
      const response: { data: any[]; total: number } = await dataProvider.getList("wholesaler_addresses", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          order: "DESC",
          field: "id",
        },
        filter: {},
      });
      if (!isCleanedUp.current) {
        const { data }: { data: WholesalerAddress[] } = response;
        setWholesalerAddresses(data);
        setLoading(false);
      }
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [dataProvider]);
  return [loading, wholesalerAddresses];
}
