import { useState } from "react";
import { upload } from "../utils/file";
import { UUID } from "../api/common";

export const useFileUpload = (): [boolean, (file: File) => Promise<UUID | null>, null | Error] => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const uploadFunc = async (selectedFile: File) => {
    let uuid: UUID | null = null;
    setUploading(true);
    setError(null);
    try {
      uuid = await upload(selectedFile);
    } catch (error) {
      setError(error as any);
      // TODO Sentryに送る
      console.error(error);
    } finally {
      setUploading(false);
    }
    return uuid;
  };

  return [uploading, uploadFunc, error];
};
