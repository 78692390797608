import React from "react";
import { SelectInput, useInput } from "react-admin";

type Choice = {
  id: number;
  name: string;
};

type Props = {
  selectedId: number;
  choices: Choice[];
};

export const RelationalSelectInput = (props: Props) => {
  const { selectedId, choices, ...rest } = props;
  const { input } = useInput(props);

  // 選択しているIDのものが選択肢になかったらリセットする
  const changed = choices.every((i) => i.id !== selectedId);
  if (changed && selectedId) {
    input.onChange(null);
  }

  let helperText = null;
  if (choices.length === 0) {
    helperText = "選択できる項目がありません。";
  }

  return <SelectInput {...rest} choices={choices} helperText={helperText} />;
};
