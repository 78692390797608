import React from "react";
import { Title, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { DashboardCounts } from "../components/DashboardCounts";
import { PendingTaskList, TransactionLogList } from "../components/LinkList";

const useStyles = makeStyles({
  listTitle: {
    color: "#666666",
    fontSize: 18,
    marginBottom: 8,
  },
});

export const Dashboard = () => {
  const styles = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Title title={translate("ui.dashboard.title")} />
      <Box maxWidth={1280}>
        <Box>
          <DashboardCounts />
        </Box>
        <Box display="flex" width={1} mt={6}>
          <Box flex={1}>
            <Typography component="h3" className={styles.listTitle}>
              {translate("resources.pending_tasks.name")}
            </Typography>
            <PendingTaskList />
          </Box>
          <Box flex={1} ml="48px">
            <Typography component="h3" className={styles.listTitle}>
              {translate("resources.transaction_logs.name")}
            </Typography>
            <TransactionLogList />
          </Box>
        </Box>
      </Box>
    </>
  );
};
