import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";

import { Payment } from "../api/payments";
import { AttachmentFileField } from "../components/AttachmentFile";
import { getFormatNumeric } from "../utils/locale";
import {
  createDeliveryCategories,
  createDeliverySubcategories,
  createIsProductAdjustments,
} from "../components/choices";
import { UpdatePaymentButton } from "../components/UpdateSpotContractPaymentButton";
import { MemoField } from "../components/MemoField";

export const SpotContractPaymentShow = (props: {
  permissions?: string | string[];
  record?: Payment;
  resource?: string;
}) => {
  const { permissions, record, resource } = props;
  const translate = useTranslate();
  if (!record || !resource) {
    return null;
  }
  const hasWritePermission = permissions === "admin" || permissions === "staff";
  return (
    <Show {...props} title="" resource="spot_contract_payments">
      <SimpleShowLayout>
        <ArrayField source="product_payments">
          <Datagrid>
            <TextField source="grade.name" emptyText={translate("ui.value.default_grade")} sortable={false} />
            <FunctionField source="tax_rate" render={getFormatNumeric("tax_rate")} sortable={false} />
            <FunctionField source="unit_price" render={getFormatNumeric("unit_price")} sortable={false} />
            <TextField source="sacks" />
            <FunctionField
              source="trading_volume_in_yen"
              render={getFormatNumeric("trading_volume_in_yen")}
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
        <ArrayField source="delivery_payments">
          <Datagrid>
            <SelectField source="category" choices={createDeliveryCategories()} sortable={false} />
            <SelectField source="subcategory" choices={createDeliverySubcategories()} sortable={false} />
            <FunctionField source="tax_rate" render={getFormatNumeric("tax_rate")} sortable={false} />
            <FunctionField source="fee_in_yen" render={getFormatNumeric("fee_in_yen")} sortable={false} />
          </Datagrid>
        </ArrayField>
        <ArrayField source="adjustments">
          <Datagrid>
            <TextField source="title" sortable={false} />
            <SelectField source="is_product_adjustment" choices={createIsProductAdjustments()} sortable={false} />
            <FunctionField source="tax_rate" render={getFormatNumeric("tax_rate")} sortable={false} />
            <FunctionField source="adjustment_in_yen" render={getFormatNumeric("adjustment_in_yen")} sortable={false} />
            <MemoField source="memo" sortable={false} />
          </Datagrid>
        </ArrayField>
        <FunctionField source="commission_fee_tax_rate" render={getFormatNumeric("commission_fee_tax_rate")} />
        <FunctionField source="commission_fee_in_yen" render={getFormatNumeric("commission_fee_in_yen")} />
        <FunctionField source="total_bill_in_yen" render={getFormatNumeric("total_bill_in_yen")} />
        <DateField source="payment_due_date" locales="ja" />
        <AttachmentFileField source="invoice" />
        <ArrayField source="attachments">
          <Datagrid>
            <AttachmentFileField addLabel={false} />
          </Datagrid>
        </ArrayField>
        {hasWritePermission && <UpdatePaymentButton />}
      </SimpleShowLayout>
    </Show>
  );
};
