import React, { useCallback, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { getApiBase, getHttpClient } from "../utils/api";
import { maxValue, minValue, number, required, useNotify, useRefresh, useTranslate } from "react-admin";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { likeInteger, steppedStringPrice } from "../utils/offer_validator";
import { errorMessageFromObject } from "../utils/error";
import { Deal } from "../infrastructure/types";
import { formatStringAsCurrency } from "../utils/locale";

const useStyles = makeStyles({
  button: {
    color: "rgb(67, 160, 71)",
  },
  totalUnitPrice: {
    fontSize: 20,
    fontWeight: "bold",
  },
  inputUnitPriceAdjustmentInYen: {
    width: "100%",
  },
  table: {
    width: 500,
  },
});

const getValidateUnitPriceAdjustmentInYenFunctionList = () => {
  return [required(), number(), minValue(0), maxValue(1000), likeInteger, steppedStringPrice];
};

type DealTableProperty = {
  styles: any;
  error: string;
  data: Deal;
  unitPriceAdjustmentInYen: string;
};

const DealTable = (props: DealTableProperty) => {
  const { error, styles, unitPriceAdjustmentInYen } = props;
  const { unit_price: total_unit_price, unit_price_adjustment_in_yen } = props.data;
  const unit_price = total_unit_price - unit_price_adjustment_in_yen;
  const resubmitText = (
    <Typography variant="body2" className={styles.totalUnitPrice}>
      {"再提示単価"}
    </Typography>
  );
  const resubmitValue =
    !error &&
    unitPriceAdjustmentInYen !== "" &&
    formatStringAsCurrency(String(unit_price + parseInt(unitPriceAdjustmentInYen, 10)));
  const resubmitStringValue =
    resubmitValue !== false ? (
      <Typography variant="body2" className={styles.totalUnitPrice}>
        {resubmitValue + "円"}
      </Typography>
    ) : (
      ""
    );
  const dealData = [
    { id: 0, text: "当初のオファー単価", value: `${formatStringAsCurrency(String(unit_price))}円（保管料込）` },
    { id: 1, text: "生産者の増額希望", value: `${formatStringAsCurrency(String(unit_price_adjustment_in_yen))}円` },
    {
      id: 2,
      text: "生産者の増額希望を受けた場合の単価",
      value: `${formatStringAsCurrency(String(total_unit_price))}円`,
    },
    { id: 3, text: resubmitText, value: resubmitStringValue },
  ];

  return (
    <TableContainer className={styles.table}>
      <Table size="small">
        <TableBody>
          {dealData.map((deal) => {
            const { id, text, value } = deal;
            return (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  {text}
                </TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type ResubmitDialogProperty = {
  dispatch: React.Dispatch<any>;
  isOpen: boolean;
  onClose: () => void;
  data: Deal;
};

export const ResubmitDialog = (props: ResubmitDialogProperty) => {
  const { dispatch, isOpen, onClose } = props;
  const { id, version_number } = props.data;
  const [error, setError] = useState("");

  const [unitPriceAdjustmentInYen, setUnitPriceAdjustmentInYen] = useState("");

  const handleClose = () => {
    setUnitPriceAdjustmentInYen("");
    setError("");
    onClose();
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match(/\s/)) {
      return null;
    }

    const validationErrors = getValidateUnitPriceAdjustmentInYenFunctionList().reduce((previous, current) => {
      const errorObject = current(inputValue);

      if (errorObject && (typeof errorObject === "string" || typeof errorObject === "object")) {
        const errorMessage = errorMessageFromObject(errorObject);
        if (errorMessage && errorMessage.startsWith("ra.validation")) {
          return previous.concat(translate(errorMessage, errorObject.args));
        } else {
          return previous.concat(errorMessage);
        }
      }
      return previous;
    }, []);

    setUnitPriceAdjustmentInYen(inputValue);
    if (validationErrors.length > 0) {
      setError(validationErrors[0]);
    } else {
      setError("");
    }
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const styles = useStyles();

  const handleClickResubmit = useCallback(async () => {
    dispatch({ type: "fetching" });

    // react-admin的に例外的なURLなのでDataProviderを使わずに更新する
    const data = {
      version_number,
      unit_price_adjustment_in_yen: parseInt(unitPriceAdjustmentInYen, 10),
    };
    const params = {
      method: "PUT",
      body: JSON.stringify(data),
    };
    const client = getHttpClient();
    try {
      const res = await client(getApiBase() + `/deals/${id}/resubmit_request`, params);
      const { body } = res;

      if (body.id === id) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      if (e instanceof Error) {
        notify(e.message, "error");
      }
    } finally {
      handleClose();
    }

    dispatch({ type: "fetched" });
  }, [dispatch, id, notify, refresh, version_number, unitPriceAdjustmentInYen]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">生産者の増額希望に回答する</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2">俵あたりの単価増額金額を入力してください</Typography>
          <Typography variant="body2">当初のオファー単価で再提示する場合は「0」と入力してください</Typography>
          <br />
          <DealTable
            data={props.data}
            error={error}
            styles={styles}
            unitPriceAdjustmentInYen={unitPriceAdjustmentInYen}
          />
        </DialogContentText>
        <br />
        <TextField
          autoFocus
          className={styles.inputUnitPriceAdjustmentInYen}
          error={!!error}
          helperText={error}
          id="unit_price_adjustment_in_yen"
          label="俵あたりの単価増額金額"
          required={true}
          value={unitPriceAdjustmentInYen}
          onChange={handleChangeInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} startIcon={<ErrorOutlineOutlinedIcon />}>
          {translate("ra.action.cancel")}
        </Button>
        <Button
          disabled={!!error || unitPriceAdjustmentInYen === ""}
          onClick={handleClickResubmit}
          startIcon={<CheckCircleIcon />}
          className={styles.button}
        >
          {translate("ui.deals.agreement.representation")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
