import React, { useState } from "react";
import { useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles({
  icon: {
    fontSize: 22,
  },
  label: {
    display: "inline-block",
    marginLeft: 7,
  },
});

export const RegisterHacobellButton = (props: { disabled: boolean; submit: () => void }) => {
  const { disabled, submit } = props;
  const [open, setOpen] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const styles = useStyles();
  const translate = useTranslate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDisagree = () => {
    setOpen(false);
  };

  const handleClickAgree = () => {
    setAgreement(!agreement);
  };

  const handleSubmit = () => {
    submit();
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" disabled={disabled} onClick={handleClickOpen}>
        <PublishIcon className={styles.icon} />
        <span className={styles.label}>{translate("ui.individual_deliveries.register_hacobell")}</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleDisagree}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate("ui.individual_deliveries.register_modal_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TermOfService />
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox checked={agreement} onChange={handleClickAgree} name="agree" />}
            label={translate("ui.individual_deliveries.check_agree_term")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            {translate("ra.action.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!agreement}>
            <PublishIcon className={styles.icon} />
            <span className={styles.label}>{translate("ui.individual_deliveries.register_hacobell")}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TermOfService = () => {
  return (
    <iframe
      src="/terms/terms_delivery.pdf#view=FitH"
      title="ハコベル利用規約"
      style={{
        width: "80vw",
        height: "50vh",
      }}
    ></iframe>
  );
};
