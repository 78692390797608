import { useEffect, useRef, useState } from "react";

import { getApiBase, getHttpClient } from "../utils/api";
import { LoginInfo } from "../api/login_info";

export function useLoginInfo(): [boolean, LoginInfo | null] {
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState<LoginInfo | null>(null);
  const isCleanedUp = useRef(false);
  const httpClient = getHttpClient();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await httpClient(getApiBase() + "/login_info");
      if (!isCleanedUp.current) {
        const data: LoginInfo = response.json;
        setLoginInfo(data);
        setLoading(false);
      }
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [httpClient]);

  return [loading, loginInfo];
}
