import { useState } from "react";
import { Payment, PaymentStatus } from "../api/payments";
import { useDataProvider, useNotify, useRefresh } from "react-admin";

export const useUpdateSpotContractPaymentStatus = (): [
  boolean,
  (status: PaymentStatus, record: Payment | undefined) => Promise<void>
] => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const update = async (status: PaymentStatus, record: Payment | undefined) => {
    if (!record) {
      return;
    }
    const { amend_id, id, individual_delivery_id, version_number } = record;
    const data = {
      id,
      amend_id,
      individual_delivery_id,
      version_number,
      status,
    };
    const params = { id, data };

    setLoading(true);
    try {
      const res = await dataProvider.update("spot_contract_payment_statuses", params);
      if (res.data.id === id) {
        notify("ra.notification.updated", "info", { smart_count: 1 });
        refresh();
      } else {
        notify("ra.message.error", "error");
      }
    } catch (e) {
      notify((e as any).message, "error");
    }
    setLoading(false);
  };

  return [loading, update];
};
