import { stringify } from "query-string";
// eslint-disable-next-line no-unused-vars
import { fetchUtils, DataProvider } from "ra-core";

/**
 * react-admin data provider for dummy server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 */
export default (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      _sortField: field,
      _sortDir: order,
      _perPage: perPage,
      _page: page,
      _filters: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      const total = 100;
      return {
        data: json,
        total,
      };
    });
  },

  getOne: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({ data: json })),

  getOneVersion: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}/${params.version_number}`).then(({ json }) => ({ data: json })),

  getMany: (resource, params) => {
    return Promise.all(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`))).then((responses: any) => ({
      data: responses.map((res: any) => res.json),
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sortField: field,
      _sortDir: order,
      _perPage: perPage,
      _page: page,
      _filters: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      const total = 100;
      return {
        data: json,
        total,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

export const getHttpClient = () => {
  return async (url: string, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers();
    }
    options.headers.append("Accept", "application/json");
    options.headers.append("Content-Type", "application/json");
    options.headers.append("X-An-Ki-Csrf-Token", "hogehoge");
    const response = await fetch(url, options);
    const json = await response.json();
    return {
      status: response.status,
      headers: response.headers,
      body: json,
      json,
    };
  };
};
