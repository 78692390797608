import AuthProvider from "../infrastructure/authProvider";
import DataProvider, { getHttpClient as getRealHttpClient } from "../infrastructure/dataProvider";
import mockAuthProvider from "../infrastructure/mockAuthProvider";
import mockDataProvider, { getHttpClient as getMockHttpClient } from "../infrastructure/mockDataProvider";
import { DataProvider as DataProvideType } from "ra-core";

// Fetch like Interface
// 本家fetchの制限をゆるくしたもの
type ResponseLike = {
  status: number;
  headers: Headers;
  body: any;
  json: any;
};
export type FetchLike = (url: string, options?: any) => Promise<ResponseLike>;

let httpClient: FetchLike | null = null;
let dataProvider: DataProvideType | null = null;

export function getApiBase() {
  return "/buyer-api/v1";
}

export function getHttpClient() {
  if (httpClient === null) {
    if (process.env.REACT_APP_MOCK_API === "true") {
      httpClient = getMockHttpClient();
    } else {
      httpClient = getRealHttpClient();
    }
  }
  return httpClient;
}

export function getAuthProvider() {
  if (process.env.REACT_APP_MOCK_API === "true") {
    return mockAuthProvider;
  }
  return AuthProvider;
}

export function getDataProvider() {
  if (dataProvider === null) {
    if (process.env.REACT_APP_MOCK_API === "true") {
      dataProvider = mockDataProvider(getApiBase(), getHttpClient());
    } else {
      dataProvider = DataProvider(getApiBase(), getHttpClient());
    }
  }
  return dataProvider;
}

// 空白の場合にNullが送られるのを抑制する
export const preventSendNull = (value: any) => value;
