import { DateTime } from "luxon";

/**
 * 基準日の1日前の営業日を返す
 *
 * @param day 基準日（YYYY-MM-DD）
 * @param holidays 土日祝日（YYYY-MM-DD形式）の配列
 */
export function getPrevBusinessDay(day: string, holidays: string[]): string {
  let datetime = DateTime.fromISO(day).minus({ day: 1 });
  const oneYearAgo = datetime.minus({ year: 1 });

  while (oneYearAgo < datetime) {
    const isHoliday = holidays.includes(datetime.toFormat("yyyy-MM-dd"));
    if (!isHoliday) {
      return datetime.toFormat("yyyy-MM-dd");
    }
    datetime = datetime.minus({ day: 1 });
  }
  // 一年前まで遡って見つからなかったためエラー
  throw new Error("can not get business day.");
}
