import React from "react";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { DateTime } from "luxon";

type Props = {
  source: "producer_agreement_status";
  record?: {
    producer_agreement_status: string;
    producer_agreement_at: string;
  };
  sortable?: boolean;
  showTime?: boolean;
};

export const ProducerAgreementStatusField = (props: Props) => {
  const translate = useTranslate();

  const { record, showTime } = props;
  if (!record) {
    return null;
  }
  const { producer_agreement_at, producer_agreement_status } = record;

  const text = translate(`commons.agreement_statuses.${producer_agreement_status}`);

  if (showTime === true) {
    const time = DateTime.fromISO(producer_agreement_at);
    return (
      <Typography variant="body2">
        {text}
        {time.isValid && (
          <Typography variant="caption" display="inline" style={{ marginLeft: "1em" }}>
            {time.toFormat("yyyy/LL/dd HH:mm:ss")}
          </Typography>
        )}
      </Typography>
    );
  }

  return <Typography variant="body2">{text}</Typography>;
};

ProducerAgreementStatusField.defaultProps = {
  addLabel: true,
};
