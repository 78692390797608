import React from "react";
import { DashboardMenuItem, getResources } from "react-admin";

import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SettingsIcon from "@material-ui/icons/Settings";

import { MyMenuItem, SubMenu } from "./MyMenuItem";
import { TermsIcon } from "../svg/TermsIcon";
import { UsageIcon } from "../svg/UsageIcon";
import { STATIC_FILE_BASE_URL } from "../config/constConfig";

const CONTRACT_GUIDE_FILE_PATH = "/buyer/contract_guide.pdf";
const DELIVERY_GUIDE_FILE_PATH = "/buyer/delivery_guide.pdf";

const useStyles = makeStyles({
  openInNewIcon: {
    fontSize: "16px",
    marginTop: "6px",
    marginLeft: "4px",
  },
});

export const MyMenu = (props: any) => {
  const { onMenuClick, logout } = props;
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources: any = useSelector(getResources);

  const spotContractLists = ["spot_contract_offers", "spot_contract_others_offers", "spot_contract_deals"];
  const transactionLists = [
    "pending_tasks",
    "offers",
    "others_offers",
    "deals",
    "offer_requests",
    "producer_summaries",
  ];
  const customRouteLists = ["wholesaler_setting_mail_notification"];
  const borderTopName = "offer_requests";
  const borderBottomName = "producer_summaries";
  const transactions = resources.filter((r: any) => transactionLists.includes(r.name));
  const masters = resources.filter(
    (r: any) =>
      (!transactionLists.includes(r.name) && !spotContractLists.includes(r.name) && r.hasList) ||
      customRouteLists.includes(r.name)
  );

  return (
    <>
      <List component="div" disablePadding>
        <DashboardMenuItem />
        {transactions.map((res: any) => {
          return (
            <MyMenuItem
              key={res.name}
              onMenuClick={onMenuClick}
              resource={res}
              sidebarOpen={open}
              inSubMenu={false}
              isBorderBottom={res.name === borderBottomName}
              isBorderTop={res.name === borderTopName}
            />
          );
        })}
        <SubMenu
          primaryText={"設定"}
          resources={masters}
          sidebarOpen={open}
          onMenuClick={onMenuClick}
          icon={SettingsIcon}
        />
      </List>
      {isXSmall && logout}
      <Box ml={2} mt={4}>
        <Link href={STATIC_FILE_BASE_URL + CONTRACT_GUIDE_FILE_PATH} target="_blank">
          <Box display="flex" flexDirection="row">
            {!open ? (
              <UsageIcon />
            ) : (
              <>
                <Box mr={1}>
                  <UsageIcon />
                </Box>
                <Box>成約マニュアル</Box>
                <OpenInNewIcon className={classes.openInNewIcon} />
              </>
            )}
          </Box>
        </Link>
      </Box>
      <Box ml={2} mt={2}>
        <Link href={STATIC_FILE_BASE_URL + DELIVERY_GUIDE_FILE_PATH} target="_blank">
          <Box display="flex" flexDirection="row">
            {!open ? (
              <UsageIcon />
            ) : (
              <>
                <Box mr={1}>
                  <UsageIcon />
                </Box>
                <Box>受渡マニュアル</Box>
                <OpenInNewIcon className={classes.openInNewIcon} />
              </>
            )}
          </Box>
        </Link>
      </Box>
      <Box ml={2} mt={2}>
        <Link href="/terms/terms4b.pdf" target="_blank">
          <Box display="flex" flexDirection="row">
            {!open ? (
              <TermsIcon />
            ) : (
              <>
                <Box mr={1}>
                  <TermsIcon />
                </Box>
                <Box>利用規約</Box>
                <OpenInNewIcon className={classes.openInNewIcon} />
              </>
            )}
          </Box>
        </Link>
      </Box>
    </>
  );
};
