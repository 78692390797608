import { maxLength, required } from "react-admin";

export type Errors = { [key: string]: string | Errors | Array<Errors | null> };

// エラーがあるかどうかの確認
export function isEmpty(errors: Errors) {
  return Object.keys(errors).length === 0;
}

export const validateRequired = [required()];
export const validateRequiredMaxLengthText = (length: number) => [required(), maxLength(length)];
export const validateMaxLengthText = (length: number) => [maxLength(length)];

export const validateMobilePhoneText = (value?: string, allValues?: any, input?: any) => {
  if (allValues === undefined || input === undefined || value === undefined) {
    return null;
  }

  const slicedNumber = value.replaceAll("-", "");
  if (slicedNumber !== "" && !slicedNumber.match(/^0[789]0\d{4}\d{4}$/)) {
    return "携帯電話番号を入力してください";
  }

  return null;
};
