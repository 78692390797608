import { useEffect, useRef, useState } from "react";

import { getApiBase, getHttpClient } from "../utils/api";
import { DashboardCounts } from "../api/dashboard_counts";

type CountByContract = {
  unanswered: number | undefined;
  needToArrange: number | undefined;
  inspection: number | undefined;
  payment: number | undefined;
};

type CountState = {
  advanceContract: CountByContract;
  spotContract: CountByContract;
};

const initialCount = {
  advanceContract: {
    unanswered: undefined,
    needToArrange: undefined,
    inspection: undefined,
    payment: undefined,
  },
  spotContract: {
    unanswered: undefined,
    needToArrange: undefined,
    inspection: undefined,
    payment: undefined,
  },
};

const getTaskCounts = (data: DashboardCounts): CountState => {
  return {
    advanceContract: {
      unanswered: data.waiting_for_answer,
      needToArrange: data.waiting_for_delivery,
      inspection: data.waiting_for_after_inspection,
      payment: data.waiting_for_payment,
    },
    spotContract: {
      unanswered: data.spot_contract_waiting_for_answer,
      needToArrange: data.spot_contract_waiting_for_delivery,
      inspection: data.spot_contract_waiting_for_after_inspection,
      payment: data.spot_contract_waiting_for_payment,
    },
  };
};

export function useDashboardCounts(): [boolean, CountState] {
  const [loading, setLoading] = useState(false);
  const [countState, setCountState] = useState<CountState>(initialCount);
  const isCleanedUp = useRef(false);
  const httpClient = getHttpClient();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await httpClient(getApiBase() + "/dashboard_counts");
      if (!isCleanedUp.current) {
        const data: DashboardCounts = response.json;
        const count = getTaskCounts(data);
        setCountState(count);
        setLoading(false);
      }
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [httpClient]);

  return [loading, countState];
}
