import React from "react";

type TotalFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
};

export const TotalField = (props: TotalFieldProps) => {
  const { record, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const total: number = record[props.source];
  const totalSelfInspect: number = record["total_self_inspect"];

  return <span {...rest}>{`${total}(${totalSelfInspect})`}</span>;
};
