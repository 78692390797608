import React from "react";
import styled from "@emotion/styled";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const OfferIcon = ({ width = 24, height = 24, color = "#35363a" }: Props) => (
  <Svg width={width} height={height} color={color} viewBox="0 0 23 23">
    <g>
      <g>
        <rect className="cls-2" width="7.34" height="0.919" transform="translate(7.83 9.124)" />
        <rect className="cls-2" width="4.515" height="0.919" transform="translate(7.83 10.844)" />
        <g>
          <path
            className="cls-2"
            d="M42.041,22.41a4.142,4.142,0,0,0-.247-.836h0a4.268,4.268,0,0,0-.813-1.271l-.009-.009-.018-.018h0l-2.327-2.237L38.578,18l-3.935-3.791-.018-.018h0a4.232,4.232,0,0,0-6.141,0h0l-.018.018L24.535,17.99l-.04.036h0l-2.327,2.242-.013.013-.009.009-.009.009a4.245,4.245,0,0,0-.813,1.271h0a4.151,4.151,0,0,0-.247.84,4.277,4.277,0,0,0-.072.773v7.3a4.236,4.236,0,0,0,.705,2.34h0a4.267,4.267,0,0,0,.539.66h0a4.214,4.214,0,0,0,2.992,1.235H37.877a4.228,4.228,0,0,0,2.988-1.236h0a4.2,4.2,0,0,0,.539-.66h0a4.209,4.209,0,0,0,.71-2.34v-7.3a4.272,4.272,0,0,0-.072-.773m-3.684-2.188,1.33,1.28a2.457,2.457,0,0,1,.49.786l-1.819,1.056Zm-8.612-4.8a2.471,2.471,0,0,1,3.621,0l2.969,2.857H26.781Zm-4.034,3.881.072-.067H37.334l.067.063v4.6l-5.844,3.387L25.717,23.9Zm-2.291,2.2,1.334-1.28v3.122l-1.819-1.06a2.349,2.349,0,0,1,.49-.782m-.656,8.975V23.3l5.287,3.073-5.063,5.139a2.489,2.489,0,0,1-.225-1.033m15.107,2.471H25.236a2.448,2.448,0,0,1-1.671-.656l5.35-5.431L31.557,28.4,34.2,26.867l5.35,5.431a2.453,2.453,0,0,1-1.671.656m2.466-2.471a2.421,2.421,0,0,1-.229,1.038l-5.063-5.148L40.343,23.3Z"
            transform="translate(-20.057 -12.294)"
          />
        </g>
      </g>
    </g>
  </Svg>
);

const Svg = styled.svg`
  .cls-2 {
    fill: ${(props) => props.color};
  }
`;
