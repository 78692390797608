import * as React from "react";
import { AppBar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ToggleContractTypeButton from "./ToggleContractTypeButton";
import { GoogleAnalytics4 } from "./GoogleAnalytics4";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  toggle: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
  },
});

const MyAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <Box className={classes.toggle}>
        <ToggleContractTypeButton />
      </Box>
      <GoogleAnalytics4 />
    </AppBar>
  );
};

export default MyAppBar;
