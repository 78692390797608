import React, { useEffect, useState } from "react";
import { useDataProvider, useInput, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/styles";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import LinearProgress from "./LinerProgress";

import { PublicationPeriod } from "../pages/offers";
import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";

const useStyles = makeStyles({
  outer: {
    marginBottom: 20,
  },
  form: {
    width: 256,
  },
  view: {
    margin: "8px 0 4px",
    padding: "0 4px",
    width: "100%",
  },
  label: {
    position: "relative",
  },
  value: {
    padding: "8px 0 4px",
  },
});

const SelectItems = (props: {
  error: string;
  input: {
    onChange: (v: unknown) => void;
    value: string;
  };
  isRequired: boolean;
  items: PublicationPeriod[];
  label: string;
}) => {
  const { error, input, isRequired, items, label } = props;
  const classes = useStyles();

  const requiredLabel = isRequired ? " *" : "";
  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(ev.target.value);
  };
  return (
    <FormControl variant="filled" className={classes.form} margin="dense" error={!!error}>
      <InputLabel id="label">{`${label}${requiredLabel}`}</InputLabel>
      <Select labelId="label" id="select" value={input.value} onChange={handleChange}>
        {items.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

const RelationalValue = (props: { label: string; value: string }) => {
  const { label, value } = props;
  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.view} margin="dense">
        <InputLabel shrink={true} className={classes.label}>
          {label}
        </InputLabel>
        <div className={classes.value}>
          <Typography variant="body2">{value}</Typography>
        </div>
      </FormControl>
    </div>
  );
};

export const SpotContractOfferPublicationPeriodInput = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { input, isRequired, meta } = useInput(props);
  const { error, submitFailed } = meta;
  const [items, setItems] = useState<PublicationPeriod[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const params = {
        ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS,
        sort: {
          ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS.sort,
          field: "publish_start_date",
          order: "ASC",
        },
        filter: {
          ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS.filter,
          is_selectable: true,
          is_deleted: false,
        },
      };
      const results = await dataProvider.getList("spot_contract_offer_publication_periods", params);

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (results) {
          setItems(results.data);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider]);

  if (isLoading) {
    return (
      <div>
        <InputLabel shrink={true}>
          {translate(`resources.spot_contract_offers.fields.offer_publication_period_id`)}
        </InputLabel>
        <LinearProgress />
      </div>
    );
  }

  const selectedItem = items.find((item) => item.id === input.value);
  let startDate = null;
  let endDate = null;
  if (selectedItem) {
    startDate = selectedItem.publish_start_date.replace(/-/g, "/");
    endDate = selectedItem.publish_end_date.replace(/-/g, "/");
  }

  // @ts-ignore
  return (
    <div className={classes.outer}>
      <SelectItems
        input={input}
        isRequired={isRequired}
        items={items}
        label={translate(`resources.spot_contract_offers.fields.offer_publication_period_id`)}
        error={(!!input.value || submitFailed) && !!error && translate(error)}
      />
      {startDate && endDate && (
        <>
          <RelationalValue
            label={translate(`resources.spot_contract_offers.fields.publish_start_date`)}
            value={startDate}
          />
          <RelationalValue
            label={translate(`resources.spot_contract_offers.fields.publish_end_date`)}
            value={endDate}
          />
        </>
      )}
    </div>
  );
};
