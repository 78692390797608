import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  SelectInput,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  downloadCSV,
  useShowController,
  useTranslate,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import jsonExport from "jsonexport/dist";

import { PaymentShow } from "./payments";
import { AddressField } from "../components/AddressField";
import { Alert } from "../components/Alert";
import {
  createDeliveryStatusChoices,
  createDeliveryTypeChoices,
  createHasProducerReviewed,
  createInspectionResponsibilityChoices,
  createPaymentStatuses,
} from "../components/choices";
import CreateRelatedResourceButton from "../components/CreateRelatedResourceButton";
import { DealShowActions } from "../components/DealShowActions";
import { getExpectedHarvestTimeframeString, HarvestPlanField } from "../components/harvest_plan";
import { ExistsSpecialConditionsField } from "../components/exists_special_conditions_field";
import { ProducerAgreementStatusField } from "../components/producer_agreement_status_field";
import { WholesalerAgreementStatusField } from "../components/wholesaler_agreement_status";
import { useAmend } from "../hooks/useAmend";
import { DeleteDeliveryButton, EditDeliveryButton, InspectDeliveryButton } from "../components/AmendButton";
import { AttachmentFileField } from "../components/AttachmentFile";
import { formatStringAsCurrency, getDateString, getFormatNumeric, getFormatRateString } from "../utils/locale";
import { IndividualDeliveryShow } from "./individual_deliveries";
import { HandoverAtField } from "../components/HandoverAtField";
import { ReceiptTimingField } from "../components/ReceiptTimingField";
import { useUnassignedDeliveryContents } from "../hooks/useUnassignedDeliveryContents";
import { DealAmendField } from "../components/DealAmendField";
import { InvoiceRegistrationStatusField } from "../components/InvoiceRegistrationStatusField";
import { useCommonStyles } from "../components/common_styles";

const statuses = ["closed", "provisional", "not_closed", "done", "cancelled"];
const createStatusChoices = () =>
  statuses.map((status) => ({
    id: status,
    name: `resources.deals.fields.statuses.${status}`,
  }));

const agreementStatuses = ["waiting", "agreement", "disagreement"];
const createAgreementStatusChoices = () =>
  agreementStatuses.map((status) => ({
    id: status,
    name: `commons.agreement_statuses.${status}`,
  }));

const amendStatuses = ["waiting_for_delivery_creation", "waiting_for_after_delivery_inspection", "waiting_for_payment"];
const createAmendStatusChoices = () =>
  amendStatuses.map((status) => ({
    id: status,
    name: `commons.amend_statuses.${status}`,
  }));

const createDealTypesChoices = () =>
  [true, false].map((item) => ({
    id: item,
    name: `commons.deal_types.${item ? "without_offer" : "with_offer"}`,
  }));

const DealFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="offer_id" />
    <SelectInput source="status" choices={createStatusChoices()} />
    <DateInput source="handover_deadline_date" />
    <TextInput source="producer_company_name" />
    <TextInput source="annum_name" />
    <TextInput source="prefecture_name" />
    <TextInput source="region_name" />
    <TextInput source="brand_name" />
    <TextInput source="grade_name" />
    <SelectInput source="producer_agreement_status" choices={createAgreementStatusChoices()} />
    <SelectInput source="wholesaler_agreement_status" choices={createAgreementStatusChoices()} />
    <SelectInput source="without_offer" choices={createDealTypesChoices()} />
    <SelectInput source="amend_status" choices={createAmendStatusChoices()} label="ui.deals.amend_status" />
  </Filter>
);

function getOfferIdField() {
  return (record: any) => {
    const value = record["offer_id"];
    if (value === null) {
      return "なし";
    }
    return value;
  };
}

function getUnitPrice() {
  return (record: any) => {
    const amend = record["amend"];
    if (amend && amend["unit_price"] !== record["unit_price"]) {
      return formatStringAsCurrency(amend["unit_price"]);
    }
    return formatStringAsCurrency(record["unit_price"]);
  };
}

function getTradingVolumeInYen() {
  return (record: any) => {
    const amend = record["amend"];
    if (amend && amend["trading_volume_in_yen"] !== record["trading_volume_in_yen"]) {
      return formatStringAsCurrency(amend["trading_volume_in_yen"]);
    }
    return formatStringAsCurrency(record["trading_volume_in_yen"]);
  };
}

function getExceptionalUnitPriceAdjustmentInYen(amend: any) {
  return () => {
    if (amend) {
      return formatStringAsCurrency(amend["unit_price_adjustment_in_yen"]);
    } else {
      return null;
    }
  };
}

const export_headers = [
  "id",
  "offer_id",
  "status",
  "expected_harvest_timeframe",
  "amend.handover_deadline_date",
  "producer.company_name",
  "annum.name",
  "prefecture.name",
  "region.name",
  "brand.name",
  "grade.name",
  "packaging_form.name",
  "trading_units",
  "trading_sacks",
  "unit_price",
  "trading_volume_in_yen",
  "special_conditions",
  "special_conditions_memo",
  "producer_agreement_status",
  "wholesaler_agreement_status",
  "agreement_deadline",
  "decision_date",
  "storage_period.name",
  "storage_period.bonus_in_yen",
  "grade_penalties",
  "amend.handover_possible_date",
];

const exporter = (translate: any) => {
  const renamed_headers = export_headers.map((name) => translate(`resources.deals.fields.${name}`));

  return (deals: any) => {
    const dealsForExport = deals.map((deal: any) => {
      deal.region = deal.region ? deal.region : {};
      deal.amend = deal.amend ? deal.amend : {};

      const partial_deal = export_headers.reduce((result: any, prop) => {
        if (prop.includes(".")) {
          const [object, property] = prop.split(".");
          if (result[object] === undefined) {
            result[object] = {};
          }

          result[object][property] = deal[object][property];
        } else {
          result[prop] = deal[prop];
        }

        return result;
      }, {});

      partial_deal.offer_id = deal.offer_id ? deal.offer_id : "なし";
      partial_deal.status = translate(`resources.deals.fields.statuses.${deal.status}`);
      partial_deal.decision_date = getDateString(deal.decision_date);
      partial_deal.producer_agreement_status = translate(
        `commons.agreement_statuses.${deal.producer_agreement_status}`
      );
      partial_deal.wholesaler_agreement_status = translate(
        `commons.agreement_statuses.${deal.wholesaler_agreement_status}`
      );
      partial_deal.agreement_deadline = getDateString(deal.agreement_deadline);

      const gradePenalties =
        deal.amend.grade_penalties === undefined ? deal.grade_penalties : deal.amend.grade_penalties;
      partial_deal.grade_penalties =
        gradePenalties.length > 0
          ? gradePenalties
              .map(
                (item: any) =>
                  item.grade.name + "：" + (item.penalty_in_yen === null ? "受け取らない" : item.penalty_in_yen + "円")
              )
              .join("、")
          : "";
      partial_deal.special_conditions =
        deal.special_conditions.length > 0
          ? deal.special_conditions.map((item: any) => item.display_text).join("、")
          : "";

      const expected_harvest_timeframe =
        deal.amend.expected_harvest_timeframe === undefined
          ? deal.expected_harvest_timeframe
          : deal.amend.expected_harvest_timeframe;
      partial_deal.expected_harvest_timeframe = getExpectedHarvestTimeframeString(
        expected_harvest_timeframe,
        translate
      );
      partial_deal.amend.handover_possible_date = getDateString(deal.amend.handover_possible_date);
      partial_deal.amend.handover_deadline_date = getDateString(deal.amend.handover_deadline_date);

      // 契約後の変更があるのでアメンドからコピー
      if (deal.status === "closed" || deal.status === "done" || deal.status === "cancelled") {
        partial_deal.unit_price = deal.amend.unit_price;
        partial_deal.trading_volume_in_yen = deal.amend.trading_volume_in_yen;
        partial_deal.storage_period.name = deal.amend.storage_period.name;
        partial_deal.storage_period.bonus_in_yen = deal.amend.storage_period.bonus_in_yen;
      }

      return partial_deal;
    });

    jsonExport(
      dealsForExport,
      {
        headers: export_headers,
        rename: renamed_headers,
      },
      (err, csv) => {
        const buffer = new Buffer("\ufeff" + csv);
        downloadCSV(buffer, "事前契約_取引案件");
      }
    );
  };
};

const DEFAULT_COLUMNS = [
  "id",
  "status",
  "producer_agreement_status",
  "wholesaler_agreement_status",
  "agreement_deadline",
  "decision_date",
  "producer.company_name",
  "annum.name",
  "prefecture.name",
  "region.name",
  "brand.name",
  "grade.name",
  "packaging_form.name",
  "expected_harvest_timeframe",
  "amend.handover_deadline_date",
  "trading_units",
  "trading_sacks",
  "unit_price",
  "special_condition_ids",
];

export const DealList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      filters={<DealFilter />}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter(translate)}
    >
      <CustomizableDatagrid rowClick="show" defaultColumns={DEFAULT_COLUMNS}>
        <TextField source="id" />
        <FunctionField source="offer_id" render={getOfferIdField()} />
        <SelectField source="status" choices={createStatusChoices()} sortable={false} />
        <HarvestPlanField source="expected_harvest_timeframe" />
        <DateField source="amend.handover_deadline_date" sortable={true} sortBy="handover_deadline_date" />
        <TextField source="producer.company_name" sortable={false} />
        <TextField source="annum.name" sortable={false} />
        <TextField source="prefecture.name" sortable={false} />
        <TextField source="region.name" sortable={false} />
        <TextField source="brand.name" sortable={false} />
        <TextField source="grade.name" sortable={false} />
        <TextField source="packaging_form.name" sortable={false} />
        <NumberField source="trading_units" sortable={false} />
        <NumberField source="trading_sacks" sortable={false} />
        <FunctionField
          source="unit_price"
          render={getUnitPrice()}
          sortable={false}
          textAlign="right"
          displayName="NumberField"
        />
        <FunctionField
          source="trading_volume_in_yen"
          render={getTradingVolumeInYen()}
          sortable={false}
          textAlign="right"
          displayName="NumberField"
        />
        <ExistsSpecialConditionsField source="special_condition_ids" sortable={false} />
        <ProducerAgreementStatusField source="producer_agreement_status" sortable={false} />
        <WholesalerAgreementStatusField source="wholesaler_agreement_status" sortable={false} />
        <DateField source="agreement_deadline" locales={"ja"} />
        <DateField source="decision_date" locales={"ja"} />
      </CustomizableDatagrid>
    </List>
  );
};

export const DealShow = (props: any) => {
  const { permissions } = props;
  const { record } = useShowController(props);
  const isClosedOrDone = record?.status === "closed" || record?.status === "done";
  const isCancelled = record?.status === "cancelled";
  const isPrenegotiatedDeal = record?.offer_id === null;
  const hasAmendStatus = isClosedOrDone || isCancelled;
  const amendId = hasAmendStatus ? record.id : undefined;
  const [loadingAmend, amend] = useAmend(amendId);
  const [loadingUnassignedDeliveryContents, unassignedDeliveryContents] = useUnassignedDeliveryContents(amendId);
  const translate = useTranslate();
  const styles = useCommonStyles();
  if (!record || record.status === undefined || loadingAmend || loadingUnassignedDeliveryContents) {
    return null;
  }

  const isDeliveryCreationStep = amend?.current_step_buyer === "waiting_for_delivery_creation";
  const isDeliveryInspectionStep = amend?.current_step_buyer === "waiting_for_after_delivery_inspection";
  const hasWritePermission = permissions === "admin" || permissions === "staff";
  const hasUnassignedDeliveryContents = unassignedDeliveryContents?.some((e) => e.package_count > 0);
  const isExceptionFlow = amend?.is_exception_flow === true;
  const canUpdateDelivery =
    (isDeliveryCreationStep || isDeliveryInspectionStep) && hasWritePermission && !isExceptionFlow;
  const canCreateDelivery = canUpdateDelivery && hasUnassignedDeliveryContents;

  const isChangedStoragePeriod = hasAmendStatus && record.storage_period.id !== amend?.storage_period.id;
  const isChangedPrice = hasAmendStatus && record.unit_price !== amend?.unit_price;
  const canShowAmendAdjustmentInYen =
    hasAmendStatus &&
    record.unit_price_adjustment_in_yen != amend?.unit_price_adjustment_in_yen &&
    amend?.unit_price_adjustment_in_yen !== 0;

  return (
    <Show {...props} actions={<DealShowActions listPath="/deals" permissions={permissions} />}>
      <TabbedShowLayout>
        <Tab label="ui.deals.tabs.info">
          <TextField source="id" />
          <FunctionField source="offer_id" render={getOfferIdField()} />
          <SelectField source="status" choices={createStatusChoices()} />
          <DateField source="decision_date" locales={"ja"} />
          <ProducerAgreementStatusField source="producer_agreement_status" showTime={!isPrenegotiatedDeal} />
          <WholesalerAgreementStatusField source="wholesaler_agreement_status" showTime={!isPrenegotiatedDeal} />
          {!isPrenegotiatedDeal && <DateField source="agreement_deadline" locales={"ja"} />}
          <TextField source="producer.company_name" />
          <InvoiceRegistrationStatusField source="producer.invoice_registration_status" />
          <DateField source="created_at" locales={"ja"} showTime={true} />
        </Tab>
        <Tab path="contract" label="ui.deals.tabs.contract">
          <TextField source="annum.name" />
          <TextField source="prefecture.name" />
          <TextField source="region.name" />
          <TextField source="brand.name" />
          <TextField source="grade.name" />
          <TextField source="packaging_form.name" />
          <NumberField source="trading_units" />
          <NumberField source="trading_sacks" />
          <NumberField source="trading_kg" />
          <NumberField source="package_count" />
          {!isChangedPrice && <NumberField source="unit_price" />}
          {isChangedPrice && <DealAmendField amendSource="unit_price" source="unit_price" currencyFormat={true} />}
          {!isChangedPrice && <NumberField source="trading_volume_in_yen" />}
          {isChangedPrice && (
            <DealAmendField amendSource="trading_volume_in_yen" source="trading_volume_in_yen" currencyFormat={true} />
          )}
          <NumberField source="unit_price_adjustment_in_yen" />
          {/* NumberFieldにrecordオプションを渡しても表示されない不具合のため、FunctionFieldで実装 */}
          {canShowAmendAdjustmentInYen && (
            <FunctionField
              source="trading_volume_in_yen"
              label="ui.amends.exceptional_unit_price_adjustment_in_yen"
              render={getExceptionalUnitPriceAdjustmentInYen(amend)}
            />
          )}
          {!isChangedStoragePeriod && <TextField source="storage_period.name" />}
          {isChangedStoragePeriod && <DealAmendField amendSource="storage_period.name" source="storage_period.name" />}
          {!isChangedStoragePeriod && <NumberField source="storage_period.bonus_in_yen" />}
          {isChangedStoragePeriod && (
            <DealAmendField
              amendSource="storage_period.bonus_in_yen"
              source="storage_period.bonus_in_yen"
              currencyFormat={true}
            />
          )}
          {!hasAmendStatus && (
            <ArrayField source="grade_penalties">
              <Datagrid>
                <NumberField source="grade.name" />
                <NumberField source="penalty_in_yen" emptyText="受け取らない" />
              </Datagrid>
            </ArrayField>
          )}
          {hasAmendStatus && (
            <ReferenceField reference="amends" source="id" label="resources.amends.fields.grade_penalties" link={false}>
              <ArrayField source="grade_penalties">
                <Datagrid>
                  <NumberField source="grade.name" />
                  <NumberField source="penalty_in_yen" emptyText="受け取らない" />
                </Datagrid>
              </ArrayField>
            </ReferenceField>
          )}
          <ArrayField source="special_conditions">
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="special_conditions_memo" component="pre" />
          <HarvestPlanField source="expected_harvest_timeframe" fromDeal={true} />
          {!isPrenegotiatedDeal && (
            <SelectField source="inspection_responsibility" choices={createInspectionResponsibilityChoices()} />
          )}
        </Tab>
        {!isPrenegotiatedDeal && (
          <Tab path="offer" label="ui.deals.tabs.offer">
            <NumberField source="offer_unit_price" />
          </Tab>
        )}
        {isClosedOrDone && (
          <Tab path="delivery" label="ui.deals.tabs.delivery">
            {isExceptionFlow && (
              <Alert severity="warning">
                {translate("ui.deals.in_exception_flow")
                  .split("\n")
                  .map((line: string) => {
                    return <div key={line}>{line}</div>;
                  })}
              </Alert>
            )}
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.expected_harvest_timeframe"
              link={false}
            >
              <HarvestPlanField source="expected_harvest_timeframe" />
            </ReferenceField>
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.handover_possible_date"
              link={false}
            >
              <DateField source="handover_possible_date" emptyText={translate("ui.value.not_set")} />
            </ReferenceField>
            <ReferenceField reference="amends" source="id" label="resources.amends.fields.storage_period" link={false}>
              <TextField source="storage_period.name" />
            </ReferenceField>
            <DateField source="amend.handover_deadline_date" />
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.handover_address"
              link={false}
            >
              <AddressField source="handover_address" />
            </ReferenceField>
            <ReferenceField reference="amends" source="id" label="resources.amends.fields.handover_memo" link={false}>
              <TextField source="handover_memo" component="pre" />
            </ReferenceField>
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.inspection_results"
              link={false}
            >
              <ArrayField source="inspection_results">
                <Datagrid>
                  <TextField source="grade.name" emptyText={translate("ui.value.default_grade")} />
                  <NumberField source="package_count" />
                </Datagrid>
              </ArrayField>
            </ReferenceField>
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.inspection_results_memo"
              link={false}
            >
              <TextField source="inspection_results_memo" component="pre" />
            </ReferenceField>
            <ReferenceField
              reference="amends"
              source="id"
              label="resources.amends.fields.inspection_results_attachments"
              link={false}
            >
              <ArrayField source="inspection_results_attachments">
                <SingleFieldList linkType={false}>
                  <AttachmentFileField
                    styles={{
                      width: "100%",
                      my: 1,
                    }}
                  />
                </SingleFieldList>
              </ArrayField>
            </ReferenceField>
            <h3>個別運送情報</h3>
            <ReferenceManyField
              reference="individual_deliveries"
              target="amend_id"
              label={false}
              pagination={<Pagination />}
            >
              <Datagrid expand={<IndividualDeliveryShow />} className={styles.showPageInnerList} rowClick="expand">
                <TextField source="id" label={"resources.individual_deliveries.fields.id"} />
                <SelectField
                  source="delivery_type"
                  choices={createDeliveryTypeChoices()}
                  label="resources.individual_deliveries.fields.delivery_type"
                  sortable={false}
                />
                <SelectField
                  source="status"
                  choices={createDeliveryStatusChoices()}
                  label="resources.individual_deliveries.fields.status"
                  sortable={false}
                />
                <SelectField
                  source="has_producer_reviewed"
                  choices={createHasProducerReviewed()}
                  label="resources.individual_deliveries.fields.has_producer_reviewed"
                  sortable={false}
                />
                <ReferenceField
                  source="delivery_address.id"
                  label="resources.individual_deliveries.fields.delivery_address.id"
                  reference="wholesaler_addresses"
                  link={false}
                  sortable={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <HandoverAtField label="resources.individual_deliveries.fields.hacobell.handover_at" sortable={false} />
                <ReceiptTimingField label="resources.individual_deliveries.fields.receipt_timing" />
                {canUpdateDelivery && <EditDeliveryButton />}
                {canUpdateDelivery && <DeleteDeliveryButton />}
                {canUpdateDelivery && <InspectDeliveryButton />}
              </Datagrid>
            </ReferenceManyField>
            {canCreateDelivery && (
              <ReferenceField reference="amends" source="id" label="" link={false}>
                <CreateRelatedResourceButton
                  reference="individual_deliveries"
                  target="amend_id"
                  hasVersion={true}
                  permissions={permissions}
                />
              </ReferenceField>
            )}
          </Tab>
        )}
        {isClosedOrDone && (
          <Tab path="payment" label="ui.deals.tabs.payments">
            <TextField source="annum.name" />
            <TextField source="prefecture.name" />
            <TextField source="brand.name" />
            <ReferenceField reference="amends" source="id" label="resources.amends.fields.producer" link={false}>
              <TextField source="producer.company_name" />
            </ReferenceField>
            <InvoiceRegistrationStatusField source="producer.invoice_registration_status" />
            <ReferenceField reference="amends" source="id" label="resources.amends.fields.commission_rate" link={false}>
              <FunctionField
                source="commission_rate"
                render={getFormatRateString("commission_rate")}
                sortable={false}
              />
            </ReferenceField>
            <ReferenceManyField
              reference="payments"
              target="amend_id"
              label="resources.payments.name"
              pagination={<Pagination />}
            >
              <Datagrid expand={<PaymentShow resource="payments" permissions={permissions} />} rowClick="expand">
                <TextField source="id" label={"resources.payments.fields.id"} />
                <SelectField source="status" choices={createPaymentStatuses()} sortable={false} />
                <ReferenceField
                  source="individual_delivery_id"
                  reference="individual_deliveries"
                  label="resources.individual_deliveries.fields.handover_at"
                  link={false}
                  sortable={false}
                >
                  <HandoverAtField
                    label="resources.individual_deliveries.fields.hacobell.handover_at"
                    sortable={false}
                  />
                </ReferenceField>
                <ReferenceField
                  source="individual_delivery_id"
                  reference="individual_deliveries"
                  label="resources.individual_deliveries.fields.delivery_address"
                  link={false}
                  sortable={false}
                >
                  <ReferenceField
                    source="delivery_address_id"
                    reference="wholesaler_addresses"
                    link={false}
                    sortable={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <FunctionField
                  source="total_bill_in_yen"
                  render={getFormatNumeric("total_bill_in_yen")}
                  sortable={false}
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
