import { DateTime } from "luxon";

import { Errors, isEmpty } from "./validator";
import { IndividualDeliveryWriteBody } from "../api/individual_deliveries";
import { NullReplacedUnassignedDeliveryContent, UnassignedDeliveryContent } from "../api/unassigned_delivery_contents";

export const replaceNullGradeId = (
  unassignedDeliveryContents: UnassignedDeliveryContent[],
  gradeId: number
): NullReplacedUnassignedDeliveryContent[] => {
  return unassignedDeliveryContents.map((udc) => {
    if (udc.grade_id === null) {
      return {
        grade_id: gradeId,
        package_count: udc.package_count,
      };
    }
    // HACK: 単純にudcをreturnすると型チェックに引っかかるので一度展開して返す
    return {
      grade_id: udc.grade_id,
      package_count: udc.package_count,
    };
  });
};

function greaterThanPossibleDate(date: string, possibleDate: string) {
  const dateTime = DateTime.fromISO(date);
  const handoverPossibleDateTime = DateTime.fromISO(possibleDate);
  if (!handoverPossibleDateTime.isValid) {
    // 引渡可能日は必ず設定されているのでvalidでない場合はエラー
    throw new Error("handover possible date is empty");
  }
  return handoverPossibleDateTime <= dateTime;
}

export function getValidateDateIsAfterHandoverAt(handover_possible_date: string) {
  return (value: string | undefined | null) => {
    if (value === undefined || value === null || value === "") {
      return null;
    }

    if (!greaterThanPossibleDate(value, handover_possible_date)) {
      return "validation.individual_deliveries.can_not_set_before_possible_date";
    }

    return null;
  };
}

function validateHacobell(values: IndividualDeliveryWriteBody): Errors {
  const errors: Errors = {};

  const { hacobell } = values;
  if (hacobell === null || hacobell === undefined) {
    // 何もフォームをさわらなかった場合
    errors["arrival_preferred_from"] = "ra.validation.required";
    errors["arrival_preferred_to"] = "ra.validation.required";
    errors["preferred_vehicle"] = "ra.validation.required";
    return errors;
  }

  const { arrival_preferred_from, arrival_preferred_to, preferred_vehicle } = hacobell;

  if (arrival_preferred_from && arrival_preferred_to) {
    const fromDateTime = DateTime.fromISO(arrival_preferred_from);
    const toDateTime = DateTime.fromISO(arrival_preferred_to);
    if (toDateTime < fromDateTime) {
      errors["arrival_preferred_from"] = "validation.individual_deliveries.can_not_set_after_to_datetime";
      errors["arrival_preferred_to"] = "validation.individual_deliveries.can_not_set_before_from_datetime";
    }
  } else {
    if (!arrival_preferred_from) {
      errors["arrival_preferred_from"] = "ra.validation.required";
    }
    if (!arrival_preferred_to) {
      errors["arrival_preferred_to"] = "ra.validation.required";
    }
  }

  if (!preferred_vehicle) {
    errors["preferred_vehicle"] = "ra.validation.required";
  }

  return errors;
}

function validateSelfDelivery(values: IndividualDeliveryWriteBody): Errors {
  const errors: Errors = {};

  const { self_delivery } = values;
  if (self_delivery === null || self_delivery === undefined) {
    // 何もフォームをさわらなかった場合
    errors["handover_date"] = "ra.validation.required";
    return errors;
  }

  const { handover_date } = self_delivery;

  // 必須以外のチェックは個別のフォーム項目自体のvalidationでやる
  if (!handover_date) {
    errors["handover_date"] = "ra.validation.required";
  }

  return errors;
}

type RowError = { [key: string]: string };

function validateDeliveryContents(
  values: IndividualDeliveryWriteBody,
  unassignedDeliveryContents: UnassignedDeliveryContent[],
  gradeId: number,
  dealAmount: number
): null | string | Array<null | RowError> {
  const { delivery_contents } = values;
  if (delivery_contents === undefined || delivery_contents === null || delivery_contents.length === 0) {
    return "ra.validation.required";
  } else {
    // HACK undefinedをフィルタリングする
    // フォーム追加直後はundefinedとなり後続の処理が失敗する
    // 数量のバリデーションに必ず引っかかるため、ここでのバリデーションで無視して問題ない
    const filteredDeliveryContents = delivery_contents.filter((dc) => !!dc);
    const allGrades = filteredDeliveryContents.map((dc) => dc.grade_id);

    let isOverDealAmount = false;
    const totalCount = filteredDeliveryContents.reduce((count, dc) => {
      return count + dc.package_count;
    }, 0);
    if (dealAmount < totalCount) {
      isOverDealAmount = true;
    }

    const deliveryContentErrors: Array<RowError | null> = [];
    filteredDeliveryContents.forEach((dc) => {
      const rowErrors: RowError = {};
      const gradeCounts = allGrades.filter((ag) => ag === dc.grade_id).length;
      if (gradeCounts > 1) {
        rowErrors.grade_id = "validation.individual_deliveries.grade_is_duplicated";
      }
      const gradeIdReplacedDeliveryContents = replaceNullGradeId(unassignedDeliveryContents, gradeId);
      const unassignedCount = gradeIdReplacedDeliveryContents.find((uc) => uc.grade_id === dc?.grade_id);
      if (!unassignedCount) {
        // 通常は起きないはず
        rowErrors.package_count = "validation.individual_deliveries.can_not_load_package_count";
      } else if (unassignedCount.package_count < dc.package_count) {
        rowErrors.package_count = "validation.individual_deliveries.over_package_count";
      } else if (isOverDealAmount) {
        rowErrors.package_count = "validation.individual_deliveries.over_deal_amount";
      }
      if (isEmpty(rowErrors)) {
        deliveryContentErrors.push(null);
      } else {
        deliveryContentErrors.push(rowErrors);
      }
    });

    if (deliveryContentErrors.filter((dce) => dce !== null).length !== 0) {
      return deliveryContentErrors;
    }

    return null;
  }
}

export function getValidateIndividualDelivery(
  unassignedDeliveryContents: UnassignedDeliveryContent[],
  gradeId: number,
  dealAmount: number
) {
  return (values: IndividualDeliveryWriteBody) => {
    let errors: Errors = {};

    const { delivery_address_id, delivery_contents, delivery_type, status } = values;

    if (status === undefined) {
      return null;
    }

    // 下書きの場合
    if (status === "draft") {
      if (delivery_contents !== undefined && delivery_contents !== null && delivery_contents.length !== 0) {
        const filteredDeliveryContents = delivery_contents.filter((dc) => !!dc);
        errors["delivery_contents"] = filteredDeliveryContents.map(() => {
          return {
            grade_id: "validation.individual_deliveries.draft_can_not_set_delivery_contents",
            package_count: "validation.individual_deliveries.draft_can_not_set_delivery_contents",
          };
        });
      }
      if (isEmpty(errors)) {
        return null;
      }
      return errors;
    }

    // それ以外の場合
    if (delivery_address_id === undefined || delivery_address_id === null) {
      errors["delivery_address_id"] = "ra.validation.required";
    }

    const deliveryContentsErrors = validateDeliveryContents(values, unassignedDeliveryContents, gradeId, dealAmount);
    if (deliveryContentsErrors !== null) {
      errors = {
        ...errors,
        delivery_contents: deliveryContentsErrors,
      };
    }

    switch (delivery_type) {
      // ハコべルの場合
      case "hacobell": {
        const hacobellErrors = validateHacobell(values);
        if (!isEmpty(hacobellErrors)) {
          errors = {
            ...errors,
            hacobell: hacobellErrors,
          };
        }
        break;
      }
      // 自社運送の場合
      case "self_delivery": {
        const selfDeliveryErrors = validateSelfDelivery(values);
        if (!isEmpty(selfDeliveryErrors)) {
          errors = {
            ...errors,
            self_delivery: selfDeliveryErrors,
          };
        }
        break;
      }
      default: {
        break;
      }
    }
    if (isEmpty(errors)) {
      return null;
    }
    return errors;
  };
}
