import { DateTimeString } from "./common";

export const LOG_KEYS_OF_ADVANCE_CONTRACT = {
  LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY: "LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY",
  LOG_BUYER_DISAGREE_DEAL: "LOG_BUYER_DISAGREE_DEAL",
  LOG_BUYER_REPORT_PRODUCTS_DELIVERED: "LOG_BUYER_REPORT_PRODUCTS_DELIVERED",
  LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  LOG_CHANGE_BUYER_INFO: "LOG_CHANGE_BUYER_INFO",
  LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL: "LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL",
  LOG_CREATE_TASK_BUYER_AGREE_DEAL: "LOG_CREATE_TASK_BUYER_AGREE_DEAL",
  LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS: "LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS",
  LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT: "LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT",
  LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION: "LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION",
  LOG_MANAGER_CANCEL_DEAL: "LOG_MANAGER_CANCEL_DEAL",
  LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY: "LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  LOG_REMIND_BUYER_CHECK_DELIVERY_INFO: "LOG_REMIND_BUYER_CHECK_DELIVERY_INFO",
  LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL: "LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL",
  LOG_REMIND_TASK_BUYER_AGREE_DEAL: "LOG_REMIND_TASK_BUYER_AGREE_DEAL",
  LOG_SELLER_AND_BUYER_AGREE_DEAL: "LOG_SELLER_AND_BUYER_AGREE_DEAL",
  LOG_SELLER_DISAGREE_BID_DEAL: "LOG_SELLER_DISAGREE_BID_DEAL",
  LOG_SELLER_DISAGREE_DEAL: "LOG_SELLER_DISAGREE_DEAL",
  LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY: "LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY",
  LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND: "LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND",
  LOG_TOMORROW_IS_HANDOVER_DATE: "LOG_TOMORROW_IS_HANDOVER_DATE",
  LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW: "LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW",
  LOG_MANAGER_CREATE_PRENEGOTIATED_DEAL: "LOG_MANAGER_CREATE_PRENEGOTIATED_DEAL",
  LOG_UPDATE_STORAGE_PERIOD_FOR_EXCEPTION_FLOW: "LOG_UPDATE_STORAGE_PERIOD_FOR_EXCEPTION_FLOW",
} as const;
export type LogKeyOfAdvanceContract = typeof LOG_KEYS_OF_ADVANCE_CONTRACT[keyof typeof LOG_KEYS_OF_ADVANCE_CONTRACT];

export const LOG_KEYS_OF_SPOT_CONTRACT = {
  LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY: "SPOT_CONTRACT_LOG_BUYER_DELETE_HACOBELL_INDIVIDUAL_DELIVERY",
  LOG_BUYER_DISAGREE_DEAL: "SPOT_CONTRACT_LOG_BUYER_DISAGREE_DEAL",
  LOG_BUYER_REPORT_PRODUCTS_DELIVERED: "SPOT_CONTRACT_LOG_BUYER_REPORT_PRODUCTS_DELIVERED",
  LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION: "SPOT_CONTRACT_LOG_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  LOG_CHANGE_BUYER_INFO: "SPOT_CONTRACT_LOG_CHANGE_BUYER_INFO",
  LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL: "SPOT_CONTRACT_LOG_CREATE_TASK_BUYER_AGREE_BID_DEAL",
  LOG_CREATE_TASK_BUYER_AGREE_DEAL: "SPOT_CONTRACT_LOG_CREATE_TASK_BUYER_AGREE_DEAL",
  LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    "SPOT_CONTRACT_LOG_CREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS: "SPOT_CONTRACT_LOG_CREATE_TASK_BUYER_PAYS_AND_REPORTS",
  LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION:
    "SPOT_CONTRACT_LOG_CREATE_TASK_BUYER_UPDATE_AFTER_DELIVERY_INSPECTION",
  LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT: "SPOT_CONTRACT_LOG_DONE_SELLER_UPDATE_AMEND_INSPECTION_RESULT",
  LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION:
    "SPOT_CONTRACT_LOG_INDIVIDUAL_DELIVERY_MOVES_TO_WAITING_FOR_INSPECTION",
  LOG_MANAGER_CANCEL_DEAL: "SPOT_CONTRACT_LOG_MANAGER_CANCEL_DEAL",
  LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY:
    "SPOT_CONTRACT_LOG_RECREATE_TASK_BUYER_CREATE_AMEND_INDIVIDUAL_DELIVERY",
  LOG_REMIND_BUYER_CHECK_DELIVERY_INFO: "SPOT_CONTRACT_LOG_REMIND_BUYER_CHECK_DELIVERY_INFO",
  LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL: "SPOT_CONTRACT_LOG_REMIND_TASK_BUYER_AGREE_BID_DEAL",
  LOG_REMIND_TASK_BUYER_AGREE_DEAL: "SPOT_CONTRACT_LOG_REMIND_TASK_BUYER_AGREE_DEAL",
  LOG_SELLER_AND_BUYER_AGREE_DEAL: "SPOT_CONTRACT_LOG_SELLER_AND_BUYER_AGREE_DEAL",
  LOG_SELLER_DISAGREE_BID_DEAL: "SPOT_CONTRACT_LOG_SELLER_DISAGREE_BID_DEAL",
  LOG_SELLER_DISAGREE_DEAL: "SPOT_CONTRACT_LOG_SELLER_DISAGREE_DEAL",
  LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY: "SPOT_CONTRACT_LOG_SELLER_REVIEWED_INDIVIDUAL_DELIVERY",
  LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND: "SPOT_CONTRACT_LOG_TASK_BUYER_AGREE_BID_DEALS_REMIND",
  LOG_TOMORROW_IS_HANDOVER_DATE: "SPOT_CONTRACT_LOG_TOMORROW_IS_HANDOVER_DATE",
  LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW: "SPOT_CONTRACT_LOG_UPDATE_INDIVIDUAL_DELIVERY_FOR_EXCEPTION_FLOW",
} as const;
export type LogKeyOfSpotContract = typeof LOG_KEYS_OF_SPOT_CONTRACT[keyof typeof LOG_KEYS_OF_SPOT_CONTRACT];

export type TransactionLog = {
  id: number;
  log_key: LogKeyOfAdvanceContract | LogKeyOfSpotContract;
  log_text: string;
  offer_id: number;
  deal_id: number;
  amend_id: number;
  delivery_id: number;
  spot_contract_deal_id: number;
  spot_contract_amend_id: number;
  created_at: DateTimeString;
};
