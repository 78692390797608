import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grey from "@material-ui/core/colors/grey";
import { formatStringAsCurrency } from "../utils/locale";

type Props = {
  amendSource: string;
  source: string;
  record?: any;
  label?: string;
  sortable?: boolean;
  currencyFormat?: boolean;
};

const useStyles = makeStyles({
  typography: {
    textDecoration: "line-through",
    color: Grey["600"],
  },
});

export const DealAmendField = (props: Props) => {
  const { record, amendSource, source, currencyFormat } = props;
  const classes = useStyles();

  if (!record) {
    return null;
  }

  const dealData = get(record, source);
  const amendData = get(record.amend, amendSource);
  if (dealData === undefined || amendData === undefined) {
    return null;
  }
  const formattedDealData = currencyFormat ? formatStringAsCurrency(dealData) : dealData;
  const formattedAmendData = currencyFormat ? formatStringAsCurrency(amendData) : amendData;

  return (
    <>
      <Typography key={2} variant="body2">
        {formattedAmendData}（最終）
      </Typography>
      <Typography key={1} variant="body2" className={classes.typography}>
        {formattedDealData}（契約時）
      </Typography>
    </>
  );
};

DealAmendField.defaultProps = {
  addLabel: true,
};
