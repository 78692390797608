import { ImageProcessingError } from "./error";

export function isImage(file: File): boolean {
  return file.type.startsWith("image/");
}

async function drawImage(canvas: HTMLCanvasElement, image: HTMLImageElement) {
  const originalWidth = image.naturalWidth;
  const originalHeight = image.naturalHeight;
  canvas.width = originalWidth;
  canvas.height = originalHeight;

  const context = canvas.getContext("2d");
  if (context === null) {
    throw new ImageProcessingError("context not found.");
  }
  context.drawImage(image, 0, 0);
}

export function removeExif(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const body = document.getElementsByTagName("body");
    if (body.length !== 1) {
      reject(new ImageProcessingError("invalid body element."));
    }

    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    body[0].appendChild(canvas);

    const image = new Image();
    const url = URL.createObjectURL(blob);
    image.onload = async function () {
      await drawImage(canvas, image);
      const onConverted = (blob: Blob | null) => {
        if (blob === null) {
          return reject(new ImageProcessingError("can not create blob from canvas."));
        }
        // Blobに変換して不要になったので開放する
        URL.revokeObjectURL(url);
        return resolve(blob);
      };
      if (blob.type === "image/jpeg") {
        canvas.toBlob(onConverted, blob.type, 1);
      } else {
        // デフォルトはpng
        canvas.toBlob(onConverted);
      }
    };
    image.onerror = function (error) {
      reject(new ImageProcessingError(error));
    };
    image.src = url;
  });
}
