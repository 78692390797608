import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { useField, useFormState } from "react-final-form";
import { HttpError } from "ra-core";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import { useFileUpload } from "../hooks/useFileUpload";
import { ACCEPTS, formatFileSize } from "../utils/file";
import { getApiBase } from "../utils/api";
import { FileNameExtensionError, FileSizeError, ImageProcessingError } from "../utils/error";

const useStyles = makeStyles({
  hidden: {
    display: "none",
  },
  link: {
    textDecorationSkipInk: "none",
  },
  tooltip: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltipText: {
    fontSize: 14,
    lineHeight: 1.3,
  },
});

function inArray(basePath: string | undefined) {
  return basePath === undefined;
}

function getItem(values: any, source: string) {
  const re = /(\w+)\[(\d+)\]/;
  const result = source.match(re);
  if (result === null) {
    throw new Error("invalid source name");
  }
  const property = result[1];
  const index = parseInt(result[2]);
  const items = values[property];
  return items[index];
}

const formatFileName = (file_name: string, file_size: number): string => {
  const [size, unit] = formatFileSize(file_size);
  return `${file_name} (${size}${unit})`;
};

const FileLinkText = (props: { href: string; text: string }) => {
  const { href, text } = props;
  const classes = useStyles();
  return (
    <Link href={href} target="_blank" variant="body2" className={classes.link}>
      {text}
    </Link>
  );
};

const UploadFileButton = (props: { onFileSelected: (file: File) => void }) => {
  const { onFileSelected } = props;
  const classes = useStyles();

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (!files || files.length === 0) {
      window.alert("ファイルを選択してください");
      return null;
    } else if (files.length > 1) {
      window.alert("ファイルは複数同時に選択できません");
      return null;
    }
    const file = files[0];
    onFileSelected(file);
  };

  return (
    <Box display="flex">
      <Box>
        <label>
          <input type="file" accept={ACCEPTS} className={classes.hidden} onChange={onChange} />
          <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
            ファイル選択
          </Button>
        </label>
      </Box>
      <Box ml={2} py={"6px"}>
        <Tooltip
          className={classes.tooltip}
          title={
            <div className={classes.tooltipText}>
              <p>画像（.jpg, .jpeg, .png）、PDF（.pdf）、Zipファイル（.zip）がアップロード可能です。</p>
              <p>アップロードできるファイルサイズの上限は100MBまでです。</p>
            </div>
          }
        >
          <HelpOutlineIcon />
        </Tooltip>
      </Box>
    </Box>
  );
};

const UploadedFileLink = (props: { href: string; fileName: string; fileSize: number }) => {
  const { fileName, fileSize, href } = props;
  const text = formatFileName(fileName, fileSize);
  return <FileLinkText href={href} text={text} />;
};

export const AttachmentFileField = (props: {
  record?: any;
  source?: string;
  addLabel?: boolean;
  styles?: { [key: string]: any };
}) => {
  const { record, source, styles } = props;
  if (!record) {
    return null;
  }
  const keys = Object.keys(record);
  const inArray = ["uuid", "file_name", "file_size_in_bytes"].every((key) => keys.includes(key));

  let uuid: string, file_name: string, file_size_in_bytes: number;
  if (inArray) {
    uuid = record.uuid;
    file_name = record.file_name;
    file_size_in_bytes = record.file_size_in_bytes;
  } else {
    if (!source || !record[source]) {
      return null;
    }
    uuid = record[source].uuid;
    file_name = record[source].file_name;
    file_size_in_bytes = record[source].file_size_in_bytes;
  }

  const apiUrl = getApiBase() + `/attachments?uuid=${uuid}`;

  const styleProps: any = styles || {
    my: 2,
  };

  return (
    <Box {...styleProps}>
      <UploadedFileLink href={apiUrl} fileName={file_name} fileSize={file_size_in_bytes} />
    </Box>
  );
};
AttachmentFileField.defaultProps = {
  addLabel: true,
};

const createErrorMessage = (error: Error, translate: (key: string) => string) => {
  if (error instanceof FileSizeError) {
    return translate("ui.error.file_size_is_too_big");
  } else if (error instanceof FileNameExtensionError) {
    return translate("ui.error.filename_extension_is_not_allowed");
  } else if (error instanceof ImageProcessingError) {
    return translate("ui.error.image_processing_is_failed");
  } else if (error instanceof HttpError) {
    return translate("ui.error.upload_is_failed");
  }
  return translate("ui.error.unknown_error");
};

export const AttachmentFileInput = (props: {
  basePath?: string | undefined;
  record?: any;
  source?: string;
  addLabel?: boolean;
}) => {
  const { basePath, source } = props;
  // HACK! propsにsourceを渡していないケースでは、react-adminが暗黙的にsourceを設定している。
  // そのため、コンポーネント内部では必ずsourceに値がある状態になる。
  const _source = source as string;

  const [file, setFile] = useState<File | null>(null);
  const [uploading, upload, error] = useFileUpload();
  const translate = useTranslate();
  const { values } = useFormState();

  let target;
  if (inArray(basePath)) {
    target = getItem(values, _source);
  } else {
    target = values[_source];
  }
  const uuid = target?.uuid;
  const file_name = target?.file_name;
  const file_size_in_bytes = target?.file_size_in_bytes;

  const {
    input: { onChange: onRemove },
  } = useField(_source);
  const {
    input: { onChange: onChangeUuid },
  } = useField(source + ".uuid");
  const {
    input: { onChange: onChangeFileName },
  } = useField(source + ".file_name");
  const {
    input: { onChange: onChangeFileSize },
  } = useField(source + ".file_size_in_bytes");

  const onClickRemove = () => {
    onRemove(null);
    setFile(null);
  };

  // アップロード済みのファイルがある場合
  if (uuid) {
    const apiUrl = getApiBase() + `/attachments?uuid=${uuid}`;
    return (
      <Box my={2} display="flex" alignItems="center" flexDirection>
        <Box flexGrow={9999}>
          <UploadedFileLink href={apiUrl} fileName={file_name} fileSize={file_size_in_bytes} />
        </Box>
        {!inArray(basePath) && (
          <Box>
            <Button onClick={onClickRemove}>
              <RemoveCircleOutlineIcon />
              <Box ml={1} display="inline">
                {translate("ra.action.delete")}
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  if (uploading) {
    return (
      <Box my={2}>
        <Box width={200} height={12} pt={1}>
          <LinearProgress />
        </Box>
      </Box>
    );
  }

  if (file !== null) {
    const blob = new Blob([file.slice(0)], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    return (
      <Box my={2}>
        <UploadedFileLink href={blobUrl} fileName={file.name} fileSize={file.size} />
      </Box>
    );
  }

  const onFileSelected = async (file: File) => {
    const newUuid = await upload(file);
    if (newUuid !== null) {
      onChangeUuid(newUuid);
      onChangeFileName(file.name);
      onChangeFileSize(file.size);
      setFile(file);
    }
  };

  return (
    <Box my={2}>
      <UploadFileButton onFileSelected={onFileSelected} />
      {error && (
        <Box mt={1}>
          <Typography color="error" variant="caption">
            {createErrorMessage(error, translate)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
AttachmentFileInput.defaultProps = {
  addLabel: true,
  fullWidth: true,
};
