import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";

type Props = {
  source: string;
  record?: any;
  label?: string;
  sortable?: boolean;
};

export const MemoField = (props: Props) => {
  const { record, source } = props;
  if (!record) {
    return null;
  }
  const data = get(record, source);
  if (!data || typeof data !== "string") {
    return null;
  }
  const lines = data.split("\n");
  return (
    <>
      {lines.map((line, index) => {
        if (!line) {
          return (
            <Typography key={index} variant="body2">
              &nbsp;
            </Typography>
          );
        }
        return (
          <Typography key={index} variant="body2">
            {line}
          </Typography>
        );
      })}
    </>
  );
};

MemoField.defaultProps = {
  addLabel: true,
};
