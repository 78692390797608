import React from "react";
import { DeleteButton, EditButton, useRedirect, useTranslate } from "react-admin";

import CancelIcon from "@material-ui/icons/Cancel";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import { IndividualDelivery } from "../api/individual_deliveries";

export const EditDeliveryButton = (props: { record?: IndividualDelivery }) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  const { status } = record;
  switch (status) {
    case "draft":
      return <EditButton {...props} resource={"spot_contract_individual_deliveries"} />;
    case "waiting_for_inspection": {
      if (record.delivery_type === "self_delivery") {
        return <EditButton {...props} resource={"spot_contract_individual_deliveries"} />;
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

const hacobellUncancellableStatuses = [
  "shipment_delivered",
  "shipment_completed",
  "canceled",
  "rejected",
  "closed",
  "accepted",
  "unknown",
];

export const DeleteDeliveryButton = (props: { record?: IndividualDelivery }) => {
  const { record } = props;
  const redirect = useRedirect();
  const translate = useTranslate();
  if (!record) {
    return null;
  }

  const redirectTo = () => {
    redirect(`/spot_contract_deals/${record.amend_id}/show/delivery`);
  };

  const { delivery_type, hacobell, status } = record;
  const hacobellStatus = hacobell ? hacobell.status : "unknown";

  switch (status) {
    case "draft": {
      return (
        <DeleteButton
          {...props}
          resource={"spot_contract_individual_deliveries"}
          undoable={false}
          redirect={redirectTo}
          confirmTitle={translate("ra.message.delete_title", {
            name: translate("ui.individual_deliveries.draft_delivery"),
          })}
          confirmContent={"ra.message.delete_content"}
        />
      );
    }
    case "waiting_for_arrangement": {
      if (delivery_type === "hacobell" && hacobellUncancellableStatuses.includes(hacobellStatus)) {
        return null;
      }
      return (
        <DeleteButton
          {...props}
          resource={"spot_contract_individual_deliveries"}
          undoable={false}
          redirect={redirectTo}
          label="ra.action.cancel"
          icon={<CancelIcon />}
          confirmTitle={translate("ui.individual_deliveries.cancel_title", {
            name: translate("ui.individual_deliveries.arranged_delivery"),
          })}
          confirmContent={"ui.individual_deliveries.cancel_content"}
        />
      );
    }
    case "waiting_for_inspection": {
      if (delivery_type === "hacobell") {
        if (hacobellUncancellableStatuses.includes(hacobellStatus)) {
          return null;
        }
        return (
          <DeleteButton
            {...props}
            resource={"spot_contract_individual_deliveries"}
            undoable={false}
            redirect={redirectTo}
            label="ra.action.cancel"
            icon={<CancelIcon />}
            confirmTitle={translate("ui.individual_deliveries.cancel_title", {
              name: translate("ui.individual_deliveries.arranged_delivery"),
            })}
            confirmContent={"ui.individual_deliveries.cancel_content"}
          />
        );
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

export const InspectDeliveryButton = (props: { record?: IndividualDelivery }) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  const { status } = record;
  switch (status) {
    case "waiting_for_inspection": {
      return (
        <EditButton
          {...props}
          basePath={"/spot_contract_individual_delivery_after_delivery_inspections"}
          resource={"spot_contract_individual_delivery_after_delivery_inspections"}
          label="ui.individual_deliveries.inspect"
          icon={<PlaylistAddCheckIcon />}
        />
      );
    }
    default: {
      return null;
    }
  }
};
