import React from "react";

type InvoiceRegistrationStatusFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
};

export const InvoiceRegistrationStatusField = (props: InvoiceRegistrationStatusFieldProps) => {
  const { record, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const invoiceRegistrationStatus: string = record[props.source];
  let status = "";
  switch (invoiceRegistrationStatus) {
    case "registered":
      status = "取得済";
      break;
    case "will_register":
      status = "取得予定";
      break;
    case "wont_register":
      status = "取得しない";
      break;
    case "pending":
      status = "未定/わからない";
      break;
  }

  return <span {...rest}>{status}</span>;
};
