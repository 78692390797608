import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import Grey from "@material-ui/core/colors/grey";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  main: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  link: {
    boxSizing: "border-box",
    color: Grey["700"],
    display: "inline-block",
    padding: 16,
    textDecoration: "none",
    width: "100%",
  },
  unit: {
    fontSize: 20,
    marginLeft: 4,
  },
});

type Props = {
  label: string;
  to: string;
  value: number | undefined; // 値が読み込み中の場合にundefined
  loading: boolean;
};

export const ItemCount = (props: Props) => {
  const { label, loading, to, value } = props;
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <Link to={to} className={styles.link}>
        <Box display="flex" width={1}>
          <Box flex={1}>
            <Typography color="textSecondary">{label}</Typography>
          </Box>
          <Box flex={1}>
            {loading && (
              <Box textAlign="center" p={1}>
                <CircularProgress size={24} />
              </Box>
            )}
            {!loading && (
              <Typography variant="h4" align="right">
                {value === undefined ? "-" : value.toLocaleString()}
                <span className={styles.unit}>件</span>
              </Typography>
            )}
          </Box>
        </Box>
      </Link>
    </div>
  );
};
