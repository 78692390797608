import React from "react";

import TrueIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";

type Props = {
  source?: string;
  record?: any;
  sortable?: boolean;
};

export const CheckedOnlyField = (props: Props) => {
  const { record, source } = props;
  if (!record || !source) {
    return null;
  }

  const value = record[source];
  return (
    <Typography variant="body2" component="span">
      {value && <TrueIcon fontSize="small" />}
    </Typography>
  );
};

CheckedOnlyField.defaultProps = {
  addLabel: true,
};
