import { makeStyles } from "@material-ui/styles";

export const useCommonStyles = makeStyles({
  // 詳細ページの一覧
  showPageInnerList: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 8px",
    },
    "& .MuiTableCell-root": {
      "min-width": "2em",
    },
  },
});
