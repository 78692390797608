import React from "react";

import { Brand } from "../../infrastructure/types";

type OfferRequestsFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
  brands: Brand[];
};

export const OfferRequestsField = (props: OfferRequestsFieldProps) => {
  const { record, brands, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const offerRequests: any[] = record[props.source];
  if (offerRequests.length === 0) {
    return null;
  }

  const brandId = offerRequests[0]["brand_id"];
  const unitPrice: number = offerRequests[0]["unit_price"];
  const findBrand = brands.find((brand) => brand.id === brandId);
  if (!findBrand) {
    return null;
  }

  const label = `${findBrand.name}/${unitPrice.toLocaleString()}円〜${offerRequests.length > 1 ? "、他" : ""}`;
  return <span {...rest}>{label}</span>;
};
