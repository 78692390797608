import React from "react";
import { EditButton, TopToolbar, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
  link: {
    color: Grey["600"],
    fontSize: "14px",
    textDecoration: "underline",
    verticalAlign: "sub",
  },
});

// react-adminから暗黙的に渡されるprops
export type ActionProps = {
  basePath?: string;
  data?: any;
  hasEdit?: boolean;
  resource?: string;
};

export const ShowToolbar = (
  props: ActionProps & {
    children?: any;
    listPath: string;
  }
) => {
  const { basePath, data, hasEdit } = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (!data) {
    return null;
  }

  const { pathname } = window.location;

  return (
    <TopToolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Link className={classes.link} href={`${pathname}#${props.listPath}`}>
            {translate("ui.action.back_to_list")}
          </Link>
        </Box>
        <Box textAlign="right">
          {props.children ? props.children : hasEdit && <EditButton basePath={basePath} record={data} />}
        </Box>
      </Box>
    </TopToolbar>
  );
};
