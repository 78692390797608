/* eslint-disable react/jsx-key */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { SentryWrapper } from "./sentry";

import { Admin, Resource } from "react-admin";
import { i18nProvider } from "./i18n/i18nProvider";
import { getAuthProvider, getDataProvider } from "./utils/api";

import { Dashboard } from "./pages/dashboard";
import {
  WholesalerAccountCreate,
  WholesalerAccountEdit,
  WholesalerAccountList,
  WholesalerAccountShow,
} from "./pages/wholesaler_accounts";
import {
  WholesalerAddressCreate,
  WholesalerAddressEdit,
  WholesalerAddressList,
  WholesalerAddressShow,
} from "./pages/wholesaler_addresses";
import { OfferCreate, OfferEdit, OfferList, OfferShow } from "./pages/offers";
import {
  SpotContractOfferCreate,
  SpotContractOfferEdit,
  SpotContractOfferList,
  SpotContractOfferShow,
} from "./pages/spot_contract_offers";
import { OthersOfferList, OthersOfferShow } from "./pages/others_offers";
import { SpotContractOthersOfferList, SpotContractOthersOfferShow } from "./pages/spot_contract_others_offers";
import { DealList, DealShow } from "./pages/deals";
import { SpotContractDealList, SpotContractDealShow } from "./pages/spot_contract_deals";
import {
  AfterDeliveryInspectionEdit,
  IndividualDeliveryCreate,
  IndividualDeliveryEdit,
} from "./pages/individual_deliveries";
import {
  SpotContractAfterDeliveryInspectionEdit,
  SpotContractIndividualDeliveryCreate,
  SpotContractIndividualDeliveryEdit,
} from "./pages/spot_contract_individual_deliveries";

import PersonIcon from "@material-ui/icons/Person";

import { DealIcon } from "./svg/DealIcon";
import { OfferIcon } from "./svg/OfferIcon";
import PlaceIcon from "@material-ui/icons/Place";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { myTheme } from "./components/my_theme";
import { MyLayout } from "./components/MyLayout";
import { MyLoginPage } from "./components/my_login";
import { PendingTaskList } from "./pages/pending_tasks";
import { OfferRequestList } from "./pages/offer_requests";
import { ProducerSummariesList, ProducerSummaryShow } from "./pages/producer_summaries";
import customRoutes from "./customRoutes";
import { OfferRequestIcon } from "./svg/OfferRequestIcon";
import { ProducerSummaryIcon } from "./svg/ProducerSummaryIcon";

const App = () => (
  <Admin
    authProvider={getAuthProvider()}
    dataProvider={getDataProvider()}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
    layout={MyLayout}
    loginPage={MyLoginPage}
    theme={myTheme}
    customRoutes={customRoutes}
  >
    {(permissions: string) => [
      <Resource icon={AssignmentTurnedInIcon} list={PendingTaskList} name="pending_tasks" />,
      <Resource name="offer_requests" icon={OfferRequestIcon} list={OfferRequestList} />,
      <Resource
        name="producer_summaries"
        icon={ProducerSummaryIcon}
        list={ProducerSummariesList}
        show={ProducerSummaryShow}
      />,
      <Resource
        create={(permissions === "admin" || permissions === "staff") && OfferCreate}
        edit={(permissions === "admin" || permissions === "staff") && OfferEdit}
        icon={OfferIcon}
        list={OfferList}
        name="offers"
        show={OfferShow}
      />,
      <Resource
        create={(permissions === "admin" || permissions === "staff") && SpotContractOfferCreate}
        edit={(permissions === "admin" || permissions === "staff") && SpotContractOfferEdit}
        icon={OfferIcon}
        list={SpotContractOfferList}
        name="spot_contract_offers"
        show={SpotContractOfferShow}
      />,

      <Resource name="others_offers" icon={OfferIcon} list={OthersOfferList} show={OthersOfferShow} />,
      <Resource
        name="spot_contract_others_offers"
        icon={OfferIcon}
        list={SpotContractOthersOfferList}
        show={SpotContractOthersOfferShow}
      />,

      <Resource icon={DealIcon} list={DealList} name="deals" show={DealShow} />,
      <Resource icon={DealIcon} list={SpotContractDealList} name="spot_contract_deals" show={SpotContractDealShow} />,
      <Resource
        create={(permissions === "admin" || permissions === "staff") && IndividualDeliveryCreate}
        edit={(permissions === "admin" || permissions === "staff") && IndividualDeliveryEdit}
        name="individual_deliveries"
      />,
      <Resource
        create={(permissions === "admin" || permissions === "staff") && SpotContractIndividualDeliveryCreate}
        edit={(permissions === "admin" || permissions === "staff") && SpotContractIndividualDeliveryEdit}
        name="spot_contract_individual_deliveries"
      />,
      <Resource
        edit={(permissions === "admin" || permissions === "staff") && SpotContractAfterDeliveryInspectionEdit}
        name="spot_contract_individual_delivery_after_delivery_inspections"
      />,
      <Resource
        edit={(permissions === "admin" || permissions === "staff") && AfterDeliveryInspectionEdit}
        name="individual_delivery_after_delivery_inspections"
      />,
      <Resource
        create={(permissions === "admin" || permissions === "staff") && WholesalerAddressCreate}
        edit={(permissions === "admin" || permissions === "staff") && WholesalerAddressEdit}
        icon={PlaceIcon}
        list={WholesalerAddressList}
        name="wholesaler_addresses"
        show={WholesalerAddressShow}
      />,
      <Resource
        create={permissions === "admin" && WholesalerAccountCreate}
        edit={permissions === "admin" && WholesalerAccountEdit}
        icon={PersonIcon}
        list={WholesalerAccountList}
        name="wholesaler_accounts"
        show={WholesalerAccountShow}
      />,
      // カスタムルート
      <Resource icon={NotificationsIcon} name={permissions === "admin" && "wholesaler_setting_mail_notification"} />,
      <Resource name="amends" />,
      <Resource name="annums" />,
      <Resource name="brands" />,
      <Resource name="grades" />,
      <Resource name="offer_publication_periods" />,
      <Resource name="packaging_forms" />,
      <Resource name="payments" />,
      <Resource name="prefectures" />,
      <Resource name="regions" />,
      <Resource name="special_conditions" />,
      <Resource name="spot_contract_amends" />,
      <Resource name="spot_contract_annums" />,
      <Resource name="spot_contract_offer_publication_periods" />,
      <Resource name="spot_contract_special_conditions" />,
      <Resource name="spot_contract_storage_periods" />,
      <Resource name="spot_contract_payments" />,
      <Resource name="storage_periods" />,
    ]}
  </Admin>
);

ReactDOM.render(
  <React.StrictMode>
    <SentryWrapper>
      <App />
    </SentryWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
