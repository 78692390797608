import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { DateTime } from "luxon";

import { Holiday } from "../infrastructure/types";
import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";
import { getPrevBusinessDay } from "../utils/date";

export const useCanEditOffer = (permissions: any, data: any): [boolean, boolean] => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [holidays, setHolidays] = useState<string[]>([]);

  const current = DateTime.local();
  const currentYear = current.year;

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const params = {
        ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS,
      };
      const results = await dataProvider.getList("holidays", params);

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefinedになるため
        if (results) {
          // 年度跨ぎを想定して今年と一年前の祝日を取得する
          const dates = results.data
            .filter((h: Holiday) => h.is_active && (h.year === currentYear || h.year === currentYear - 1))
            .map((h: Holiday) => h.dates)
            .flat();
          setHolidays(dates);
        }
        setIsLoading(false);
      }
    })();
    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, currentYear]);

  if (isLoading || !data) {
    return [true, false];
  }

  if (!["admin", "staff"].includes(permissions)) {
    return [false, false];
  }

  if (data.publish_status !== "waiting" || data.is_deleted) {
    return [false, false];
  }

  const prevBusinessDay = getPrevBusinessDay(data.publish_start_date, holidays);
  const deadline = DateTime.fromISO(prevBusinessDay).set({
    hour: 17,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return [false, current < deadline];
};
