import React from "react";
import { EditButton } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DealIcon } from "../svg/DealIcon";

import { useCanEditOffer } from "../hooks/useCanEditOffer";
import { ActionProps, ShowToolbar } from "./ShowToolbar";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    textAlign: "right",
  },
  loading: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  dealListButton: {
    color: "rgb(30, 136, 229)",
    "&:hover": {
      backgroundColor: "rgb(30, 136, 229, 0.12)",
    },
  },
}));

export const SpotContractOfferShowActions = (
  props: ActionProps & {
    listPath: string;
    permissions: string;
  }
) => {
  const classes = useStyles();
  const { listPath, permissions, ...actionProps } = props;
  const { basePath, data } = actionProps;
  const [isLoading, canEdit] = useCanEditOffer(permissions, data);

  if (!permissions || !data) {
    return null;
  }

  return (
    <ShowToolbar {...actionProps} listPath={listPath}>
      <Box className={classes.toolbar}>
        {isLoading && (
          <span className={classes.loading}>
            <CircularProgress size={16} />
          </span>
        )}
        {canEdit && (
          <span className={classes.button}>
            <EditButton basePath={basePath} record={data} />
          </span>
        )}
        <span className={classes.button}>
          <DealListButton offerId={data.id} />
        </span>
      </Box>
    </ShowToolbar>
  );
};

type ButtonProps = {
  offerId: number;
};

export const DealListButton = (props: ButtonProps) => {
  const classes = useStyles();

  const displayFilters = {
    offer_id: true,
  };
  const filter = {
    offer_id: props.offerId,
  };
  const toString = (obj: any) => encodeURIComponent(JSON.stringify(obj));
  const to = `/#/spot_contract_deals?displayFilters=${toString(displayFilters)}&filter=${toString(filter)}`;

  return (
    <Button
      className={classes.dealListButton}
      size="small"
      startIcon={<DealIcon width={18} height={13.65} color="#1e88e5" />}
      href={to}
    >
      取引案件
    </Button>
  );
};
