import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

import LinearProgress from "./LinerProgress";
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from "../utils/parameters";

import { FetchedListData, Prefecture } from "../infrastructure/types";

let cachedPrefectures: FetchedListData<Prefecture> = null;

type FieldProps = {
  record?: {
    prefecture_ids: number[];
  };
  reference?: string;
  sortable?: boolean;
  source?: string;
};

export const PrefecturesSelectField = (props: FieldProps) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);

  let ids: number[] = [];
  if (record !== undefined) {
    ids = record.prefecture_ids;
  }

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }
      const params = {
        ...FETCH_PARAMS_FOR_THOUSAND_ITEMS,
      };

      if (cachedPrefectures === null) {
        cachedPrefectures = dataProvider.getList("prefectures", params);
      }
      const results = await cachedPrefectures;

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (results) {
          setPrefectures(results.data);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, ids]);

  if (isLoading) {
    return <LinearProgress />;
  }

  const filteredPrefectures = prefectures.filter((p) => ids.includes(p.id));

  let label = "なし"; // 通常はありえない
  if (filteredPrefectures.length === 1) {
    label = filteredPrefectures[0].name;
  } else if (filteredPrefectures.length > 1) {
    label = `${filteredPrefectures[0].name}、他`;
  }

  return <span>{label}</span>;
};
