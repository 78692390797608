import React from "react";

import Typography from "@material-ui/core/Typography";

import { IndividualDelivery } from "../api/individual_deliveries";
import { dateTimeProps, dateProps } from "../utils/locale";

export const HandoverAtField = (props: { record?: IndividualDelivery; label?: string; sortable?: boolean }) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  const { delivery_type, hacobell, self_delivery } = record;
  let handoverAt: string | undefined | null;
  switch (delivery_type) {
    case "hacobell": {
      handoverAt = hacobell?.handover_at;
      break;
    }
    case "self_delivery": {
      handoverAt = self_delivery?.handover_date;
      break;
    }
    default: {
      break;
    }
  }

  if (!handoverAt) {
    return null;
  }
  const date = new Date(handoverAt);
  if (delivery_type === "hacobell") {
    return (
      <Typography variant="body2">
        {date.toLocaleTimeString(dateTimeProps.locales, { ...dateTimeProps.options })}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2">{date.toLocaleDateString(dateProps.locales, { ...dateProps.options })}</Typography>
    );
  }
};

HandoverAtField.defaultProps = {
  addLabel: true,
};
