import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import Grey from "@material-ui/core/colors/grey";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { ItemCount } from "./ItemCount";
import { useDashboardCounts } from "../hooks/useDashboardCounts";
import {
  createWaitingForAfterDeliveryInspectionParams,
  createWaitingForDealAgreementParams,
  createWaitingForDeliveryCreationParams,
  createWaitingForPaymentParams,
} from "../utils/url";

const useStyles = makeStyles({
  countTitle: {
    fontSize: 20,
    marginBottom: 8,
  },
});

const borderTop = `1px solid ${Grey[300]}`;

export const DashboardCounts = () => {
  const styles = useStyles();
  const translate = useTranslate();
  const [loadingDashboardCounts, dashboardCounts] = useDashboardCounts();

  if (loadingDashboardCounts) {
    return (
      <Box mt={24} px={24} textAlign="center">
        <Typography>読み込み中...</Typography>
        <Box mt={2}>
          <LinearProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Box flex={1}>
        <Box mt={2}>
          <Typography component="h2" className={styles.countTitle}>
            {translate("ui.dashboard.deals")}
          </Typography>
          <Paper>
            <ItemCount
              to={`/deals?${createWaitingForDealAgreementParams()}`}
              label={translate("ui.dashboard.unanswered")}
              value={dashboardCounts.advanceContract.unanswered}
              loading={dashboardCounts.advanceContract.unanswered === undefined}
            />
          </Paper>
        </Box>
        <Box mt={6}>
          <Typography component="h2" className={styles.countTitle}>
            {translate("ui.dashboard.amends")}
          </Typography>
          <Paper>
            <Box>
              <ItemCount
                to={`/deals?${createWaitingForDeliveryCreationParams()}`}
                label={translate("ui.dashboard.waiting_for_delivery_creation")}
                value={dashboardCounts.advanceContract.needToArrange}
                loading={dashboardCounts.advanceContract.needToArrange === undefined}
              />
            </Box>
            <Box borderTop={borderTop}>
              <ItemCount
                to={`/deals?${createWaitingForAfterDeliveryInspectionParams()}`}
                label={translate("ui.dashboard.waiting_for_after_delivery_inspection")}
                value={dashboardCounts.advanceContract.inspection}
                loading={dashboardCounts.advanceContract.inspection === undefined}
              />
            </Box>
            <Box borderTop={borderTop}>
              <ItemCount
                to={`/deals?${createWaitingForPaymentParams()}`}
                label={translate("ui.dashboard.waiting_for_payment")}
                value={dashboardCounts.advanceContract.payment}
                loading={dashboardCounts.advanceContract.payment === undefined}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box flex={1} ml="48px">
        <Box mt={2}>
          <Typography component="h2" className={styles.countTitle}>
            {translate("ui.dashboard.spot_contract_deals")}
          </Typography>
          <Paper>
            <ItemCount
              to={`/spot_contract_deals?${createWaitingForDealAgreementParams()}`}
              label={translate("ui.dashboard.unanswered")}
              value={dashboardCounts.spotContract.unanswered}
              loading={dashboardCounts.spotContract.unanswered === undefined}
            />
          </Paper>
        </Box>
        <Box mt={6}>
          <Typography component="h2" className={styles.countTitle}>
            {translate("ui.dashboard.spot_contract_amends")}
          </Typography>
          <Paper>
            <Box>
              <ItemCount
                to={`/spot_contract_deals?${createWaitingForDeliveryCreationParams()}`}
                label={translate("ui.dashboard.waiting_for_delivery_creation")}
                value={dashboardCounts.spotContract.needToArrange}
                loading={dashboardCounts.spotContract.needToArrange === undefined}
              />
            </Box>
            <Box borderTop={borderTop}>
              <ItemCount
                to={`/spot_contract_deals?${createWaitingForAfterDeliveryInspectionParams()}`}
                label={translate("ui.dashboard.waiting_for_after_delivery_inspection")}
                value={dashboardCounts.spotContract.inspection}
                loading={dashboardCounts.spotContract.inspection === undefined}
              />
            </Box>
            <Box borderTop={borderTop}>
              <ItemCount
                to={`/spot_contract_deals?${createWaitingForPaymentParams()}`}
                label={translate("ui.dashboard.waiting_for_payment")}
                value={dashboardCounts.spotContract.payment}
                loading={dashboardCounts.spotContract.payment === undefined}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
