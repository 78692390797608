import React, { useEffect, useState } from "react";
import { LinearProgress, useDataProvider } from "react-admin";

type Props = {
  record?: {
    storage_period_ids: number[];
  };
  sortable?: boolean;
  source?: string;
};

export const SpotContractStoragePeriodsField = (props: Props) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [storagePeriods, setStoragePeriods] = useState<any[]>([]);

  let ids: number[] = [];
  if (record !== undefined) {
    ids = record.storage_period_ids;
  }

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const promises = ids.map((id) => {
        return dataProvider.getOne("spot_contract_storage_periods", { id });
      });
      const results = await Promise.all(promises);

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (results) {
          const periods = results.filter((res) => res).map((res: any) => res.data);
          setStoragePeriods(periods);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, ids]);

  if (isLoading) {
    return <LinearProgress />;
  }

  const maxDays = storagePeriods
    .filter((sp) => ids.includes(sp.id))
    .reduce((value: number, sp) => {
      const { storage_period_in_days } = sp;
      return storage_period_in_days > value ? storage_period_in_days : value;
    }, 0);

  return <span>最大{maxDays}日</span>;
};
