import React from "react";
import {
  Datagrid,
  TextField,
  ArrayField,
  Labeled,
  ReferenceField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { Card, CardContent, Chip } from "@material-ui/core";

import { UnitPriceField } from "./unit_price_field";

export const DetailsByProducerExpand = (props: any) => {
  const { record, certifications } = props;
  if (record === undefined) {
    return null;
  }

  const farmingCertificationIds = record["farming_certification_ids"];
  const CertificationFieldProps = {
    certifications,
    farmingCertificationIds,
  };

  return (
    <Card>
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <FarmingCertificationField {...CertificationFieldProps} />
        <OfferRequestArrayField {...props} source="offer_requests" />
      </CardContent>
    </Card>
  );
};

type FarmingCertificationFieldProps = {
  certifications: any;
  farmingCertificationIds: number[];
};

export const FarmingCertificationField = (props: FarmingCertificationFieldProps) => {
  const { certifications, farmingCertificationIds } = props;

  const chips = farmingCertificationIds.map((id: any) => {
    const findCertification = certifications.find((certification: any) => certification.id === id);

    if (!findCertification) {
      return null;
    }

    return <Chip key={id} label={findCertification.label} style={{ marginRight: "8px" }} />;
  });

  return (
    <Labeled label="resources.producer_summaries.fields.farming_certification_ids">
      <>{chips}</>
    </Labeled>
  );
};

export const OfferRequestArrayField = (props: any) => {
  const { record, isExistRegion } = props;
  if (record === undefined) {
    return null;
  }

  return (
    <Labeled {...props} label="resources.producer_summaries.fields.offer_requests">
      <ArrayField {...props} source="offer_requests">
        <Datagrid>
          <DateField source="updated_at" locales={"ja"} sortable={false} />
          {isExistRegion && (
            <ReferenceField reference="regions" source="region_id" link={false} sortable={false}>
              <TextField source="name" sortable={false} />
            </ReferenceField>
          )}
          <ReferenceField reference="brands" source="brand_id" link={false} sortable={false}>
            <TextField source="name" sortable={false} />
          </ReferenceField>
          <ReferenceField reference="grades" source="grade_id" link={false} sortable={false}>
            <TextField source="name" sortable={false} />
          </ReferenceField>
          <UnitPriceField source="unit_price" sortable={false} />
          <ReferenceArrayField reference="special_conditions" source="special_condition_ids" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="display_text" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ArrayField>
    </Labeled>
  );
};
