import React from "react";

import Typography from "@material-ui/core/Typography";

import { IndividualDelivery } from "../api/individual_deliveries";
import { dateTimeProps } from "../utils/locale";

function formatStartDate(date: Date | null): string {
  if (date === null) {
    return "";
  }
  return date.toLocaleTimeString(dateTimeProps.locales, { ...dateTimeProps.options });
}

function formatEndDate(end: Date | null, start: Date | null): string {
  if (end === null) {
    return "";
  }
  if (start === null) {
    return end.toLocaleTimeString(dateTimeProps.locales, { ...dateTimeProps.options });
  }
  const isSameDate =
    end.getFullYear() === start.getFullYear() &&
    end.getMonth() === start.getMonth() &&
    end.getDate() === start.getDate();
  if (!isSameDate) {
    return end.toLocaleTimeString(dateTimeProps.locales, { ...dateTimeProps.options });
  }
  return end.toLocaleTimeString(dateTimeProps.locales, { hour: "numeric", minute: "numeric" });
}

export const ReceiptTimingField = (props: {
  source?: string;
  record?: IndividualDelivery;
  label?: string;
  sortable?: boolean;
}) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  const { receipt_timing_end, receipt_timing_start } = record;

  const startDate = receipt_timing_start !== null ? new Date(receipt_timing_start) : null;
  const endDate = receipt_timing_end !== null ? new Date(receipt_timing_end) : null;
  if (startDate === null && endDate === null) {
    return null;
  }

  const start = formatStartDate(startDate);
  const end = formatEndDate(endDate, startDate);
  return (
    <Typography variant="body2">
      {start}〜{end}
    </Typography>
  );
};

ReceiptTimingField.defaultProps = {
  addLabel: true,
};
