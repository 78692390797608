import React from "react";

type FarmingCertificationFieldProps = {
  record?: any;
  sortable?: boolean;
  source: string;
  certifications: any;
};

export const FarmingCertificationField = (props: FarmingCertificationFieldProps) => {
  const { record, certifications, ...rest } = props;
  if (record === undefined) {
    return null;
  }

  const farmingCertificationIds: number[] = record[props.source];

  let label = "";
  if (farmingCertificationIds.length > 0) {
    const findCertification =
      certifications && certifications.find((certification: any) => certification.id === farmingCertificationIds[0]);

    label = `${findCertification ? findCertification.label : ""}${
      findCertification && farmingCertificationIds.length > 1 ? "、他" : ""
    }`;
  }

  return <span {...rest}>{label}</span>;
};
