import React from "react";
import * as Sentry from "@sentry/react";
import { release } from "./status.json";

if (process.env.REACT_APP_DEPLOY_TARGET) {
  let dsn = "https://545ee625546e4c0e966d6ad452201986@o60106.ingest.sentry.io/5401410";
  if (process.env.REACT_APP_DEPLOY_TARGET === "production") {
    dsn = "https://ec2e92c54a6843a896f40aa5c8837694@o60106.ingest.sentry.io/5401406";
  }
  Sentry.init({
    dsn,
    release,
  });
}

type Props = {
  children: React.ReactElement;
};

export const SentryWrapper = (props: Props): React.ReactElement => {
  if (!process.env.REACT_APP_DEPLOY_TARGET) {
    return props.children;
  }
  return (
    <Sentry.ErrorBoundary fallback={"エラーが発生しました。画面を再読み込みしてください。"}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};
