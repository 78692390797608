import { useEffect, useRef, useState } from "react";
import { useNotify, useVersion } from "react-admin";

import { getDataProvider } from "../utils/api";

/**
 * 特定のリソースの任意のIDの項目を取得するhooks。
 *
 * 型をつけて呼び出すことで第二引数に型が設定される。
 *
 * ```
 * const [loading, item] = useGetOne<ResourceType>("resource_name", resource_id)
 * ```
 *
 * @param resourceName リソース名
 * @param id リソースのID
 */
export const useGetOne = <T>(resourceName: string, id: number | undefined): [boolean, T | null] => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<T | null>(null);
  const isCleanedUp = useRef(false);
  const dataProvider = getDataProvider();
  const version = useVersion();

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    (async () => {
      if (isCleanedUp.current) {
        isCleanedUp.current = false;
      }
      setLoading(true);
      const response: { data: any } = await dataProvider.getOne(resourceName, { id: id });
      if (!isCleanedUp.current) {
        const { data }: { data: T } = response;
        if (data) {
          setItem(data);
        } else {
          notify("ra.notification.http_error", "error");
        }
      }
      setLoading(false);
    })();
    return () => {
      isCleanedUp.current = true;
    };
  }, [dataProvider, id, notify, resourceName, version]);

  if (id === undefined) {
    return [false, null];
  }

  return [loading, item];
};
