import React from "react";
import styled from "@emotion/styled";

export const TermsIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 30 30">
    <g>
      <rect className="cls-2" width="13.383" height="1.279" transform="translate(8.309 22.219)" />
      <rect className="cls-2" width="13.383" height="1.279" transform="translate(8.309 17.912)" />
      <rect className="cls-2" width="13.383" height="1.279" transform="translate(8.309 13.605)" />
      <rect className="cls-2" width="5.133" height="1.279" transform="translate(16.553 7.834)" />
      <path
        className="cls-2"
        d="M74.765,5H63.7a3,3,0,0,0-2.121.879l-6.9,6.9A2.994,2.994,0,0,0,53.8,14.9V31.684a2.73,2.73,0,0,0,2.73,2.73H74.765a2.73,2.73,0,0,0,2.73-2.73V7.725A2.73,2.73,0,0,0,74.765,5M62.736,7.42v5.607c0,.662-.258.92-.92.92h-5.49ZM75.374,31.1a1.2,1.2,0,0,1-1.2,1.2H57.116a1.2,1.2,0,0,1-1.2-1.2V15.9h7.008a1.465,1.465,0,0,0,1.465-1.465V7.121h9.785a1.2,1.2,0,0,1,1.2,1.189Z"
        transform="translate(-50.648 -4.707)"
      />
    </g>
  </Svg>
);

const Svg = styled.svg`
  .cls-2 {
    fill: #67ad10;
  }
`;
