import React, { useState, useEffect } from "react";
import { Datagrid, TextField, ArrayField, Labeled, ReferenceField, useDataProvider, Pagination } from "react-admin";

import { Brand, Region, FetchedListData } from "../../infrastructure/types";
import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../../utils/parameters";

import { DetailsByProducerExpand } from "./details_by_producer_expand";
import { CanSelfInspectField } from "./can_self_inspect_field";
import { InvoiceRegistrationStatusField } from "./invoice_registration_status_field";
import { FarmingCertificationField } from "./farming_certification_field";
import { OfferRequestsField } from "./offer_requests_field";

export const DetailsByProducerTab = (props: any) => {
  const { record, regions } = props;
  const dataProvider = useDataProvider();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      const fetchedBrands: FetchedListData<Brand> = await dataProvider.getList(
        "brands",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );

      if (!isCleanedUp) {
        if (fetchedBrands) {
          setBrands(fetchedBrands.data);
        }
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider, setBrands]);

  if (record === undefined) {
    return null;
  }

  const detailsByProducer = record["details_by_producer"];
  const certifications = record["details_by_farming_certification"];
  const filterRegions = regions.filter((region: Region) => {
    return region.is_active && region.prefecture_id === record["id"];
  });

  const isExistRegion = filterRegions.length > 0;
  const detailsByProducerExpandProps = {
    ...props,
    certifications: certifications,
  };
  const newRecord = {
    ...record,
    details_by_producer: sliceProducers(detailsByProducer, page, perPage),
  };

  return (
    <Labeled {...props}>
      <>
        <ArrayField {...props} record={newRecord}>
          <Datagrid
            expand={<DetailsByProducerExpand {...detailsByProducerExpandProps} isExistRegion={isExistRegion} />}
          >
            <TextField source="id" label="No." sortable={false} />
            {isExistRegion && (
              <ReferenceField reference="regions" source="region_id" link={false} sortable={false}>
                <TextField source="name" sortable={false} />
              </ReferenceField>
            )}
            <CanSelfInspectField source="can_self_inspect" sortable={false} />
            <TextField source="cropland_area" sortable={false} />
            <ReferenceField reference="storage_periods" source="storage_period_id" link={false} sortable={false}>
              <TextField source="name" sortable={false} />
            </ReferenceField>
            <InvoiceRegistrationStatusField source="invoice_registration_status" sortable={false} />
            <FarmingCertificationField
              source="farming_certification_ids"
              sortable={false}
              certifications={certifications}
            />
            <OfferRequestsField source="offer_requests" sortable={false} brands={brands} />
          </Datagrid>
        </ArrayField>
        <Pagination
          total={detailsByProducer.length}
          page={page}
          perPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          setPage={setPage}
          setPerPage={(perPage: number) => {
            setPage(1);
            setPerPage(perPage);
          }}
        />
      </>
    </Labeled>
  );
};

function sliceProducers(producers: any[], page: number, perPage: number) {
  if (producers.length === 0) {
    return producers;
  }

  return producers.slice((page - 1) * perPage, (page - 1) * perPage + perPage);
}
