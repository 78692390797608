import React, { ReactElement, useEffect } from "react";
import { Button, Toolbar, useNotify } from "react-admin";
import { useFormState, useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";

import { HandoverAddress } from "../api/amend";
import { IndividualDeliveryWriteBody } from "../api/individual_deliveries";
import { Prefecture } from "../api/prefectures";
import { WholesalerAddress } from "../api/wholesaler_addresses";
import { RegisterHacobellButton } from "./RegisterHacobellButton";
import { findBasePrice } from "../utils/hacobell";

const useStyles = makeStyles({
  button: {
    marginRight: 8,
  },
});

const MyButton = (props: {
  color: string;
  label: string;
  icon: ReactElement;
  disabled?: boolean;
  onClick: () => void;
}) => {
  const styles = useStyles();
  return (
    <Button
      className={styles.button}
      color={props.color}
      label={props.label}
      onClick={props.onClick}
      size="medium"
      variant="contained"
      disabled={props.disabled === true}
    >
      {props.icon}
    </Button>
  );
};

export const IndividualDeliveryToolbar = (props: {
  disabledSaveAsDraft: boolean;
  saveAsNotifyDriverInfo: boolean;
  handoverAddress: HandoverAddress;
  prefectures: Prefecture[];
  wholesalerAddresses: WholesalerAddress[];
  [key: string]: any;
}) => {
  const { disabledSaveAsDraft, saveAsNotifyDriverInfo, handoverAddress, prefectures, wholesalerAddresses } = props;
  const form = useForm();
  const { values }: { values: IndividualDeliveryWriteBody } = useFormState();
  const notify = useNotify();
  useEffect(() => {
    form.change("status", undefined);
  }, [form]);

  if (!values) {
    return null;
  }

  const { delivery_type, delivery_address_id, hacobell } = values;

  const toAddress = wholesalerAddresses.find((wa) => wa.id === delivery_address_id);
  const fromPref = prefectures.find((p) => p.id === handoverAddress.prefecture_id);
  const toPref = prefectures.find((p) => p.id === toAddress?.prefecture_id);
  let price = null;
  if (fromPref !== undefined && toPref !== undefined && hacobell) {
    price = findBasePrice(fromPref, toPref, hacobell.preferred_vehicle);
  }
  const hasPrice = price !== null;

  const onClickSaveAsDraft = async () => {
    form.change("status", "draft");
    await props.handleSubmit();
    if (props.invalid) {
      notify("ra.message.invalid_form");
    }
  };

  const onClickSaveAsPublish = async () => {
    form.change("status", "waiting_for_inspection");
    await props.handleSubmit();
    if (props.invalid) {
      notify("ra.message.invalid_form");
    }
  };

  const onClickSaveAsRegisterHacobell = async () => {
    form.change("status", "waiting_for_arrangement");
    await props.handleSubmit();
    if (props.invalid) {
      notify("ra.message.invalid_form");
    }
  };

  switch (delivery_type) {
    case "self_delivery": {
      return (
        <Toolbar {...props}>
          <MyButton
            label="ui.individual_deliveries.save_as_draft"
            icon={<DraftsIcon />}
            color="default"
            disabled={disabledSaveAsDraft}
            onClick={onClickSaveAsDraft}
          />
          {!saveAsNotifyDriverInfo && (
            <MyButton
              label="ui.individual_deliveries.publish"
              icon={<SendIcon />}
              color="primary"
              onClick={onClickSaveAsPublish}
            />
          )}
          {saveAsNotifyDriverInfo && (
            <MyButton
              label="ui.individual_deliveries.save_as_notify_driver_info"
              icon={<SendIcon />}
              color="primary"
              onClick={onClickSaveAsPublish}
            />
          )}
        </Toolbar>
      );
    }
    case "hacobell": {
      return (
        <Toolbar {...props}>
          <MyButton
            label="ui.individual_deliveries.save_as_draft"
            icon={<DraftsIcon />}
            color="default"
            disabled={disabledSaveAsDraft}
            onClick={onClickSaveAsDraft}
          />
          <RegisterHacobellButton disabled={!hasPrice} submit={onClickSaveAsRegisterHacobell} />
        </Toolbar>
      );
    }
    default: {
      return null;
    }
  }
};
