/* eslint-disable react/display-name */

import React from "react";
import { MenuItemLink, useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import DefaultIcon from "@material-ui/icons/ViewList";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import customRoutes from "../customRoutes";

const useStyles = makeStyles((theme) => {
  const commonPadding = {
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
  };

  return {
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    icon: {
      minWidth: 40,
    },
    rootItem: {
      ...commonPadding,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    borderTop: {
      ...commonPadding,
      borderTop: "#e0e0e0 solid 1px",
      whiteSpace: "normal",
    },
    borderBottom: {
      ...commonPadding,
      borderBottom: "#e0e0e0 solid 1px",
    },
  };
});

type MyMenuItemProps = {
  onMenuClick: () => void;
  resource: any;
  sidebarOpen: boolean;
  inSubMenu: boolean;
  icon?: any;
  isBorderTop?: boolean;
  isBorderBottom?: boolean;
};

export const MyMenuItem = (props: MyMenuItemProps) => {
  const { inSubMenu, onMenuClick, resource, sidebarOpen, isBorderBottom, isBorderTop } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const rootClassNames = !sidebarOpen
    ? classes.rootItem
    : isBorderTop
    ? classes.borderTop
    : isBorderBottom
    ? classes.borderBottom
    : classes.rootItem;
  const classNames = inSubMenu ? sidebarOpen && classes.nested : rootClassNames;
  const itemIcon = resource.icon ? <resource.icon /> : <DefaultIcon />;

  let name;
  if (!(resource.options && resource.options.label)) {
    name = translate(`resources.${resource.name}.name`);
    name = name.replace("事前契約", "").replace("現物契約", "");
  }
  const customRoute = customRoutes.find((route) => route.key === resource.name);

  return (
    <MenuItemLink
      key={resource.name}
      to={customRoute ? customRoute.props.path : `/${resource.name}`}
      primaryText={name}
      leftIcon={itemIcon}
      onClick={onMenuClick}
      sidebarIsOpen={sidebarOpen}
      className={classNames}
    />
  );
};

type SubMenuProps = {
  primaryText: string;
  resources: any[];
  sidebarOpen: boolean;
  onMenuClick: () => void;
  icon?: any;
};

export const SubMenu = (props: SubMenuProps) => {
  const { onMenuClick, primaryText, resources, sidebarOpen, icon } = props;

  const [collapsed, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!collapsed);
  };

  const groupIcon = icon ? <props.icon /> : <DefaultIcon />;
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon className={classes.icon}>{groupIcon}</ListItemIcon>
        {sidebarOpen && <ListItemText primary={primaryText} />}
        {collapsed ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {resources.map((res) => {
            return (
              <MyMenuItem
                key={res.name}
                onMenuClick={onMenuClick}
                resource={res}
                sidebarOpen={sidebarOpen}
                inSubMenu={true}
                icon={PeopleIcon}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
