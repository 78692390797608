export function redirect503() {
  window.location.href = "./503.html";
}

/**
 * パスからリソース名を抽出する。
 *
 * 渡した文字列が想定したフォーマットではない場合はnullを返す。
 *
 * @param path リソースのパス
 */
export function pluckResource(path: string): string | null {
  // eslint-disable-next-line
  const [_, resource, ...rest] = path.split("/");
  return resource !== undefined ? resource : null;
}

/**
 * 契約ごとのマッピング
 *
 * 片方しかリソースがない場合、ない側をnullにする
 */
type ResourceByContract = {
  advance: string | null;
  spot: string | null;
};

// 契約の種類でリソースが変わるもの一覧
const RESOURCES_BY_CONTRACT: ResourceByContract[] = [
  {
    advance: "offers",
    spot: "spot_contract_offers",
  },
  {
    advance: "others_offers",
    spot: "spot_contract_others_offers",
  },
  {
    advance: "deals",
    spot: "spot_contract_deals",
  },
  {
    advance: "annums",
    spot: "spot_contract_annums",
  },
  {
    advance: "offer_publication_periods",
    spot: "spot_contract_offer_publication_periods",
  },
  {
    advance: "special_conditions",
    spot: "spot_contract_special_conditions",
  },
  {
    advance: "storage_periods",
    spot: "spot_contract_storage_periods",
  },
];

// 契約の種類
export type ContractType = "advance_contract" | "spot_contract" | null;

/**
 * リソースの契約の種類を返す。
 *
 * 共通の項目で契約の種類でリソースが変わらない場合はnullを返す。
 *
 * @param resource リソース名
 */
export function getContractType(resource: string): ContractType {
  const advanceResources = RESOURCES_BY_CONTRACT.map((d) => d.advance);
  const spotResources = RESOURCES_BY_CONTRACT.map((d) => d.spot);
  if (advanceResources.includes(resource)) {
    return "advance_contract";
  } else if (spotResources.includes(resource)) {
    return "spot_contract";
  }
  return null;
}

/**
 * 渡したリソース名を指定した契約の種類に変換して返す。
 *
 * 変換できない場合はnullを返す。
 *
 * @param resource リソース名
 * @param contractType 変換先となる契約の種類
 */
export function getAnotherContractResource(resource: string, contractType: ContractType): string | null {
  if (contractType === "advance_contract") {
    const target = RESOURCES_BY_CONTRACT.find((r) => r.spot === resource);
    return target ? target.advance : null;
  }

  if (contractType === "spot_contract") {
    const target = RESOURCES_BY_CONTRACT.find((r) => r.advance === resource);
    return target ? target.spot : null;
  }

  return null;
}
